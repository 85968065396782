import React, {useEffect, useState} from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import {Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import {formatNumber} from '../helpers/GeneralFunctions.js';
import {DateToString} from '../helpers/DateFunctions.js';
import {isObjectEmpty} from '../helpers/GeneralFunctions';

const styles = StyleSheet.create({
	page: {
		padding: 30,
	},
	viewChart: {
		width: '525px',
		flexDirection: 'column',
		textAlign: 'center',
		marginBottom: 20,
	},
	pdfViewer: {
    position:'fixed',
    width:'100%',
    height:'100%',
    left:0,
    top:0,
    background:'rgba(51,51,51,0.7)'
	},
	chartImage: {
		width: 390,
		height: 220,
		marginHorizontal: 'auto',
		marginBottom: 10,
	},
	title: {
		fontSize:11,
		fontWeight: 600,
	},
	cell: {
		fontSize:10,
		padding:4,
		textAlign:'center',
	}
});

const ReportPDF = () => {

	const [data, setData] = useState();

	useEffect(() => {
		setData({
			props: JSON.parse(localStorage.getItem('props')),
			charts: JSON.parse(localStorage.getItem('charts')),
		});
	}, []);


	return (
		<PDFViewer style={styles.pdfViewer}>
			<Document>
				<Page size="A4" style={styles.page}>
					{ !isObjectEmpty(data)
						&& data.props.options.deals_by_month
						&& (<DealsByMonth year={data.props.year} deals_by_month={data.props.deals_by_month} />)
					}
					{ !isObjectEmpty(data)
						&& ( data.props.options.key_stats
							|| data.props.options.deals_by_type
							|| data.props.options.bar_charts)
						&& (<View>
								<Text style={{fontSize:18,fontWeight:'bold',textAlign:'center'}}>
									Statistics Report
								</Text>
								<Text style={{fontSize:10,marginBottom:15,textAlign:'center'}}>
									({DateToString(data.props.date_from)} to {DateToString(data.props.date_to)})
								</Text>
							</View>
						)
					}
					{ !isObjectEmpty(data)
						&& data.props.options.key_stats
						&& (<KeyStats key_stats={data.props.key_stats} />)
					}
					{ !isObjectEmpty(data)
						&& data.props.options.deals_by_type
						&& (<DealsByType deals_by_type={data.props.deals_by_type} />)
					}
					{ !isObjectEmpty(data)
						&& data.props.options.bar_charts
						&& (<Charts charts={data.charts}/>)
					}
				</Page>
			</Document>
		</PDFViewer>
	)
}

const KeyStats = (props) => {
	const {key_stats} = props;
	return(
		<View style={{marginBottom:18}}>
			<Text style={{fontSize:12, fontWeight: 600, marginBottom:7}}>
				Key Statistics
			</Text>
			<Table
				data={[
					{ new_deals: (
								<Text style={{padding:5, textAlign:'center'}} >
									<Text style={{fontSize:14,letterSpacing:2.8}}>
										{`\n${key_stats.number_of_new_deals}\n`}
									</Text>
									<Text style={{fontSize:9,color:'#4d4d4d'}}>
										Count
									</Text>
									<Text style={{fontSize:14,letterSpacing:2.8}}>
										{`\n\n\n${formatNumber(key_stats.revenue_of_new_deals)}\n`}
									</Text>
									<Text style={{fontSize:9,color:'#4d4d4d'}}>
										Revenue
									</Text>
								</Text>),
						won_deals: (
								<Text style={{padding:5, textAlign:'center'}} >
									<Text style={{fontSize:14,letterSpacing:2.8}}>
										{"\n"}
										{key_stats.number_of_deals_won}
										{"\n"}
									</Text>
									<Text style={{fontSize:9,color:'#4d4d4d'}}>
										Count
									</Text>
									<Text style={{fontSize:14,letterSpacing:2.8}}>
										{"\n\n\n"}
										{formatNumber(key_stats.revenue_of_deals_won)}
										{"\n"}
									</Text>
									<Text style={{fontSize:9,color:'#4d4d4d'}}>
										Revenue
									</Text>
								</Text>),
						average_spend: (
								<Text style={{fontSize:14,letterSpacing:1,textAlign:'center'}}>
								{formatNumber(key_stats.average_spend)}
								</Text>),
					}
				]}
			>
				<TableHeader>
					<TableCell style={styles.cell}>
						New Deals
					</TableCell>
					<TableCell style={styles.cell}>
						Won Deals
					</TableCell>
					<TableCell style={styles.cell}>
						Average Spend
					</TableCell>
				</TableHeader>
				<TableBody>
					<DataTableCell getContent={(r) => r.new_deals}/>
					<DataTableCell getContent={(r) => r.won_deals}/>
					<DataTableCell getContent={(r) => r.average_spend}/>
				</TableBody>
			</Table>
		</View>
	)
}

const DealsByType = (props) => {
	const {deals_by_type} = props;
	return(
		<View style={{marginBottom:18}}>
			<Text style={{fontSize:12, fontWeight: 600, marginBottom:7}}>
				Deals By Event Type
			</Text>
			<Table data={deals_by_type}>
				<TableHeader>
					<TableCell style={styles.cell}>
						Event Type
					</TableCell>
					<TableCell style={styles.cell}>
						Number of New Deals
					</TableCell>
					<TableCell style={styles.cell}>
						Revenue of New Deals
					</TableCell>
					<TableCell style={styles.cell}>
						Number of Deals Won
					</TableCell>
					<TableCell style={styles.cell}>
						Revenue of Deals Won
					</TableCell>
					<TableCell style={styles.cell}>
						Average Spend
					</TableCell>
				</TableHeader>
				<TableBody>
					<DataTableCell
						style={{fontSize:10, padding:4}}
						getContent={(r) => r.type}
					/>
					<DataTableCell
						style={styles.cell}
						getContent={(r) => r.number_of_new_deals}
					/>
					<DataTableCell
						style={{fontSize:10, padding:4, textAlign:'right'}}
						getContent={(r) => formatNumber(r.revenue_of_new_deals)}
					/>
					<DataTableCell
						style={styles.cell}
						getContent={(r) => r.number_of_deals_won}
					/>
					<DataTableCell
						style={{fontSize:10, padding:4, textAlign:'right'}}
						getContent={(r) =>  formatNumber(r.revenue_of_deals_won)}
					/>
					<DataTableCell
						style={{fontSize:10, padding:4, textAlign:'right'}}
						getContent={(r) =>  formatNumber(r.average_spend)}
					/>
				</TableBody>
			</Table>
		</View>
	)
}

const Charts = (props) => {
	const {charts} = props;
	return (
		<View>
			<Text style={{fontSize:14,fontWeight:'bold',marginBottom:7,marginTop:7}}>
				CHARTS
			</Text>
			{charts.new_deals_by_type && (<Image style={styles.chartImage} src={charts.new_deals_by_type} />)}
			{charts.won_deals_by_type && (<Image style={styles.chartImage} src={charts.won_deals_by_type} />)}
			{charts.deals_by_stage && (<Image style={styles.chartImage} src={charts.deals_by_stage} />)}
			{charts.revenue_by_month && (<Image style={styles.chartImage} src={charts.revenue_by_month} />)}
		</View>
	)
}

const DealsByMonth = (props) => {
	const {deals_by_month, year} = props;
	return(
		<View style={{marginBottom:30}}>
			<Text style={{fontSize:18,fontWeight:600,textAlign:'center'}}>
				Monthly Statistics Report
			</Text>
			<Text style={{fontSize:10,fontWeight:600,textAlign:'center',marginBottom:15}}>
				(Year {year})
			</Text>
			<Table data={deals_by_month}>
				<TableHeader>
					<TableCell style={styles.cell}>
						Month
					</TableCell>
					<TableCell style={styles.cell}>
						Number of New Deals
					</TableCell>
					<TableCell style={styles.cell}>
						Revenue of New Deals
					</TableCell>
					<TableCell style={styles.cell}>
						Number of Deals Won
					</TableCell>
					<TableCell style={styles.cell}>
						Revenue of Deals Won
					</TableCell>
					<TableCell style={styles.cell}>
						Conversion Rate
					</TableCell>
					<TableCell style={styles.cell}>
						Average Spend
					</TableCell>
				</TableHeader>
				<TableBody>
					<DataTableCell
						style={{fontSize:10, padding:4}}
						getContent={(r) => r.monthname}
					/>
					<DataTableCell
						style={styles.cell}
						getContent={(r) => r.number_of_new_deals}
					/>
					<DataTableCell
						style={{fontSize:10, padding:4, textAlign:'right'}}
						getContent={(r) => formatNumber(r.revenue_of_new_deals)}
					/>
					<DataTableCell
						style={styles.cell}
						getContent={(r) => r.number_of_deals_won}
					/>
					<DataTableCell
						style={{fontSize:10, padding:4, textAlign:'right'}}
						getContent={(r) =>  formatNumber(r.revenue_of_deals_won)}
					/>
					<DataTableCell
						style={{fontSize:10, padding:4, textAlign:'right'}}
						getContent={(r) =>  formatNumber(r.conversion_rate)}
					/>
					<DataTableCell
						style={{fontSize:10, padding:4, textAlign:'right'}}
						getContent={(r) =>  formatNumber(r.average_spend)}
					/>
				</TableBody>
			</Table>
		</View>
	)
}

export default ReportPDF;
