import React from 'react';
import { Navigate } from 'react-router-dom';
import {connect} from 'react-redux';
import {login, typing} from '../actions/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';

import { ErrorMessage } from '../components/FormElements';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#33384d",
  },
  form: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {

  const classes = useStyles();

  const handleFormChange = (e) => {
    props.typing({[e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.login(props.user);
  }

  if(props.isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <ErrorMessage
            error={props.error}
            className="mt-4  text-left"
          />
          <TextField
            required
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleFormChange}
        />
        <TextField
            required
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleFormChange}
        />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            { props.isProcessing ? 'Signing In ...' : 'Sign In' }
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
    </Container>
  );
}

const mapStateToProps = state => ({
  error: state.Auth.error,
  isProcessing: state.Auth.isProcessing,
  isLoggedIn: state.Auth.isLoggedIn,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  login: (payload) => login(payload),
  typing: (payload) => typing(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
