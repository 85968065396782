import React from 'react';
import { makeStyles } from '@mui/styles';
import { Alert, AlertTitle } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const FormErrorMessage = (props) => {
  const classes = useStyles();

  if (!props.error) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <ul>
          {props.error.map((err, i) => {
            return <li key={i}>{err}</li>;
          })}
        </ul>
      </Alert>
    </div>
  );
};

export default FormErrorMessage;
