import React from 'react';
import { connect } from 'react-redux';
import { clearForm, load, save, remove } from '../../actions/deal_comment';

import { makeStyles } from '@mui/styles';
import { Paper, Button, Box, IconButton } from '@mui/material';
import { AttachFile as AttachFileIcon, Close as CloseIcon } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(theme => ({
  cardBorder: {
		border: '1px solid #e6e6e6',
		boxShadow:' none !important',
	},
}));

const DealCommentNew = (props) => {

	const classes = useStyles();

	const [content, setContent] = React.useState(EditorState.createEmpty());
	const  onEditorStateChange = (editorState) => {
		setContent(editorState);
	}

	const handleFormSubmit = (event) => {
		event.preventDefault();
		props.save({
			comment: draftToHtml(convertToRaw(content.getCurrentContent())),
			files: files
		});
		setContent(EditorState.createEmpty());
		setFiles([]);
	}

	const [files, setFiles] = React.useState([]);
	const handleFileUpload = (event) => {
		if (event.target.files && event.target.files[0]) {
			let newFiles = files.concat(Array.from(event.target.files));
			setFiles(newFiles)
			document.getElementById('file-upload').value = null;
		}
	}

	const handleRemoveFile = (index) => {
		let newFiles = files.filter(item => item !== files[index]);
		setFiles(newFiles);
	}

	return(
		<div>
			<Row>
				<Col>
					<Paper className={classes.cardBorder} >
						<Editor
							editorState={content}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={onEditorStateChange}
						/>
					</Paper>
				</Col>
			</Row>
			<Row className="mb-4">
				<Col xs={7}>
					<FileUploadList
						files={files}
						handleRemoveFile={(index) => handleRemoveFile(index)}
					/>
				</Col>
				<Col xs={5} className="mt-1 text-right">
					<form autoComplete="off" onSubmit={handleFormSubmit}>
						<input
							accept="image/*"
							className="d-none"
							id="file-upload"
							multiple
							type="file"
							onChange={(event) => handleFileUpload(event)}
						/>
						<label htmlFor="file-upload" className="mr-1">
							<Button component="span" color="primary" size="small">
								Upload
							</Button>
						</label>
						<Button
							size="small"
							type="submit"
							variant="contained"
							color="secondary"
							disabled={content.getCurrentContent().hasText() ? false : true}
						>
							Save Note
						</Button>
					</form>
				</Col>
			</Row>

		</div>
	)
}

const FileUploadList = (props) => {
	return(
		<div
			style={{ width: '100%' }} className="mt-1">
			{
				props.files && props.files.map((row, index) => {
					return (
						<Box
							display="flex"
							p={1}
							bgcolor="background.paper"
							key={index}
							className="p-0 pt-1 pl-1"
						>
							<Box flexGrow={1} bgcolor="grey.100" className="upload-file-list-title" >
								<AttachFileIcon className="ml-1 mr-1" style={{ fontSize:'10px' }} />
								{ row.name }
							</Box>
							<Box bgcolor="grey.100" className="upload-file-list-action">
								<label htmlFor="icon-button-file" className="m-0" title="remove">
									<IconButton
										color="primary"
										aria-label="upload picture"
										component="span"
										size="small"
										onClick={() => props.handleRemoveFile(index)}
									>
										<CloseIcon style={{ fontSize:'10px' }}/>
									</IconButton>
								</label>
							</Box>
						</Box>
					)
				})
			}
    </div>
	)
}

const mapStateToProps = state => ({
	success: state.DealComment.success,
	comments: state.DealComment.comments,
});

const mapDispatchToProps = {
	clearForm: () => clearForm(),
	load: (payload) => load(payload),
	save: (payload) => save(payload),
	remove: (payload) => remove(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealCommentNew);
