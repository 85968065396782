import {AUTH} from '../constants/auth';

const initialState = {
  error:'',
  isProcessing: false,
  isLoggedIn: false,
  token: '',
  user: {
		id:'',
		email:'',
		password:'',
		lastname:'',
		firstname:'',
		avatar:''
	},
};

const Auth = (state = initialState, action) => {

	let newState = JSON.parse(JSON.stringify(state));

	if(localStorage.getItem('token')) {
		newState.isLoggedIn = true;
		newState.token = localStorage.getItem('token');
		newState.user = JSON.parse(localStorage.getItem('user'));
	}

  switch(action.type) {

    case AUTH.LOGIN_REQUEST : {
			newState.isProcessing = true;
			newState.error = false;
			newState.isLoggedIn = false;
			return newState;
    }

    case AUTH.LOGIN_SUCCESS : {

			newState.error = false;
			newState.isProcessing = false;

			newState.isLoggedIn = true;
			newState.token = action.payload.token;
			newState.user = action.payload.user;

			localStorage.setItem('token', action.payload.token);
			localStorage.setItem('user', JSON.stringify(action.payload.user));

      return newState;
    }

    case AUTH.LOGIN_ERROR : {

			newState.isProcessing = false;
			newState.isLoggedIn = false;

			let err = [];
			let error = action.payload.errors ? action.payload.errors : action.payload.error;

			if(typeof error === 'object') {
				for(let i in error) {
					err.push(error[i][0]);
				}
			}
			else if(Array.isArray(error)) {
				err = error;
			}
			else {
				err[0] = error;
			}

			newState.error = err;

			return newState;
    }

    case AUTH.LOGOUT : {

			localStorage.removeItem('token');
			localStorage.removeItem('user');

			newState.isLoggedIn = false;
			newState.token = "";
			newState.user = {id:'', email:'', password:'',  lastname:'', firstname:'', avatar:''};

			return newState;
    }

    case AUTH.TYPING : {
        newState.error = false;
        let key = Object.keys(action.payload)[0];
        newState.user[key] = action.payload[key];
        return newState;
    }

		case AUTH.LOGIN_UPDATE_PROFILE : {

			newState.token = action.payload.token;
			newState.user = action.payload.user;

			localStorage.setItem('token', action.payload.token);
			localStorage.setItem('user', JSON.stringify(action.payload.user));

			return newState;
		}

		case AUTH.LOGIN_UPDATE_AVATAR : {
			newState.user.avatar = action.payload.avatar;
			localStorage.setItem('user', JSON.stringify(newState.user));
			return newState;
		}

    default: return newState;
	}



}

export default Auth;
