import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import {ArrowForwardIosRounded} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
	PageTitle: {
		color:'#6e768e',
		'& svg': {
			color:'#6e768e',
			marginRight:'7px',
			fontSize:'25px',
			marginTop:'-5px'
		}
	},
	SubTitle: {
		color:'#8f95a8',
		'& svg': {
			color:'#8f95a8',
			marginLeft:'6px',
			marginRight:'6px',
			fontSize:'20px',
			marginTop:'-5px'
		}
	},
}));

const PageHeader = (props) => {

	const classes = useStyles();

  return (
		<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h5" component="span" className={classes.PageTitle}>
					{props.icon}
					{props.headerTitle}
				</Typography>
				{
					props.subTitle && (
						<Typography variant="h6" component="span" className={classes.SubTitle}>
							<ArrowForwardIosRounded />
							{props.subTitle}
						</Typography>
					)
				}
			</Grid>
		</Grid>
  );
}

export default PageHeader;
