import React from 'react';
import { makeStyles } from '@mui/styles';
import { Alert, AlertTitle } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AlertFormSuccess = (props) => {
  const { message } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {message}
      </Alert>
    </div>
  );
};

export default AlertFormSuccess;
