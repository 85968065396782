import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/styles';

import store from './store';
import { theme } from './theme.js';

const Wrapper = ({ children, ...props }) => {
  return (
    <HashRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Provider>
    </HashRouter>
  );
};

export default Wrapper;
