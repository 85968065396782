import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { typing, save } from '../actions/contact';
import { load } from '../actions/contact_builder';
// import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextareaAutosize,
  // Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  MenuItem,
  // LinearProgress,
} from '@mui/material';
// import FormErrorMessage from "../components/FormErrorMessage";
// import { hasSpecialCharacter } from "../helpers/GeneralFunctions.js";
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { isObjectEmpty } from '../helpers/GeneralFunctions';
import AlertFormError from '../components/AlertFormError';

// const useStyles = makeStyles((theme) => ({
// 	DialogTitle: {
// 		color: "#fff",
// 		backgroundColor: "#323a46",
// 		borderRadius: "1px !important",
// 		padding: "12px 24px !important",
// 	},
// 	TextArea: {
// 		width: "100%",
// 		padding: "5px",
// 		borderRadius: "3px",
// 	},
// }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ContactEditDialog = (props) => {
  // const classes = useStyles();

  const [isDisable, setIsDisable] = useState(true);
  const [contact_form, contact_setForm] = useState([]);

  // useEffect(() => {
  // 	props.load({ user_id: props.user.id });
  // }, []);

  useEffect(() => {
    let form = props.contact_form.contacts_other_info_details
      ? JSON.parse(props.contact_form.contacts_other_info_details)
      : [];
    contact_setForm(form);
  }, [props.contact_form]);

  const contact_info = props.contact.additional_info
    ? JSON.parse(props.contact.additional_info)
    : props.contact;

  useEffect(() => {
    contact_form.map((item) => {
      let field = item.title.replace(/\s/g, '_').toLowerCase();
      let value = null;
      if (props.contact.id) {
        value = contact_info[item.title.replace(/\s/g, '_').toLowerCase()];
      }
      appendCustomformToState(field, value);
    });
  }, [contact_form]);

  const appendCustomformToState = (field, value) => {
    props.typing({ [field]: value });
  };

  const handleFormChange = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let type = event.target.type;
    let checked = event.target.checked;

    // let pattern = ["phone", "email"].indexOf(field) !== -1 ? field : "standard";

    setIsDisable(false);

    // if (hasSpecialCharacter(value, pattern) && type !== "date") {
    // 	return;
    // }

    if (field == 'First Name' || field == 'Last Name') {
      field = field.toLowerCase().replace(/\s/g, '');
    } else {
      field = field.toLowerCase().replace(/\s/g, '_');
    }

    if (type === 'checkbox') {
      let newOptions = props.contact[field] ?? [];
      if (checked) {
        newOptions.push(value);
      } else {
        newOptions = newOptions.filter((option) => option != value);
      }
      value = newOptions;
    }

    props.typing({ [field]: value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let response = await props.save();
    if (response) {
      props.handleSaveSuccess();
    }
  };

  useEffect(() => {
    if (props.success) {
      props.saveEditorModal();
    }
  }, [props.success]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChangePhone = (phone) => {
    setIsDisable(false);
    props.typing({ phone: phone });
  };

  if (!props.open) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.closeEditorModal()}
        maxWidth="sm"
        fullWidth
      >
        <form
          autoComplete="off"
          onSubmit={handleFormSubmit}
          style={{ display: 'flex', flexDirection: 'column', maxHeight: '90vh' }}
        >
          <DialogTitle className="modal-header">Update Contact Details</DialogTitle>
          <div style={{ maxHeight: '85vh', overflowY: 'auto' }}>
            <DialogContent>
              <Grid container spacing={2} className="pt-3">
                {/* <Grid item xs={12}>
									<FormErrorMessage error={props.error} />
								</Grid> */}
                {!isObjectEmpty(props.error) && (
                  <Grid item xs={12}>
                    <AlertFormError errors={props.error} />
                  </Grid>
                )}
                {contact_form.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    {item.title == 'Email' ? (
                      <TextField
                        fullWidth
                        type="email"
                        size="small"
                        required={item.required}
                        label={Capitalize(item.title)}
                        name={item.title}
                        value={props.contact[item.title.replace(/\s/g, '_').toLowerCase()] || ''}
                        onChange={(event) => handleFormChange(event)}
                      />
                    ) : item.title == 'Phone' ? (
                      <PhoneInput
                        country={'au'}
                        countryCodeEditable={false}
                        value={props.contact[item.title.replace(/\s/g, '_').toLowerCase()]}
                        autoFormat
                        onChange={handleChangePhone}
                        inputStyle={{ width: '100%' }}
                        inputProps={{
                          name: item.title,
                          required: item.required,
                          autoFocus: true,
                        }}
                        isValid={(value, country) => {
                          if (value.match(/12345/)) {
                            return 'Invalid value: ' + value + ', ' + country.name;
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                      />
                    ) : item.type == 'Short Answer' ? (
                      <TextField
                        fullWidth
                        required={item.required}
                        label={Capitalize(item.title)}
                        name={item.title}
                        defaultValue={
                          item.title == 'First Name' || item.title == 'Last Name'
                            ? contact_info[item.title.replace(/\s/g, '').toLowerCase()]
                            : contact_info[item.title.replace(/\s/g, '_').toLowerCase()]
                        }
                        onChange={(event) => handleFormChange(event)}
                      />
                    ) : item.type == 'Paragraph' ? (
                      <TextareaAutosize
                        className="px-2 pt-2"
                        style={{
                          border: '1px solid #bdbdbd',
                          width: '100%',
                          borderRadius: '5px',
                        }}
                        placeholder={item.title + '...'}
                        required={item.required}
                        label={Capitalize(item.title)}
                        name={item.title}
                        defaultValue={contact_info[item.title.replace(/\s/g, '_').toLowerCase()]}
                        onChange={(event) => handleFormChange(event)}
                        minRows={2}
                      />
                    ) : item.type == 'Multiple Choice' || item.type == 'Checkbox' ? (
                      <FormControl fullWidth required={item.required} error={item.required}>
                        <FormLabel>{Capitalize(item.title)}</FormLabel>
                        <>
                          {item.type == 'Multiple Choice' && (
                            <RadioGroup
                              defaultValue={
                                contact_info[item.title.replace(/\s/g, '_').toLowerCase()]
                              }
                            >
                              {item.options.map((option, i) => (
                                <FormControlLabel
                                  key={i}
                                  name={item.title}
                                  value={option}
                                  onChange={(event) => handleFormChange(event)}
                                  control={<Radio />}
                                  label={option}
                                />
                              ))}
                            </RadioGroup>
                          )}
                          {item.type == 'Checkbox' && (
                            <FormGroup>
                              {item.options.map((option, i) => (
                                <FormControlLabel
                                  key={i}
                                  control={
                                    <Checkbox
                                      defaultChecked={
                                        contact_info[item.title.replace(/ /g, '_').toLowerCase()]
                                          ? contact_info[
                                              item.title.replace(/ /g, '_').toLowerCase()
                                            ].includes(option)
                                          : false
                                      }
                                      name={item.title}
                                      value={option}
                                      onChange={(event) => handleFormChange(event)}
                                    />
                                  }
                                  label={option}
                                />
                              ))}
                            </FormGroup>
                          )}
                        </>
                      </FormControl>
                    ) : item.type == 'Dropdown' ? (
                      <TextField
                        fullWidth
                        name={item.title}
                        required={item.required}
                        label={Capitalize(item.title)}
                        defaultValue={contact_info[item.title.replace(/\s/g, '_').toLowerCase()]}
                        onChange={(event) => handleFormChange(event)}
                        select
                      >
                        {item.options.map((option, i) => (
                          <MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        fullWidth
                        required={item.required}
                        label={Capitalize(item.title)}
                        type="date"
                        name={item.title}
                        defaultValue={
                          contact_info[item.title.replace(/\s/g, '_').toLowerCase()]
                            ? moment(
                                contact_info[item.title.replace(/\s/g, '_').toLowerCase()]
                              ).format('YYYY-MM-DD')
                            : ''
                        }
                        onChange={(event) => handleFormChange(event)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  </Grid>
                ))}
                {/*<Grid item xs={12} className="text-right">
                  <div className="flex space-x-4 float-right">
                    <div>
                      <Button onClick={props.closeEditorModal}>Cancel</Button>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isDisable}
                      >
                        {props.isProcessing ? 'Saving ...' : 'Save'}
                      </Button>
                    </div>
                  </div>
                </Grid>*/}
              </Grid>
            </DialogContent>
          </div>
          <DialogActions>
            <Button onClick={props.closeEditorModal} color="primary" className="mr-1 mb-2">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className="mr-3 mb-2"
              disabled={isDisable}
            >
              {props.isProcessing ? 'Saving ...' : 'Save'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isProcessing: state.Contact.isProcessing,
  success: state.Contact.success,
  error: state.Contact.error,
  contact: state.Contact.contact,
  contact_form: state.ContactBuilder.contact_form,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  typing: (payload) => typing(payload),
  save: () => save(),
  load: (payload) => load(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditDialog);
