import React from 'react';
import {
  Route,
  Routes as Switch,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoutes from './PrivateRoutes';
import Login from '../../pages/Login';
import Dashboard from '../../pages/Dashboard';
import Contacts from '../../pages/Contacts';
import ContactEdit from '../../pages/ContactEdit';
import ContactOverview from '../../pages/ContactOverview';
import Deals from '../../pages/Deals';
import DealOverview from '../../pages/DealOverview';
import DealBoard from '../../pages/DealBoard';
import Reports from '../../pages/Reports';
import Settings from '../../pages/Settings';
import FormBuilder from '../../pages/FormBuilder';
import FormBuilderContact from '../../pages/FormBuilderContact';
import Venues from '../../pages/Venues';
import VenueEdit from '../../pages/VenueEdit';
import ReportPDF from '../../pages/ReportPDF';
import UnassignedDeals from '../../pages/UnassignedDeals';
import PageNotFound from '../../pages/PageNotFound';
import TestInquiryToStg from '../../pages/TestInquiryToStg';
import TestInquiryToDev from '../../pages/TestInquiryToDev';
import AdminRoutes from './AdminRoutes';
import FormBuilderDeal from '../../pages/FormBuilderDeal';

const Routes = (props) => {
  return (
    <Switch>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          !props.isLoggedIn ? (
            <Login />
          ) : props.user.role !== 'god_admin' ? (
            <Navigate to="/deals" />
          ) : (
            <Dashboard />
          )
        }
      />
      {/* <Route path="/404" element={<PageNotFound />} /> */}
      <Route path="/test-inquiry-to-stg" element={<TestInquiryToStg />} />
      <Route path="/test-inquiry-to-dev" element={<TestInquiryToDev />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/contacts/edit/:id" element={<ContactEdit />} />
        <Route path="/contacts/view/:id" element={<ContactOverview />} />
        <Route
          path="/deals"
          element={props.user.role === 'god_admin' ? <Deals /> : <DealBoard />}
        />
        <Route path="/deals/view/:id" element={<DealOverview />} />
        <Route path="/settings" element={<Settings />} />

        <Route element={<AdminRoutes />}>
          <Route path="/venues" element={<Venues />} />
          <Route path="/venues/edit/:id" element={<VenueEdit />} />
          <Route path="/deals/unassigned" element={<UnassignedDeals />} />
          <Route path="/dynamic-forms" element={<FormBuilder />} />
          <Route path="/dynamic-forms/contacts" element={<FormBuilderContact />} />
          <Route path="/dynamic-forms/deals" element={<FormBuilderDeal />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/pdf" element={<ReportPDF {...props} />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Switch>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  user: state.Auth.user,
});

export default connect(mapStateToProps)(withRouter(Routes));
