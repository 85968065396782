import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Toolbar, Typography, Paper, IconButton, Tooltip } from '@mui/material';
import TableHeader from '../components/table/TableHeader';
import { stableSort, getSorting } from '../components/table/TableFunctions';
import {formatNumber} from '../helpers/GeneralFunctions.js';

const headCells = [
	{ id: 'type', align: "left", label: 'Event Type', },
	{ id: 'number_of_new_deals', align: "center", label: 'Number of new Deals', },
  { id: 'revenue_of_new_deals', align: "right", label: 'Revenue of New Deals' },
  { id: 'number_of_deals_won', align: "center", label: 'Number of Deals Won' },
  { id: 'revenue_of_deals_won', align: "right", label: 'Revenue of Deals Won' },
	{ id: 'average_spend', align: "right", label: 'Average Spend' },
];

TableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle">
				Deals By Event Type
			</Typography>
			{/* <Tooltip title="Filter list">
				<IconButton aria-label="filter list">
					<FilterListIcon />
				</IconButton>
			</Tooltip> */}
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
		width: '100%',
		border: '1px solid #e2e0e0',
  },
  table: {
		width: '100%',
		'& tr:last-child': {
			'& td': {
				borderBottom: 'none',
			}
		}
  },
}));

const ReportDealsByType = (props) => {

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created_at');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

	if(props.deals_by_type.length === 0) {
		return null;
	}

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
						id="by_type"
            className={classes.table}
          >
						<TableHeader
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>

            <TableBody>
              {stableSort(props.deals_by_type, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                    >
											<TableCell>
												{item.type}
											</TableCell>
                      <TableCell align="center">
												{item.number_of_new_deals}
											</TableCell>
                      <TableCell align="right">
													{formatNumber(item.revenue_of_new_deals)}
											</TableCell>
                      <TableCell align="center">
												{item.number_of_deals_won}
											</TableCell>
                      <TableCell align="right">
												{formatNumber(item.revenue_of_deals_won)}
											</TableCell>
											<TableCell align="right">
												{formatNumber(item.average_spend)}
											</TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={props.deals_by_type.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({
	user: state.Auth.user,
  deals_by_type: state.Report.deals_by_type,
});

export default connect(
  mapStateToProps
)(ReportDealsByType);
