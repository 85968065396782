/* global global */
import { DEAL } from '../constants/deal';

export function clearForm() {
  return { type: DEAL.DEAL_CLEAR_FORM };
}

export function changeView(payload) {
  return { type: DEAL.DEAL_CHANGE_VIEW, payload: payload };
}

export function clearFlag() {
  return { type: DEAL.DEAL_CLEAR_FLAG };
}

export function remove(payload) {
  return async function (dispatch, getState) {
    let url = `/deal/${payload.id}`;

    if (getState().Auth.user.role === 'venue') {
      url += `?venue_id=${getState().Auth.user.venue_id}`;
    }

    try {
      let response = await global.axios({
        method: 'delete',
        url: url,
      });
      dispatch({ type: DEAL.DEAL_LOAD_ALL, payload: response.data });
      return true;
    } catch (error) {
      console.error(error);
    }
  };
}

export function load(payload) {
  return async function (dispatch) {
    if (Number.isInteger(parseInt(payload.id))) {
      try {
        let response = await global.axios({
          method: 'get',
          url: `/deal/${payload.id}`,
        });
        dispatch({ type: DEAL.DEAL_LOAD, payload: response.data });
        return true;
      } catch (error) {
        return false;
      }
    }
  };
}

export function loadAll() {
  return function (dispatch, getState) {
    let url = '/deal';

    if (getState().Auth.user.role === 'venue') {
      url += `?venue_id=${getState().Auth.user.venue_id}`;
    }

    global
      .axios({
        method: 'get',
        url: url,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL.DEAL_LOAD_ALL, payload: data });
      })
      .catch(function (error) {});
  };
}

export function loadUnassigned() {
  return async function (dispatch) {
    try {
      let response = await global.axios({
        method: 'get',
        url: `/unassigned_deals`,
      });
      dispatch({ type: DEAL.DEAL_LOAD_UNASSIGNED, payload: response.data });
    } catch (error) {
      console.error(error);
    }
  };
}

export function assignDealToVenue(payload) {
  return async function (dispatch) {
    payload._method = 'put';
    try {
      await global.axios({
        method: 'post',
        url: `/assign_deal_to_venue/${payload.id}`,
        data: payload,
      });
      return true;
    } catch (error) {
      return false;
    }
  };
}

export function save(formFields) {
  return async function (dispatch, getState) {
    dispatch({ type: DEAL.DEAL_SAVE_REQUEST });

    // console.log('getState().Deal.deal', JSON.parse(getState().Deal.deal.additional_info))
    // let postData = getState().Deal.deal;
    // console.log('save', formFields)
    const { additional_info, contact, stage, venue, ...postData } = formFields;
    postData['venue_id'] = getState().Auth.user.venue_id;
    postData['user_id'] = getState().Auth.user.id;

    let method = 'post';
    let url = '/deal';

    if (Number.isInteger(parseInt(postData.id))) {
      method = 'put';
      url += `/${postData.id}`;
    }

    // console.log('postData', postData)
    try {
      let response = await global.axios({
        method: method,
        url: url,
        data: postData,
      });
      dispatch({ type: DEAL.DEAL_SAVE_SUCCESS, payload: response.data });
      return true;
    } catch (error) {
      if (error.response.status === 422) {
        dispatch({ type: DEAL.DEAL_SAVE_ERROR, payload: error.response.data.errors });
      }
      return false;
    }
  };
}

export function typing(payload) {
  return { type: DEAL.DEAL_TYPING, payload: payload };
}

export function updateStage(payload) {
  return function (dispatch, getState) {
    let deal = getState().Deal.deal;

    let postData = {
      venue_id: getState().Auth.user.venue_id,
      user_id: getState().Auth.user.id,
      deal_id: deal.id,
      from_stage_id: deal.stage.stage_id,
      stage_id: payload.stage_id,
      from_position: deal.stage.position,
    };

    return global
      .axios({
        method: 'post',
        url: `/deal_stage`,
        data: postData,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL.DEAL_UPDATE_STAGE, payload: data });
        dispatch({ type: DEAL.DEAL_LOAD_ALL, payload: data });
      })
      .catch(function (error) {});
  };
}

export function updateBoardStage(payload) {
  return function (dispatch, getState) {
    let card = getCardData(
      getState().Deal.board.columns,
      payload.source.fromColumnId,
      payload.source.fromPosition
    );

    let to_deal_id = '';
    if (payload.source.fromColumnId === payload.destination.toColumnId) {
      let to_card = getCardData(
        getState().Deal.board.columns,
        payload.destination.toColumnId,
        payload.destination.toPosition
      );
      to_deal_id = to_card.id;
    }

    let postData = {
      venue_id: getState().Auth.user.venue_id,
      user_id: getState().Auth.user.id,
      deal_id: card.id,
      to_deal_id: to_deal_id,
      from_stage_id: payload.source.fromColumnId,
      stage_id: payload.destination.toColumnId,
      from_position: payload.source.fromPosition,
      position: payload.destination.toPosition,
    };

    dispatch({ type: DEAL.DEAL_BOARD_UPDATE_STAGE, payload: postData });

    return global
      .axios({
        method: 'post',
        url: `/deal_stage`,
        data: postData,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL.DEAL_LOAD_ALL, payload: data });
      })
      .catch(function (error) {});
  };
}

export function swapBoardColumn(payload) {
  return function (dispatch, getState) {
    payload.venue_id = getState().Auth.user.venue_id;

    dispatch({ type: DEAL.DEAL_BOARD_SWAP_COLUMN, payload: payload });

    return global
      .axios({
        method: 'post',
        url: `/swap_stage`,
        data: payload,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL.DEAL_LOAD_ALL, payload: data });
      })
      .catch(function (error) {});
  };
}

function getCardData(lanes, laneId, position) {
  let cardData = {};
  lanes.filter(function (lane) {
    if (lane.id === laneId) {
      lane.cards.filter(function (card) {
        if (card.position === position) {
          cardData = card;
        }
      });
    }
  });
  return cardData;
}

export function loadStatistics(payload) {
  return function (dispatch, getState) {
    let venue_id = '';

    if (getState().Auth.user.role === 'venue') {
      venue_id = getState().Auth.user.venue_id;
    } else {
      venue_id = payload.venue_id ? payload.venue_id : 'all';
    }

    global
      .axios({
        method: 'get',
        url: `/stats/${venue_id}`,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL.DEAL_LOAD_STATISTICS, payload: data });
      })
      .catch(function (error) {});
  };
}
