import React, { useEffect } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearForm, load } from '../actions/contact';
import { loadAll as loadComments, clearComments } from '../actions/contact_comment';
import { loadAll as loadStages } from '../actions/stage';
import { DateToString } from '../helpers/DateFunctions.js';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Backdrop,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import {
  EventNote as EventNoteIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  PeopleOutlineRounded,
} from '@mui/icons-material';
import PageHeader from '../components/PageHeader.js';
import ContactComments from './ContactComments.js';
import ContactEditDialog from './ContactEditDialog.js';
import { SuccessSnackbar2 } from '../components/Snackbars.js';
import { load as load_form } from '../actions/contact_builder';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItem: {
    padding: 0,
  },
  stage: {
    color: '#fff',
    backgroundColor: '#00acc1',
    padding: '3px 5px',
    borderRadius: '6px',
  },
  ButtonIcon: {
    fontSize: '12px',
    marginRight: '5px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ContactOverview = (props) => {
  const classes = useStyles();
  const [loadComplete, setLoadComplete] = React.useState(false);

  useEffect(() => {
    props.clearForm();
    async function fetchData() {
      await props.load_form({ user_id: props.user.id });
      let loaded = await props.load({ id: props.params.id });
      setLoadComplete(loaded);
      if (loaded) {
        await props.loadStages();
        await props.clearComments();
        await props.loadComments({ id: props.params.id });
      } else {
        props.navigate('/404');
      }
    }
    fetchData();
  }, []);

  const [editorOpen, setEditorOpen] = React.useState(false);
  const openEditorModal = () => {
    setEditorOpen(true);
  };
  const saveEditorModal = () => {
    setEditorOpen(false);
    props.clearForm();
    props.load({ id: props.params.id });
  };

  const closeEditorModal = () => {
    setEditorOpen(false);
  };

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSaveSuccess = () => {
    setSnackbarMessage('Contact successfully saved.');
    openSnackbar();
  };

  if (!loadComplete) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={10} xs={12}>
                <PageHeader
                  icon={<PeopleOutlineRounded />}
                  headerTitle="Contacts"
                  subTitle="Contact Overview"
                />
              </Grid>
              <Grid item sm={2} xs={12} className="text-right py-1">
                {props.user.role === 'venue' && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => openEditorModal()}
                    disabled={!props.contact.id}
                  >
                    <EditIcon className={classes.ButtonIcon} />
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {snackbarOpen && (
          <SuccessSnackbar2
            open={snackbarOpen}
            openSnackbar={() => openSnackbar()}
            closeSnackbar={() => closeSnackbar()}
            message={snackbarMessage}
          />
        )}
        <ContactEditDialog
          open={editorOpen}
          openEditorModal={() => openEditorModal()}
          saveEditorModal={() => saveEditorModal()}
          closeEditorModal={() => closeEditorModal()}
          handleSaveSuccess={() => handleSaveSuccess()}
        />

        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Paper className="p-3">
								<FeaturedDeal {...props} />
							</Paper>
						</Grid>
					</Grid>
				</Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ContactDetails {...props} />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className="p-3">
                      <Typography variant="body1" component="div" className="font-weight-bold pb-1">
                        Active Deals
                      </Typography>
                      <Deals {...props} deals={props.contact.deals} />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper className="p-3">
                      <Typography variant="body1" component="div" className="font-weight-bold pb-1">
                        Customer Notes
                      </Typography>
                      <ContactComments />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className="p-3">
                      <Typography variant="body1" component="div" className="font-weight-bold pb-1">
                        Archived Deals
                      </Typography>
                      <Deals {...props} deals={props.contact.archived_deals} />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};

const ContactDetails = (props) => {
  const form = props.contact_form.contacts_other_info_details
    ? JSON.parse(props.contact_form.contacts_other_info_details)
    : [];
  const data = props.contact.additional_info
    ? JSON.parse(props.contact.additional_info)
    : props.contact;

  return (
    <Card>
      <CardHeader
        className="pt-3 pb-0"
        title={
          <Typography variant="body1" component="span" className="font-weight-bold">
            Contact Details
          </Typography>
        }
      />
      <CardContent>
        {!props.contact.id ? (
          <>
            <LinearProgress color="inherit" />
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4} className="font-weight-bold">
                Name
              </Grid>
              <Grid item xs={8}>{`${data.firstname} ${data.lastname}`}</Grid>
            </Grid>
            {props.contact_form &&
              form.map((item, index) => (
                <div key={index}>
                  {(item.title !== 'First Name' || item.title !== 'Last Name') &&
                    data[item.title.replace(/ /g, '_').toLowerCase()] !== undefined &&
                    data[item.title.replace(/ /g, '_').toLowerCase()] !== null && (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={4}
                          className="font-weight-bold"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {item.title + ':'}
                        </Grid>
                        <Grid item xs={8}>
                          {item.type == 'Date'
                            ? moment(data[item.title.replace(/ /g, '_').toLowerCase()]).format(
                                'MMMM DD, YYYY'
                              )
                            : data[item.title.replace(/ /g, '_').toLowerCase()] + ' '}
                        </Grid>
                      </Grid>
                    )}
                </div>
              ))}
          </>
        )}
      </CardContent>
    </Card>
  );
};

const Deals = (props) => {
  const classes = useStyles();

  return (
    <List dense>
      {props.deals &&
        props.deals.map((item, index) => {
          let stage = props.stages.filter((stage) => stage.stage_id === item.stage.stage_id);
          let stageName = '';
          if (stage.length > 0) {
            stageName = stage[0].name ? stage[0].name : stage[0].stage.name;
          }
          return (
            <ListItem dense disableGutters key={index} className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.small}>
                  <EventNoteIcon fontSize="small" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container spacing={0}>
                    <Grid item xs={7}>
                      <NavLink to={'/deals/view/' + item.id}>
                        <Typography
                          variant="body2"
                          component="div"
                          style={{ color: '#00acc1', fontWeight: 600 }}
                        >
                          {item.title}
                        </Typography>
                      </NavLink>
                      {item.date && (
                        <Typography variant="caption" component="div" color="textSecondary">
                          {DateToString(item.date)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={4} className="text-right pr-3 pt-2">
                      <Typography variant="caption" component="span" className={classes.stage}>
                        {stageName}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
              <ListItemSecondaryAction>
                <NavLink to={'/deals/view/' + item.id} title="View deal">
                  <IconButton edge="end" aria-label="view">
                    <VisibilityIcon />
                  </IconButton>
                </NavLink>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
    </List>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  success: state.Contact.success,
  contact: state.Contact.contact,
  stages: state.Stage.stages,
  contact_form: state.ContactBuilder.contact_form,
});

const mapDispatchToProps = {
  clearForm: () => clearForm(),
  clearComments: () => clearComments(),
  load: (payload) => load(payload),
  loadComments: (payload) => loadComments(payload),
  loadStages: () => loadStages(),
  load_form: (payload) => load_form(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactOverview));
