import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadStatistics } from '../actions/deal';
import { loadAll as loadVenues } from '../actions/venue';
import { loadAll as loadStages } from '../actions/stage';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, TextField, Paper, Button, Card, CardContent, Avatar } from '@mui/material';
import { AttachMoneyRounded as AttachMoneyRoundedIcon, EqualizerRounded as EqualizerRoundedIcon, EventAvailableRounded as EventAvailableRoundedIcon, PermContactCalendarRounded as PermContactCalendarRoundedIcon, InfoOutlined as InfoOutlinedIcon, DashboardOutlined as DashboardOutlinedIcon } from '@mui/icons-material';
import Autocomplete from '@mui/lab/Autocomplete';
import CountUp from 'react-countup';
import PageHeader from '../components/PageHeader';
import LatestDeals from './LatestDeals';
import LatestContacts from './LatestContacts';
import DealsByTypeChart from '../components/charts/DealsByTypeChart';
import DealsByStageChart from '../components/charts/DealsByStageChart';
import DealsByDateChart from '../components/charts/DealsByDateChart';

const useStyles = makeStyles(theme => ({
  header: {
		fontWeight: 600,
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: '3px'
	},
	buttonLabel: {
		minWidth: '150px'
	},
	autocomplete: {
		float: 'right',
		minWidth: '300px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
    },
	},
	paper: {
		padding: '18px',
	},
	fabButton: {
		minWidth: '20px',
		padding: '20px',
		borderRadius: '50%',
		cursor: 'text',
	},
	fabButtonIcon: {
		fontSize: '20px',
	},
	fabButton1: {
		border: '1px solid rgb(247, 184, 75, 0.6)',
		backgroundColor: 'rgb(247, 184, 75, 0.3)',
		color: 'rgb(247, 184, 75)',
	},
	fabButton2: {
		border: '1px solid rgb(79, 198, 225, 0.6)',
		backgroundColor: 'rgb(79, 198, 225, 0.3)',
		color: 'rgb(79, 198, 225)'
	},
	fabButton3: {
		border: '1px solid rgb(26, 188, 156, 0.6)',
		backgroundColor: 'rgb(26, 188, 156, 0.3)',
		color: 'rgb(26, 188, 156)'
	},
	fabButton4: {
		border: '1px solid rgb(255, 152, 171, 0.6)',
		backgroundColor: 'rgb(255, 152, 171, 0.3)',
		color: 'rgb(255, 152, 171)'
	},
	label: {
		color: '#8c8c8c',
	},
	infoIcon: {
		color: '#3f51b5',
		marginRight: '4px',
		fontSize: '20px',
		marginBottom: '3px'
	},
	infoText: {
		color: '#8c8c8c',
	},
	avatar: {
		backgroundColor: '#00ACC1',
		fontSize: '22px',
		fontWeight: 'bold',
		margin: 'auto',
		marginTop: '7px',
		marginBottom: '5px',
		padding: '28px'
	},
	card: {
		background: 'url(/images/bg-pattern-2.png)',
	}
}));

const styles = {
	avatar: {
		width: 60, 
		height: 60,
	},

	contactAvatar: {
		bgcolor: 'rgb(255, 152, 171, 0.3)', 
		border: '1px solid rgb(255, 152, 171, 0.6)',
	},
	contactIcon: {
		fontSize: 20,
		color: 'rgb(255, 152, 171)',
	},

	dealsWonAvatar: {
		bgcolor: 'rgb(26, 188, 156, 0.3)', 
		border: '1px solid rgb(26, 188, 156, 0.6)',
	},
	dealsWonIcon: {
		fontSize: 20,
		color: 'rgb(26, 188, 156)',
	},

	dealsValueAvatar: {
		bgcolor: 'rgb(247, 184, 75, 0.3)', 
		border: '1px solid rgb(247, 184, 75, 0.6)',
	},
	dealsValueIcon: {
		fontSize: 20,
		color: 'rgb(247, 184, 75)',
	},

	leadsAvatar: {
		bgcolor: 'rgb(79, 198, 225, 0.3)', 
		border: '1px solid rgb(79, 198, 225, 0.6)',
	},
	leadsIcon: {
		fontSize: 20,
		color: 'rgb(79, 198, 225)',
	},
}

const Dashboard = (props) => {

	const classes = useStyles();
	const [venue, setVenue] = React.useState(null);

	useEffect(() => {
		props.loadStatistics({venue_id:null});
		props.loadStages({venue_id:null});

		if(props.user.role === 'god_admin') {
			props.loadVenues();
		}
	}, []);

	const changeVenue = (venue) => {
		setVenue(venue);

		let venue_id = venue ? venue.id : null;
		props.loadStages({venue_id:venue_id});
		props.loadStatistics({venue_id:venue_id});
	}

	return(
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<Grid item sm={6} xs={12}>
						<PageHeader headerTitle="Dashboard" icon={<DashboardOutlinedIcon />}/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<VenueSelector {...props} venue={venue} changeVenue={(venue) => changeVenue(venue)} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Information {...props} />
					<Grid item lg={8} md={12} xs={12}>
						<Grid container direction="row" justifyContent="space-between" spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
										<ContactsStat {...props} />
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
										<DealsWonTotal {...props} />
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
										<LeadsNumber {...props} />
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
										<DealsValue {...props} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<LatestDeals />
							</Grid>
							<Grid item xs={12}>
								<LatestContacts />
							</Grid>
						</Grid>
					</Grid>
					<Grid item lg={4} md={12} xs={12}>
						<Grid container spacing={2}>
							<VenueDetails {...props} venue={venue} />
							<Grid item xs={12}>
								<DealsByTypeChart />
							</Grid>
							<Grid item xs={12}>
								<DealsByStageChart />
							</Grid>
							<Grid item xs={12}>
								<DealsByDateChart />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const VenueSelector = (props) => {

	const classes = useStyles();

	if(props.user.role === 'venue') {
		return null;
	}

	const onChange = (event, venue) => {
		props.changeVenue(venue);
	}

	return (
		<Autocomplete
			size="small"
			className={classes.autocomplete}
      options={props.venues}
			getOptionLabel={option => option.name}
			onChange={onChange}
      renderInput={params => (
        <TextField {...params} label="Select Venue" placeholder="Select Venue" variant="outlined" fullWidth margin="dense" />
      )}
    />
	)
}

const Information = (props) => {

	const classes = useStyles();

	if(props.stats.date_range.length === 0) {
		return null;
	}

	return(
		<Grid item xs={12}>
			<InfoOutlinedIcon className={classes.infoIcon} />
			<Typography variant="body2" component="span" className={classes.infoText}>
				Showing statistics from {props.stats.date_range.from} to {props.stats.date_range.to}
			</Typography>
		</Grid>
	)
}

const VenueDetails = (props) => {

	const classes = useStyles();

	if(!props.venue) {
		return null;
	}

	return (
		<Grid item xs={12}>
			<Card className={classes.card}>
				<CardContent>
					<Avatar aria-label="recipe" className={classes.avatar}>
						{props.venue.name.charAt(0)}
          </Avatar>
					<Typography gutterBottom variant="h5" component="h2" className="text-center">
							{props.venue.name}
						</Typography>
					<Typography variant="h6" color="textSecondary" component="div" className="text-center">
						{props.venue.user.firstname} {props.venue.user.lastname}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="div" className="text-center">
						{props.venue.user.email}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="div" className="text-center">
						{props.venue.address}
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	)
}

const ContactsStat = (props) => {
	const classes = useStyles();

	return(
		<Paper className={classes.paper}>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item xs={4}>
					{/* <Button
						variant="contained"
						className={`${classes.fabButton} ${classes.fabButton4}`}
					>
						<PermContactCalendarRoundedIcon className={`${classes.fabButtonIcon}`}/>
					</Button> */}
					<Avatar
						sx={[styles.avatar, styles.contactAvatar]}
					>
						<PermContactCalendarRoundedIcon sx={styles.contactIcon}/>
					</Avatar>
				</Grid>
				<Grid item xs={8} className="text-right">
					<Typography variant="h4" component="h4" style={{fontWeight:600}}>
						<CountUp end={props.stats.latest_contacts.length}  duration={4} />
					</Typography>
					<Typography variant="body2" component="div" className={classes.label}>
						Total of Contacts
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	)
}

const DealsValue = (props) => {
	const classes = useStyles();

	return(
		<Paper className={classes.paper}>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item xs={4}>
					{/* <Button variant="outlined" className={`${classes.fabButton} ${classes.fabButton1}`}>
						<AttachMoneyRoundedIcon className={`${classes.fabButtonIcon}`}></AttachMoneyRoundedIcon>
					</Button> */}
					<Avatar
						sx={[styles.avatar, styles.dealsValueAvatar]}
					>
						<AttachMoneyRoundedIcon sx={styles.dealsValueIcon}/>
					</Avatar>
				</Grid>
				<Grid item xs={8} className="text-right" >
					<Typography variant="h4" component="h4" style={{fontWeight:600}} >
						<CountUp end={props.stats.deals_value} decimals={2} separator="," />
					</Typography>
					<Typography variant="body2" component="div" className={classes.label}>
						Value of Leads
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	)
}

const LeadsNumber = (props) => {
	const classes = useStyles();

	return(
		<Paper className={classes.paper}>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item xs={4}>
					{/* <Button variant="outlined" className={`${classes.fabButton} ${classes.fabButton2}`}>
						<EqualizerRoundedIcon className={classes.fabButtonIcon} ></EqualizerRoundedIcon>
					</Button> */}
					<Avatar
						sx={[styles.avatar, styles.leadsAvatar]}
					>
						<EqualizerRoundedIcon sx={styles.leadsIcon}/>
					</Avatar>
				</Grid>
				<Grid item xs={8} className="text-right">
					<Typography variant="h4" component="h4" style={{fontWeight:600}} >
						<CountUp end={props.stats.deals_cnt}  duration={4}/>
					</Typography>
					<Typography variant="body2" component="div" className={classes.label}>
						Number of Leads
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	)
}

const DealsWonTotal = (props) => {
	const classes = useStyles();

	return(
		<Paper className={classes.paper}>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item xs={4}>
					{/* <Button variant="outlined" className={`${classes.fabButton} ${classes.fabButton3}`}>
						<EventAvailableRoundedIcon className={classes.fabButtonIcon}></EventAvailableRoundedIcon>
					</Button> */}
					<Avatar
						sx={[styles.avatar, styles.dealsWonAvatar]}
					>
						<EventAvailableRoundedIcon sx={styles.dealsWonIcon}/>
					</Avatar>
				</Grid>
				<Grid item xs={8} className="text-right">
					<Typography variant="h4" component="h4" style={{fontWeight:600}} >
						<CountUp end={props.stats.deals_won_cnt} duration={4} />
					</Typography>
					<Typography variant="body2" component="div" className={classes.label}>
						Deals Won
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	)
}


const mapStateToProps = state => ({
	user: state.Auth.user,
	venues: state.Venue.venues,
	stats: state.Deal.stats,
});

const mapDispatchToProps = {
	loadVenues: () => loadVenues(),
	loadStatistics: (payload) => loadStatistics(payload),
	loadStages: (payload) => loadStages(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
