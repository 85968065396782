import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  loadProfile,
  saveProfile,
  saveAvatar,
  typingProfile,
  clearErrors,
} from '../actions/setting';
import CONFIG from '../config.js';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Typography, Avatar, Button, TextField } from '@mui/material';
import {
  ErrorOutlineRounded as ErrorOutlineRoundedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  PhotoCamera,
} from '@mui/icons-material';
import PageHeader from '../components/PageHeader.js';
import AlertFormError from '../components/AlertFormError';
import SnackbarSuccess from '../components/SnackbarSuccess';
import { isObjectEmpty } from '../helpers/GeneralFunctions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '35px',
    paddingBottom: '60px',
  },
  paper2: {
    padding: '36px',
    paddingTop: '50px;',
  },
  avatar: {
    padding: '65px',
    fontSize: '40px',
    fontWeight: 600,
    backgroundColor: '#82c34c',
  },
  avatarImg: {
    width: '140px !important',
    height: '140px !important',
  },
  textfield: {
    marginBottom: '10px',
  },
  ErrorText: {
    color: '#f50057',
    marginBottom: '10px',
  },
  ErrorIcon: {
    paddingBottom: '4px',
    marginRight: '3px',
  },
  InputError: {
    borderColor: '#f50057',
  },
  GroupLabel: {
    color: '#636669fa',
    fontWeight: 500,
  },
}));

const Settings = (props) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');

  useEffect(() => {
    props.clearErrors();
    props.loadProfile();
  }, []);

  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleFormChange = (event) => {
    if (event.target.name === 'password' || event.target.name === 'password_confirmation') {
      props.typingProfile({ [event.target.name]: event.target.value.replace(/\s/g, '') });
    } else {
      props.typingProfile({ [event.target.name]: event.target.value });
    }
  };

  const handleChangeAvatar = (image) => {
    setAvatarFile(image);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    // return false
    // var validatePassword;

    // if (props.profile.password !== '' && props.profile.password_confirmation !== '') {
    //   if (props.profile.password.length >= 8 && props.profile.password_confirmation.length >= 8) {
    //     validatePassword = true;
    //   } else {
    //     validatePassword = false;
    //   }
    // }

    let success = await props.saveProfile();

    if (success) {
      setSeverity('success');
      setSnackbarMessage('Successfully saved.');
      openSnackbar();
      if (avatarFile) {
        props.saveAvatar(avatarFile);
      }
    }
    setIsSubmitting(false);

    // if (validatePassword) {
    //   console.log('saving...');
    //   if (avatarFile) {
    //     await props.saveAvatar(avatarFile);
    //   }
    //   let success = await props.saveProfile();

    //   if (success) {
    //     setSnackbarMessage('Successfully saved.');
    //     openSnackbar();
    //   }
    // } else {
    //   setSnackbarMessage('Password must atleast 8 characters!');
    //   setSeverity('error');
    //   openSnackbar();
    // }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageHeader headerTitle="Settings" icon={<SettingsOutlinedIcon />} />
      </Grid>
      <Grid item xl={6} lg={8} xs={12}>
        <Paper className={classes.paper}>
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" component="div" className={classes.GroupLabel}>
                  Update Account Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {!isObjectEmpty(props.errors) && <AlertFormError errors={props.errors} />}
                {snackbarOpen && (
                  <SnackbarSuccess
                    message={snackbarMessage}
                    handleClose={() => closeSnackbar()}
                    severity={severity}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AvatarEditor {...props} onChangeAvatar={handleChangeAvatar} />
                  </Grid>
                  <Grid item xs={12}>
                    <VenueInformation
                      {...props}
                      handleFormChange={(event) => handleFormChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <UserInformation
                      {...props}
                      handleFormChange={(event) => handleFormChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword
                      {...props}
                      handleFormChange={(event) => handleFormChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} className="text-right">
                    {(() => {
                      if (isSubmitting) {
                        return (
                          <Button type="button" variant="contained" color="primary">
                            Saving. . .
                          </Button>
                        );
                      }
                      return (
                        <Button type="submit" variant="contained" color="primary">
                          Save Changes
                        </Button>
                      );
                    })()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

const AvatarEditor = ({ onChangeAvatar, ...props }) => {
  const classes = useStyles();
  const [avatar, setAvatar] = useState(null);

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // props.saveAvatar(event.target.files[0]);
      setAvatar(file);
      onChangeAvatar(file);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          Avatar
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={6}>
            {(() => {
              if (avatar) {
                const imageUrl = URL.createObjectURL(avatar);
                return <Avatar alt="avatar" src={imageUrl} className={classes.avatarImg} />;
              }

              if (props.profile.avatar) {
                return (
                  <Avatar
                    alt="avatar"
                    src={`${CONFIG.API_STORAGE_URL}${props.profile.avatar}`}
                    className={classes.avatarImg}
                  />
                );
              }

              return (
                <Avatar className={classes.avatar}>{props.profile.firstname.charAt(0)}</Avatar>
              );
            })()}
          </Grid>
          <Grid item xs={6}>
            <input
              accept="image/*"
              className="d-none"
              id="outlined-button-file"
              type="file"
              onChange={(event) => handleFileUpload(event)}
            />
            <label htmlFor="outlined-button-file" className="mr-1">
              <Button
                className="ml-3"
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<PhotoCamera />}
              >
                Upload
              </Button>
            </label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const UserInformation = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          User Information
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        {/*<InputLabel shrink>Email</InputLabel>*/}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          sx={{ paddingBottom: '15px' }}
          name="email"
          label="Email"
          placeholder="Email"
          size="small"
          value={props.profile.email}
          disabled
        />
        {/*<InputLabel shrink>First Name</InputLabel>*/}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          sx={{ paddingBottom: '15px' }}
          name="firstname"
          label="First Name"
          placeholder="First Name"
          size="small"
          value={props.profile.firstname}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'firstname')}
        />
        {/*<InputLabel shrink>Last Name</InputLabel>*/}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          sx={{ paddingBottom: '15px' }}
          name="lastname"
          label="Last Name"
          placeholder="Last Name"
          size="small"
          value={props.profile.lastname}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'lastname')}
        />
      </Grid>
    </Grid>
  );
};

const VenueInformation = (props) => {
  const classes = useStyles();

  if (props.profile.role !== 'venue') {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          Venue Information
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        {/*<InputLabel shrink>Name</InputLabel>*/}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          sx={{ paddingBottom: '15px' }}
          size="small"
          label="Name"
          placeholder="Name"
          name="name"
          value={props.profile.name}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'name')}
        />
        {/*<InputLabel shrink>Address</InputLabel>*/}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          sx={{ paddingBottom: '15px' }}
          size="small"
          label="Address"
          placeholder="Address"
          name="address"
          value={props.profile.address}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'address')}
        />
      </Grid>
    </Grid>
  );
};

const ChangePassword = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          Change your password
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        {!props.error ? null : (
          <Typography variant="body2" component="div" className={classes.ErrorText}>
            <ErrorOutlineRoundedIcon color="secondary" className={classes.ErrorIcon} />
            Please make sure your password match.
          </Typography>
        )}

        {/*<InputLabel shrink>New Password</InputLabel>*/}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          type="password"
          className={classes.textfield}
          sx={{ paddingBottom: '15px' }}
          name="password"
          label="New Password"
          placeholder="New Password"
          size="small"
          value={props.profile.password}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
        />
        {/*<InputLabel shrink>Re-enter Your New Password</InputLabel>*/}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          type="password"
          className={classes.textfield}
          sx={{ paddingBottom: '15px' }}
          name="password_confirmation"
          label="Re-enter Your New Password"
          placeholder="Re-enter Your New Password"
          size="small"
          value={props.profile.password_confirmation}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  profile: state.Setting.profile,
  errors: state.Setting.errors,
});

const mapDispatchToProps = {
  loadProfile: () => loadProfile(),
  saveProfile: () => saveProfile(),
  clearErrors: () => clearErrors(),
  saveAvatar: (payload) => saveAvatar(payload),
  typingProfile: (payload) => typingProfile(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
