import React from 'react';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

// const Alert = (props) => {
//   //   return <MuiAlert elevation={6} variant="filled" {...props} />;
//   return <MuiAlert severity="success"> Testing </MuiAlert>;
// };

const SnackbarSuccess = (props) => {
  const { message, handleClose, severity } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={true}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity ? severity : 'success'}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarSuccess;
