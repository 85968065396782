import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { WarningRounded, MoreHorizOutlined } from '@mui/icons-material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
// import ConfirmModal from '../components/ConfirmModal';

const useStyles = makeStyles((theme) => ({
  action: {
    cursor: 'pointer',
    height: '30px',
    paddingTop: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  ColumnTitle: {
    fontWeight: 600,
    margin: '15px',
  },
  DialogTitle: {
    textAlign: 'center',
  },
  DialogFooter: {
    margin: 'auto',
  },
  WarningIcon: {
    fontSize: '2.5em !important',
    color: '#DC004E',
  },
}));

const DealBoardColumnHeader = (props) => {
  const classes = useStyles();
  const [deleteId, setDeleteId] = React.useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);

  // console.log(props.board);
  const confirmDelete = (popupState, id) => {
    popupState.setOpen(false);

    let lane = props.board.columns.filter((item) => item.id === id)[0];

    if (lane.cards.length > 0) {
      // console.log(lane.cards);
      toggleInfoModal();
    } else {
      setDeleteId(id);
      toggleConfirmModal();
    }
  };

  const handleDelete = () => {
    toggleConfirmModal();
    props.removeBoardColumn(deleteId);
  };

  const handleEdit = (popupState, id) => {
    popupState.setOpen(false);
    props.openBoardEditorModal(id);
  };

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const toggleInfoModal = () => {
    setInfoModalOpen(!infoModalOpen);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {/*<ConfirmModal
          title=""
          color="action"
          open={confirmModalOpen}
          toggleModal={() => toggleConfirmModal()}
          handleYes={() => handleDelete()}
          DialogTitle="Are you sure?"
          ContentText="Are you sure you want to delete this Column? This process cannot be undone."
        />*/}
        <Dialog
          open={confirmModalOpen}
          onClose={() => toggleConfirmModal()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.DialogTitle}>
            <HelpIcon className={classes.WarningIcon} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="text-center">
              <Typography variant="h6" component="h6">
                Are you sure?
              </Typography>
              <Typography variant="body1" component="div">
                Are you sure you want to delete this Column? This process cannot be undone.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleConfirmModal} color="error">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained" color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={infoModalOpen}
          onClose={toggleInfoModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.DialogTitle}>
            <WarningRounded className={classes.WarningIcon} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="text-center">
              <Typography variant="h6" component="h6">
                Unable to remove column.
              </Typography>
              <Typography variant="body1" component="div">
                Please move deals from this column to another before removing.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleInfoModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1" component="div" className={classes.ColumnTitle}>
          {props.data.title}
        </Typography>
      </Grid>
      <Grid item xs={2} className="text-right">
        <PopupState variant="popover">
          {(popupState) => (
            <div>
              <IconButton className="mt-1" aria-haspopup="true" {...bindTrigger(popupState)}>
                <MoreHorizOutlined />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <Box className="p-1" style={{ width: '150px' }}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    className={classes.action}
                    onClick={() => handleEdit(popupState, props.data.id)}
                  >
                    Edit Column Name
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    className={classes.action}
                    onClick={() => confirmDelete(popupState, props.data.id)}
                  >
                    Remove Column
                  </Typography>
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
      </Grid>
    </Grid>
  );
};

export default DealBoardColumnHeader;
