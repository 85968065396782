import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  loadAll as loadStages,
  clearForm as clearFormStage,
  load as loadStage,
  remove as removeStage,
  save as saveStage,
} from '../actions/stage';
import {
  clearForm,
  load,
  loadAll,
  save,
  remove,
  updateBoardStage,
  swapBoardColumn,
  changeView,
} from '../actions/deal';
import { loadAll as loadContacts } from '../actions/contact';
import { makeStyles } from '@mui/styles';
import { Grid, Button, ButtonGroup } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  AddCircle as AddCircleIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from '@mui/icons-material';
// import Board from '@lourenci/react-kanban';
import PageHeader from '../components/PageHeader.js';
import { SuccessSnackbar2 } from '../components/Snackbars.js';
import DealEditDialog from './DealEditDialog';
import DealList from './DealList';
import BoardColumnEditDialog from './BoardColumnEditDialog';
import ConfirmModal from '../components/ConfirmModal';
import DealBoardCard from './DealBoardCard';
import DealBoardColumnHeader from './DealBoardColumnHeader';
import Board from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import { load as load_form_deal } from '../actions/deal_builder';
import { load as load_form_contact } from '../actions/contact_builder';

const useStyles = makeStyles((theme) => ({
  defaultBtn: {
    color: '#6e768e',
    border: '1px solid #6e768e',
    backgroundColor: '#f9f5f5',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#f0eefa',
    },
  },
  addDealBtn: {
    color: '#ffffff',
    border: '1px solid #82c34c',
    backgroundColor: 'rgb(109,180,78)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgb(109,180,78, 0.7)',
      boxShadow: 'none',
    },
  },
  ButtonGroup: {
    '& button': {
      padding: '4px 15px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'inherit',
      },
      '& button:last-child': {
        marginRight: '4px',
      },
    },
  },
  activeBtn: {
    color: '#fff',
    fontWeight: '700',
    border: '1px solid #0eb0c4',
    backgroundColor: '#0eb0c4',
    '&:hover': {
      backgroundColor: 'rgb(14, 176, 196, 0.7)',
    },
  },
  inactiveListBtn: {
    color: '#0eb0c4',
    border: '1px solid #0eb0c4',
    backgroundColor: '#f9f5f5',
    borderLeft: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#f0eefa',
    },
  },
  inactiveBoardBtn: {
    color: '#0eb0c4',
    border: '1px solid #0eb0c4',
    backgroundColor: '#f9f5f5',
    borderRight: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#f0eefa',
    },
  },
  HeaderBtns: {
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      '& button': {
        display: 'block !important',
        marginBottom: '3px',
      },
    },
  },
}));

const DealBoard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    // props.clearForm();
    // props.loadAll();
    // props.loadStages();
    // props.loadContacts();
    // props.load_form({ user_id: props.user.id });
    // console.log(props.board);

    props.clearForm();
    async function fetchData() {
      await props.loadAll();
      await props.loadStages();
      await props.load_form_deal({ user_id: props.user.id });
      await props.loadContacts();
      await props.load_form_contact({ user_id: props.user.id });
    }
    fetchData();
  }, []);

  const onCardDragEnd = (board, source, destination) => {
    props.updateBoardStage({ source: source, destination: destination });
  };

  const onLaneDragEnd = (board, source, destination) => {
    // console.log(board);
    // console.log(source);
    // console.log(destination);
    props.swapBoardColumn({
      from: source.fromPosition,
      to: destination.toPosition,
    });
  };

  const removeBoardColumn = async (stage_id) => {
    let success = await props.removeStage({ id: stage_id });
    if (success) {
      props.loadAll();
      setSnackbarMessage('Column successfully deleted.');
      openSnackbar();
    }
  };

  const saveBoardColumn = async () => {
    let success = await props.saveStage();
    if (success) {
      props.loadAll();
      closeBoardEditorModal();
      props.clearFormStage();
      setSnackbarMessage('Column successfully saved.');
      openSnackbar();
    }
  };

  const [BoardEditorOpen, setBoardEditorOpen] = React.useState(false);
  const [boardEditAction, setBoardEditAction] = React.useState('create');
  const openBoardEditorModal = async (stage_id) => {
    props.clearFormStage();

    if (stage_id) {
      await props.loadStage({ id: stage_id });
      setBoardEditAction('update');
    } else {
      setBoardEditAction('create');
    }

    setBoardEditorOpen(true);
  };
  const closeBoardEditorModal = () => {
    setBoardEditorOpen(false);
    props.clearFormStage();
  };

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [EditorOpen, setEditorOpen] = React.useState(false);
  const openEditorModal = async (deal_id) => {
    setIsLoading(true);
    if (deal_id) {
      await props.load({ id: deal_id });
    }
    setIsLoading(false);
    setEditorOpen(true);
  };
  const closeEditorModal = () => {
    setEditorOpen(false);
    props.loadAll();
    props.clearForm();
  };

  const [deleteId, setDeleteId] = React.useState(null);
  const confirmDelete = (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const handleSave = async (formFields) => {
    let success = await props.save(formFields);
    if (success) {
      closeEditorModal();
      setSnackbarMessage('Deal successfully saved.');
      openSnackbar();
    }
  };

  const handleDelete = async () => {
    let success = await props.remove({ id: deleteId });
    if (success) {
      setSnackbarMessage('Deal successfully deleted.');
      toggleConfirmModal();
      openSnackbar();
    }
  };
  // console.log('props.board', props.board)
  return (
    <div className="board-container">
      <Backdrop
        open={isLoading}
        sx={{ zIndex: 9999, color: (theme) => theme.palette.common.white }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {snackbarOpen && (
        <SuccessSnackbar2
          open={snackbarOpen}
          openSnackbar={() => openSnackbar()}
          closeSnackbar={() => closeSnackbar()}
          message={snackbarMessage}
        />
      )}
      {BoardEditorOpen && (
        <BoardColumnEditDialog
          open={BoardEditorOpen}
          action={boardEditAction}
          openEditorModal={() => openBoardEditorModal()}
          closeEditorModal={() => closeBoardEditorModal()}
          saveBoardColumn={() => saveBoardColumn()}
          {...props}
        />
      )}
      <DealEditDialog
        open={EditorOpen}
        openEditorModal={() => openEditorModal()}
        closeEditorModal={() => closeEditorModal()}
        handleSave={(formFields) => handleSave(formFields)}
        {...props}
      />
      {confirmModalOpen && (
        <ConfirmModal
          title=""
          color="action"
          open={confirmModalOpen}
          toggleModal={() => toggleConfirmModal()}
          handleYes={() => handleDelete()}
          DialogTitle="Are you sure?"
          ContentText="Are you sure you want to delete this Deal? This process cannot be undone."
        />
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} className="mt-4 px-3">
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <PageHeader headerTitle="Deals" icon={<LocalOfferOutlinedIcon />} />
            </Grid>
            <Grid item sm={9} xs={12} className={`text-right ${classes.HeaderBtns}`}>
              {props.view === 'board' && (
                <Button
                  size="small"
                  variant="contained"
                  className={`${classes.defaultBtn} ml-1`}
                  onClick={() => openBoardEditorModal(null)}
                >
                  <AddCircleIcon style={{ fontSize: 15, marginRight: 4 }} />
                  Add Column
                </Button>
              )}
              <Button
                size="small"
                className={`${classes.addDealBtn} ml-1`}
                variant="contained"
                onClick={() => openEditorModal(null)}
              >
                <AddCircleIcon style={{ fontSize: 15, marginRight: 4 }} />
                Add Deal
              </Button>
              <ButtonGroup size="small" className={`${classes.ButtonGroup} ml-1`}>
                <Button
                  onClick={() => props.changeView('board')}
                  variant={props.view === 'board' ? 'contained' : 'outlined'}
                >
                  Board
                </Button>
                <Button
                  variant={props.view === 'list' ? 'contained' : 'outlined'}
                  onClick={() => props.changeView('list')}
                >
                  List
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="px-3">
          {props.view === 'list' && (
            <DealList
              openEditorModal={(id) => openEditorModal(id)}
              removeBoardColumn={(id) => removeBoardColumn(id)}
              confirmDelete={(id) => confirmDelete(id)}
            />
          )}
          {props.view === 'board' && (
            <Board
              height="auto"
              onCardDragEnd={onCardDragEnd}
              onColumnDragEnd={onLaneDragEnd}
              // initialBoard={props.board}
              renderColumnHeader={(data) => (
                <DealBoardColumnHeader
                  {...props}
                  data={data}
                  openBoardEditorModal={(id) => openBoardEditorModal(id)}
                  removeBoardColumn={(id) => removeBoardColumn(id)}
                />
              )}
              renderCard={(data, { removeCard, dragging }) => (
                <DealBoardCard
                  data={data}
                  removeCard={removeCard}
                  dragging={dragging}
                  handleDelete={(id) => confirmDelete(id)}
                  key={(id) => id}
                  openEditorModal={(id) => openEditorModal(id)}
                />
              )}
            >
              {props.board}
            </Board>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  view: state.Deal.view,
  success: state.Deal.success,
  stageSuccess: state.Stage.success,
  board: state.Deal.board,
  contacts: state.Contact.contacts,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  loadStage: (payload) => loadStage(payload),
  loadStages: () => loadStages(),
  clearFormStage: () => clearFormStage(),
  removeStage: (payload) => removeStage(payload),
  loadContacts: () => loadContacts(),
  loadAll: () => loadAll(),
  load: (payload) => load(payload),
  clearForm: () => clearForm(),
  remove: (payload) => remove(payload),
  updateBoardStage: (payload) => updateBoardStage(payload),
  swapBoardColumn: (payload) => swapBoardColumn(payload),
  changeView: (payload) => changeView(payload),
  save: (payload) => save(payload),
  saveStage: () => saveStage(),
  load_form_deal: (payload) => load_form_deal(payload),
  load_form_contact: (payload) => load_form_contact(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(DealBoard);
