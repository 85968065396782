import {VENUE} from '../constants/venue';

export function clearForm() {
  return {type: VENUE.VENUE_CLEAR_FORM}
}

export function remove(payload) {
	return async function(dispatch) {
		try {
			let response = await global.axios({
				method: "delete",
				url: `/venue/${payload.id}`,
			});
			dispatch({type: VENUE.VENUE_DELETE, payload: response.data});
			return true;
		}
		catch(error) {
			console.log(error.response);
			return false;
		}
  }
}

export function load(payload) {
  return function(dispatch) {
		let venue = Object.keys(payload.id).map(function(key){return payload.id[key]})
		if (venue) {
			if(Number.isInteger(parseInt(venue[0]))) {
				global.axios({
					method: "get",
					url: `/venue/${venue[0]}`,
				})
					.then(({ data }) => {
						dispatch({ type: VENUE.VENUE_LOAD, payload:data });
					})
					.catch(function(error) {
						console.log(error.response)
					});
			}
		}
  }
}

export function loadAll() {
  return async function(dispatch) {
		try {
			let response = await global.axios({
				method: "get",
				url: "/venue"
			});
			await dispatch({ type: VENUE.VENUE_LOAD_ALL, payload: response.data });
			return response.data;
		}
		catch(error) {
			console.log(error.response);
			return false;
		}
  }
}

export function save() {
	return async function(dispatch, getState) {

    dispatch({type: VENUE.VENUE_SAVE_REQUEST});

		let postData = getState().Venue.venue;
    let method = "post";
		let url = "/venue";

    if(postData.id) {
      method = "put";
      url += `/${postData.id}`;
		}
		try {
			let response = await global.axios({
				method: method,
				url: url,
				data: postData
			});
			dispatch({type: VENUE.VENUE_SAVE_SUCCESS, payload: response.data});
			return response.data;
		}
		catch(error) {
			if(error.response.status === 422) {
				dispatch({ type:  VENUE.VENUE_SAVE_ERROR, payload: error.response.data.errors });
			} else {
				console.log(error);
			}
			return false;
		}
  };
}

export function typing(payload) {
  return {type: VENUE.VENUE_TYPING, payload: payload}
}
