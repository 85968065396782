import React from 'react';
import CONFIG from '../../config.js';
import { ToDateTimeHMDMY } from '../../helpers/DateFunctions.js';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  // Menu,
  // MenuItem,
  Popover,
  Box,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { makeStyles } from '@mui/styles';

import FileModal from '../FileModal';

import CommentCardMachine from './CommentCard.machine';
import { useMachine } from '@xstate/react';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: '3px 12px 2px 16px',
  },
  action: {
    cursor: 'pointer',
    height: '30px',
    paddingTop: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  fileContainer: {
    width: '100px',
    height: '65px',
    marginRight: '5px',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 hsla(0, 0%, 0%, 0.1)',
    display: 'inline-block',
  },
  imgThumbnail: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
  },
  docThumbnail: {
    width: '50px',
    height: '50px',
  },
}));

const CommentCard = (props) => {
  const classes = useStyles();
  const State = useMachine(CommentCardMachine);
  // console.log(State)

  const handleDelete = (popupState, id) => {
    popupState.setOpen(false);
    props.handleDelete(id);
  };

  const handleEdit = (popupState, id) => {
    popupState.setOpen(false);
    props.handleEdit(id);
  };

  return (
    <Card className={props.className}>
      <FileModal
        open={State[0].matches('fileModal.open')}
        url={State[0].context.fileUrl}
        onClose={() => State[1]('FILEMODAL_TOGGLE')}
      />
      <CardHeader
        className={classes.cardHeader}
        action={
          <PopupState variant="popover">
            {(popupState) => (
              <div>
                <IconButton
                  className="mt-2"
                  aria-label="more"
                  aria-haspopup="true"
                  {...bindTrigger(popupState)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box className="p-1" style={{ width: '130px' }}>
                    <Typography
                      variant="subtitle2"
                      className={classes.action}
                      color="textSecondary"
                      onClick={() => handleEdit(popupState, props.data.id)}
                    >
                      <EditOutlinedIcon className="mr-2" style={{ fontSize: 12 }} />
                      Edit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.action}
                      color="textSecondary"
                      onClick={() => handleDelete(popupState, props.data.id)}
                    >
                      <DeleteOutlineOutlinedIcon className="mr-2" style={{ fontSize: 12 }} />
                      Delete
                    </Typography>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        }
        subheader={<small>{ToDateTimeHMDMY(props.data.updated_at)}</small>}
      />
      <CardContent className="pt-0 pb-0">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="body2" component="span">
              <span dangerouslySetInnerHTML={{ __html: props.data.comment }}></span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FileList
              files={props.data.files}
              onPreview={(file) => State[1]({ type: 'FILEMODAL_TOGGLE', data: file })}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const FileList = (props) => {
  const classes = useStyles();

  if (!props.files) {
    return null;
  }

  return (
    <div className="pb-2">
      {props.files &&
        props.files.map((row, index) => {
          // let arr = row.file.split('.');
          // let fileType = arr[arr.length - 1];

          let file_thumbnail = (
            <img className={classes.docThumbnail} src="/images/pdf.png" alt="" />
          );
          // /^.jpg|.jpeg|.png/.test(fileType) !== -1 ? (
          //   <img
          //     className={classes.imgThumbnail}
          //     src={`${CONFIG.API_STORAGE_URL}${row.file}`}
          //     alt=""
          //   />
          // ) : ['doc', 'docx'].indexOf(fileType) !== -1 ? (
          //   <img className={classes.docThumbnail} src="/images/doc.png" alt="" />
          // ) : (
          //   <img className={classes.docThumbnail} src="/images/pdf.png" alt="" />
          // );
          if (/.jpg|.jpeg|.png/.test(row.file)) {
            file_thumbnail = (
              <img
                className={classes.imgThumbnail}
                src={`${CONFIG.API_STORAGE_URL}${row.file}`}
                alt=""
              />
            );
          } else if (/.doc|.docx/.test(row.file)) {
            file_thumbnail = <img className={classes.docThumbnail} src="/images/doc.png" alt="" />;
          }
          // return (
          // 	<a key={index} href={`${CONFIG.API_STORAGE_URL}${row.file}`} target="_blank" rel="noopener noreferrer">
          // 		<div className={`text-center ${classes.fileContainer}`}>
          // 			{file_thumbnail}
          // 		</div>
          // 	</a>
          // )
          return (
            <div
              key={'file-' + index}
              className={`text-center ${classes.fileContainer}`}
              style={{ cursor: 'pointer' }}
              onClick={() => props.onPreview(row.file)}
            >
              {file_thumbnail}
            </div>
          );
        })}
    </div>
  );
};

export default CommentCard;
