import React from 'react';
import { connect } from 'react-redux';
import { load, save, remove, clearForm } from '../actions/contact_comment';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CommentCard from '../components/deal_comment/CommentCard';
import ContactCommentNew from './ContactCommentNew';
import ContactCommentUpdate from './ContactCommentUpdate';
import ConfirmModal from '../components/ConfirmModal';
import { SuccessSnackbar2 } from '../components/Snackbars.js';

const useStyles = makeStyles(theme => ({
  cardBorder: {
		border: '1px solid #e6e6e6',
		boxShadow:' none !important',
	},
}));

const ContactComments = (props) => {

	const classes = useStyles();

	const [EditorOpen, setEditorOpen] = React.useState(false);
	const openEditorModal = (comment_id) => {
		props.clearForm();
		props.load({id: comment_id});
		setEditorOpen(true);
	}
	const closeEditorModal = () => {
		setEditorOpen(false);
		if(props.success) {
			setTimeout( function() {
				setSnackbarMessage("Comment successfully saved.");
				openSnackbar();
			}, 500);
		}
		props.clearForm();
	}

	const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
	const toggleConfirmModal = () => {
		setConfirmModalOpen(!confirmModalOpen);
	}

	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const openSnackbar = () => {
    setSnackbarOpen(true);
	}
	const closeSnackbar = () => {
    setSnackbarOpen(false);
	}

	const [deleteId, setDeleteId] = React.useState(null);
	const handleConfirmDelete = (id) => {
		setDeleteId(id);
		toggleConfirmModal();
	}

	const handleDelete = () => {
		props.remove({ id: deleteId });
		toggleConfirmModal();
		setTimeout( function() {
			setSnackbarMessage("Comment successfully deleted.");
			openSnackbar();
		}, 1000);
	}

	return(
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<SuccessSnackbar2
					open={snackbarOpen}
					openSnackbar={() => openSnackbar()}
					closeSnackbar={() => closeSnackbar()}
					message={snackbarMessage}
				/>
				<ConfirmModal
					title="delete"
					color="action"
					open={confirmModalOpen}
					toggleModal={() => toggleConfirmModal()}
					handleYes={() => handleDelete()}
					DialogTitle="Are you sure?"
					ContentText="Are you sure you want to delete this comment? This process cannot be undone."
				/>
				<ContactCommentUpdate
					open={ EditorOpen }
					openEditorModal={ () => openEditorModal() }
					closeEditorModal={ () => closeEditorModal() }
					{...props}
				/>
			</Grid>
			<Grid item xs={12}>
				<ContactCommentNew />
			</Grid>
			<Grid item xs={12}>
				{
					props.comments
					&& props.comments.map((row, index) => {
						return (
							<CommentCard key={index} data={row} className={`${classes.cardBorder} mt-3`} handleEdit={(id) => openEditorModal(id)} handleDelete={(id) => handleConfirmDelete(id)} {...props}
							/>
						);
					})
				}
			</Grid>
		</Grid>
	)
}


const mapStateToProps = state => ({
	success: state.ContactComment.success,
	comments: state.ContactComment.comments,
});

const mapDispatchToProps = {
	clearForm: () => clearForm(),
	load: (payload) => load(payload),
	save: (payload) => save(payload),
	remove: (payload) => remove(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactComments);
