import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { remove, loadAll } from '../actions/contact';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Button } from '@mui/material';
import { AddCircle, PeopleOutlineRounded } from '@mui/icons-material';
import PageHeader from '../components/PageHeader.js';
import { SuccessSnackbar2 } from '../components/Snackbars.js';
import ConfirmModal from '../components/ConfirmModal';
import TableComponent from '../components/table/TableComponent';
import { load, clearForm } from '../actions/contact_builder';
import ContactEdit from './ContactEdit';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    color: '#ffffff',
    border: '1px solid #82c34c',
    marginTop: '-4px',
    backgroundColor: 'rgb(109,180,78)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgb(109,180,78, 0.7)',
      boxShadow: 'none',
    },
  },
}));

const headCells = [
  { id: 'venue_name', label: 'Venue', restrict: 'god_admin' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'phone', label: 'Phone' },
  { id: 'created_at', label: 'Created at', align: 'center', type: 'date' },
  {
    id: '',
    label: 'Action',
    align: 'right',
    sortable: false,
    searchable: false,
    width: '100px',
  },
];

const Contacts = (props) => {
  const { user, contacts, loadAll, remove, load } = props;
  const classes = useStyles();

  useEffect(() => {
    load({ user_id: user.id });
    loadAll();
  }, []);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const toggleSnackbar = () => {
    setTimeout(function () {
      setSnackbarOpen(!snackbarOpen);
    }, 1000);
  };

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const [editorOpen, setEditorOpen] = useState(false);

  const openEditorModal = () => {
    setEditorOpen(true);
  };

  const saveEditorModal = () => {
    setEditorOpen(false);
    loadAll();
  };

  const closeEditorModal = () => {
    setEditorOpen(false);
  };

  const confirmDelete = (contact) => {
    setDeleteId(contact.id);
    toggleConfirmModal();
  };

  const handleDelete = async () => {
    setDeleting(true);
    let success = await remove({ id: deleteId });
    if (success) {
      setDeleting(false);
      toggleConfirmModal();
      toggleSnackbar();
    }
  };

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpenNew, setSnackbarOpenNew] = useState(false);
  const openSnackbar = () => {
    setSnackbarOpenNew(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpenNew(false);
  };

  const handleSaveSuccess = () => {
    setSnackbarMessage('Contact successfully saved.');
    openSnackbar();
  };

  const handleView = (contact) => {
    props.navigate(`/contacts/view/${contact.id}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PageHeader icon={<PeopleOutlineRounded />} headerTitle="Contacts" />
          </Grid>
          <Grid item xs={6} className="text-right">
            {props.user.role === 'venue' && (
              <Button
                size="small"
                className={`${classes.addBtn}`}
                variant="contained"
                onClick={() => openEditorModal()}
                disabled={props.fetchingData}
              >
                <AddCircle style={{ fontSize: 15, marginRight: 4 }} />
                Create New
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          {snackbarOpenNew && (
            <SuccessSnackbar2
              open={snackbarOpenNew}
              openSnackbar={() => openSnackbar()}
              closeSnackbar={() => closeSnackbar()}
              message={snackbarMessage}
            />
          )}
          {confirmModalOpen && (
            <ConfirmModal
              loading={deleting}
              title=""
              color="action"
              open={confirmModalOpen}
              toggleModal={() => toggleConfirmModal()}
              handleYes={() => handleDelete()}
              DialogTitle="Are you sure?"
              ContentText="Are you sure you want to delete this contact? This process cannot be undone."
            />
          )}
          {editorOpen && (
            <ContactEdit
              open={editorOpen}
              saveEditorModal={() => saveEditorModal()}
              closeEditorModal={() => closeEditorModal()}
              handleSaveSuccess={() => handleSaveSuccess()}
            />
          )}
          <TableComponent
            data={contacts}
            headCells={headCells}
            handleView={(contact) => handleView(contact)}
            handleDelete={user.role === 'venue' ? (contact) => confirmDelete(contact) : null}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  contacts: state.Contact.contacts,
  fetchingData: state.Contact.fetchingData,
});

const mapDispatchToProps = {
  loadAll: () => loadAll(),
  clearForm: () => clearForm(),
  remove: (payload) => remove(payload),
  load: (payload) => load(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Contacts));
