import {CONTACT_BUILDER} from '../constants/contact_builder';

export function clearForm() {
  return {type: CONTACT_BUILDER.CONTACT_BUILDER_CLEAR_FORM}
}

export function load(payload) {
  return function(dispatch) {

		dispatch({type: CONTACT_BUILDER.CONTACT_BUILDER_LOAD_REQUEST});

		global.axios({
			method: "get",
			url: `/contact_other_info/${payload.user_id}`,
		})
			.then(({ data }) => {
				if(Object.keys(data).length > 0){
					dispatch({ type: CONTACT_BUILDER.CONTACT_BUILDER_LOAD, payload:data });
				}
			})
			.catch(function(error) {
				console.log(error.response)
			});
  }
}

export function save(postData) {
	return async function(dispatch) {

	dispatch({type: CONTACT_BUILDER.CONTACT_BUILDER_SAVE_REQUEST});
	
    let method = "post";
		let url = "/contact_other_info";

    if(postData.id) {
      method = "put";
      url += `/${postData.id}`;
		}
		try {
			let response = await global.axios({
				method: method,
				url: url,
				data: postData
			});
			dispatch({type: CONTACT_BUILDER.CONTACT_BUILDER_SAVE_SUCCESS, payload: response.data});
			return response.data;
		}
		catch(error) {
			if(error.response.status === 422) {
				dispatch({ type:  CONTACT_BUILDER.CONTACT_BUILDER_SAVE_ERROR, payload: error.response.data.errors });
			} else {
				console.log(error);
			}
			return false;
		}
  };
}

export function typing(payload) {
  return {type: CONTACT_BUILDER.VENUE_TYPING, payload: payload}
}
