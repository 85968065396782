import { TrendingUpRounded } from "@mui/icons-material";
import { CONTACT_BUILDER } from "../constants/contact_builder";

const initialState = {
	errors: {},
	isProcessing: false,
	formLoading: false,
	success: false,
	contact_form: {
		contacts_other_info_details: JSON.stringify([
			{
				title: "First Name",
				type: "Short Answer",
				placeholder: "",
				options: [],
				required: true,
				disabled: true,
			},
			{
				title: "Last Name",
				type: "Short Answer",
				placeholder: "",
				options: [],
				required: true,
				disabled: true,
			},
			{
				title: "Email",
				type: "Short Answer",
				placeholder: "",
				options: [],
				required: false,
				disabled: true,
			},
			{
				title: "Phone",
				type: "Short Answer",
				placeholder: "",
				options: [],
				required: false,
				disabled: true,
			},
			{
				title: "Address",
				type: "Paragraph",
				placeholder: "",
				options: [],
				required: false,
				disabled: false,
			},
		]),
	},
};

const ContactBuilder = (state = initialState, action) => {
	let newState = JSON.parse(JSON.stringify(state));

	switch (action.type) {
		case CONTACT_BUILDER.CONTACT_BUILDER_CLEAR_FORM: {
			newState.errors = {};
			newState.isProcessing = false;

			newState.contact_form = {};

			return newState;
		}

		case CONTACT_BUILDER.CONTACT_BUILDER_LOAD_REQUEST: {
			newState.formLoading = true;
			newState.errors = {};
			newState.success = false;

			return newState;
		}

		case CONTACT_BUILDER.CONTACT_BUILDER_LOAD: {
			newState.formLoading = false;
			newState.errors = {};
			newState.success = true;
			
			newState.contact_form = action.payload;
			return newState;
		}

		case CONTACT_BUILDER.CONTACT_BUILDER_SAVE_REQUEST: {
			newState.isProcessing = true;
			newState.errors = {};
			newState.success = false;

			return newState;
		}

		case CONTACT_BUILDER.CONTACT_BUILDER_SAVE_SUCCESS: {
			newState.errors = {};
			newState.isProcessing = false;
			newState.success = true;

			newState.contact_form = action.payload;

			return newState;
		}

		case CONTACT_BUILDER.CONTACT_BUILDER_SAVE_ERROR: {
			newState.isProcessing = false;
			newState.errors = action.payload;
			newState.success = false;

			return newState;
		}

		default:
			return state;
	}
};

export default ContactBuilder;
