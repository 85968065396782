import moment from 'moment'

let monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function DateToString(dateStr) {
	// from (YYYY-mm-dd) to (MonthName day, Year)

	let d = new Date(dateStr);
	let date = `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;

	return date;
}

export function ToDateTimeHMDMY(dateStr) {
	return moment(dateStr).format('hh:mma DD/MM/YYYY')
	// from (YYYY-mm-dd HH:mm:ss) to (HH:mm[am/pm] dd/mm/YYYY)

	// var d = new Date(dateStr);
	// var amOrPm = (d.getHours() < 12) ? "am" : "pm";
	// var hour = (d.getHours() < 13) ? d.getHours() : d.getHours() - 12;

	// let datetime = `${addLeadingZero(hour)}:${addLeadingZero(d.getMinutes())}${amOrPm}`
	// 								+ ` ${addLeadingZero(d.getDate())}/${addLeadingZero(d.getMonth())}/${d.getFullYear()} `;

	// return datetime;
}

export function TimestampToYYYYMMDD(timestamp) {
	// from timestamp to (YYYY-mm-dd)

	let d = new Date(timestamp);
	let date = `${d.getFullYear()}-${addLeadingZero(d.getMonth()+1)}-${addLeadingZero(d.getDate())}`;

	return date;
}

export function formatDateTime(datetime, separator='/', format="MDY") {

	let d = new Date(datetime);
	let year = d.getFullYear();
	let month = addLeadingZero(d.getMonth()+1);
	let day = addLeadingZero(d.getDate());

	let date = "";

	switch(format) {
		case 'MDY' : {
			date = `${month}${separator}${day}${separator}${year}`;
			break;
		}
		case 'YMD' : {
			date = `${year}${separator}${month}${separator}${day}`;
			break;
		}
	}

	return date;
}

export function isDate(val) {
	var d = new Date(val);
	return !isNaN(d.valueOf());
}

function addLeadingZero(num) {
	return ( (num.toString().length == 1) ? '0' : '' ) + num.toString();
}




