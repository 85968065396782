import {DEAL_COMMENT} from '../constants/deal_comment';

const initialState = {
  error: '',
  success: false,
	isProcessing: false,
	comments: [],
	comment: {},
};

const DealComment = (state = initialState, action) => {

	let newState = JSON.parse(JSON.stringify(state));

  switch(action.type) {

		case DEAL_COMMENT.DEAL_COMMENT_CLEAR_FORM : {

      newState.error = false;
      newState.success = false;
			newState.isProcessing = false;
			newState.comment = {};

			return newState;
		}

		case DEAL_COMMENT.DEAL_COMMENT_CLEAR : {

      newState.error = false;
      newState.success = false;
			newState.isProcessing = false;
			newState.comment = {};
			newState.comments = [];

			return newState;
    }

    case DEAL_COMMENT.DEAL_COMMENT_DELETE : {
      newState.comments = action.payload;
			return newState;
		}

		case DEAL_COMMENT.DEAL_COMMENT_DELETE_FILE : {
			let files = newState.comment.files;
			newState.comment.files = files.filter(item => item.id !== action.payload.id);
			return newState;
		}

    case DEAL_COMMENT.DEAL_COMMENT_LOAD : {
			newState.comment = action.payload;
			return newState;
		}

		case DEAL_COMMENT.DEAL_COMMENT_LOAD_ALL : {

			let data = action.payload;

			for(let i in data) {

				var d = new Date(data[i].updated_at);
				var amOrPm = (d.getHours() < 12) ? "am" : "pm";
				var hour = (d.getHours() < 13) ? d.getHours() : d.getHours() - 12;
				let datetime = `${hour}:${d.getMinutes()}${amOrPm}`
											+ ` ${d.getDate()}/${d.getMonth()}/${d.getFullYear()} `;
				data[i]['datetime_updated'] = datetime;
			}

			newState.comments = data;

      return newState;
		}

		case DEAL_COMMENT.DEAL_COMMENT_SAVE_SUCCESS : {

			newState.error = '';
			newState.isProcessing = false;
			newState.success = true;

			return newState;
    }

    default: return state;
  }

}

export default DealComment;
