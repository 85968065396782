export const CONTACT = {
    CONTACT_CLEAR_FORM: 'CONTACT_CLEAR_FORM',
    CONTACT_DELETE: 'CONTACT_DELETE',
    CONTACT_LOAD: 'CONTACT_LOAD',
    CONTACT_LOAD_ALL_REQUEST: 'CONTACT_LOAD_ALL_REQUEST',
    CONTACT_LOAD_ALL: 'CONTACT_LOAD_ALL',
    CONTACT_SAVE_REQUEST: 'CONTACT_SAVE_REQUEST',
    CONTACT_SAVE_SUCCESS: 'CONTACT_SAVE_SUCCESS',
    CONTACT_SAVE_ERROR: 'CONTACT_SAVE_ERROR',
		CONTACT_TYPING: 'CONTACT_TYPING',
  }
