export const AUTH = {
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
		TYPING: 'TYPING',
		LOGIN_UPDATE_PROFILE: 'LOGIN_UPDATE_PROFILE',
		LOGIN_UPDATE_AVATAR: 'LOGIN_UPDATE_AVATAR',
  }
