import React from 'react';
import {Grid, Typography, Paper} from '@mui/material';
import {EqualizerRounded as EqualizerRoundedIcon, GetApp} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import CountUp from 'react-countup';

const useStyles = makeStyles(theme => ({
	paper2: {
		padding: '15px',
		border: '1px solid #e2e0e0',
	},
	label: {
		color: '#8c8c8c',
		marginBottom: 0,
	},
	statFigures: {
		fontWeight: 700,
	},
	statIcon: {
		marginTop: '-6px',
		marginRight: '4px',
	},
	keyStatTitle: {
		fontWeight: 600,
	},
}));

const ReportKeyStats = (props) => {
	const classes = useStyles();
	return (
		<Grid container spacing={2}>
			<Grid item lg={4} md={6} sm={12} xs={12}>
				<Paper className={classes.paper2}>
					<Grid container spacing={2} id="key_stats_new">
						<Grid item xs={12}>
							<Typography variant="body1" component="span" className={classes.keyStatTitle}>
								<EqualizerRoundedIcon className={classes.statIcon} style={{color:'#5ecbe4'}}/>
								New Deals
							</Typography>
						</Grid>
						<Grid item sm={5} xs={12} className="text-center">
							<Typography variant="h4" component="div" className={classes.statFigures}>
								<CountUp end={props.key_stats.number_of_new_deals} duration={4} />
							</Typography>
							<Typography variant="body2" component="div" className={classes.label}>
								Count
							</Typography>
						</Grid>
						<Grid item sm={7} xs={12} className="text-center">
							<Typography variant="h4" component="div" className={classes.statFigures}>
								$<CountUp end={props.key_stats.revenue_of_new_deals} decimals={2} separator=","  duration={4} />
							</Typography>
							<Typography variant="body2" component="div" className={classes.label}>
								Revenue
							</Typography>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item lg={4} md={6} sm={12} xs={12}>
				<Paper className={classes.paper2}>
					<Grid container spacing={2} id="key_stats_won">
						<Grid item xs={12}>
							<Typography variant="body1" component="span" className={classes.keyStatTitle}>
								<EqualizerRoundedIcon className={classes.statIcon}  style={{color:'#1abc9c'}}/>
								Deals Won
							</Typography>
						</Grid>
						<Grid item sm={5} xs={12} className="text-center">
							<Typography variant="h4" component="div" className={classes.statFigures}>
								<CountUp end={props.key_stats.number_of_deals_won} duration={4} />
							</Typography>
							<Typography variant="body2" component="div" className={classes.label}>
								Count
							</Typography>
						</Grid>
						<Grid item sm={7} xs={12} className="text-center">
							<Typography variant="h4" component="div" className={classes.statFigures}>
								$<CountUp end={props.key_stats.revenue_of_deals_won} decimals={2} separator="," duration={4} />
							</Typography>
							<Typography variant="body2" component="div" className={classes.label}>
								Revenue
							</Typography>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item lg={4} md={12} sm={12} xs={12}>
				<Paper className={classes.paper2}>
					<Grid container spacing={2} id="key_stats_aspend">
						<Grid item xs={12}>
							<Typography variant="body1" component="span" className={classes.keyStatTitle}>
								<EqualizerRoundedIcon className={classes.statIcon} style={{color:'#f7b84b'}} />
								Average Spend
							</Typography>
						</Grid>
						<Grid item xs={12} className="text-center">
							<Typography variant="h4" component="div" className={classes.statFigures}>
								$<CountUp end={props.key_stats.average_spend} duration={4} decimals={2} separator="," />
							</Typography>
							<Typography variant="body2" component="div" className={classes.label}>
								Amount
							</Typography>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	)
}

export default ReportKeyStats;
