import {makeStyles} from '@mui/styles';

const TableStyles = makeStyles(theme => ({
  table: {
		'& thead': {
			backgroundColor: '#ffffff',
			padding: '10px'
		},
		'& tbody > tr:hover': {
			backgroundColor: '#f0eefa !important',			
		},
		'& th:first-child': {
			borderTopLeftRadius: '4px',
		},
		'& th:last-child': {
			borderTopRightRadius: '4px',
		},
		'& th': {
			padding: '15px 10px',
			borderBottom: '1px solid #e9ecef',
		},
		'& td': {
			padding: '10px',
			borderBottom: '1px solid #e9ecef',
		}
	},
	rowOdd: {
		backgroundColor: '#ffffff',
	},
	rowEven: {
		backgroundColor: '#ffffff',
	},
	searchRow: {
		'& td': {
			padding: '8px',
			borderBottom: '1px solid #e9ecef',
			backgroundColor: '#ffffff',
		},
		'&:hover': {
		}
	},
	searchField: {
		'& input': {
			padding: '10px !important',
			paddingRight: '1px !important',
			fontSize: '14px',
		},
		'& button': {
			padding: '6px !important',
		},
		'& svg': {
			fontSize: '18px !important',
		}
	}
}));

export { TableStyles };
