import React from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// console.log(pdfjs.GlobalWorkerOptions.workerSrc)
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const FileModal = ({ open, url, onClose }) => {
  // const extenstions = ['jpeg', 'jpg', 'png'];
  const isImage = /.jpg|.jpeg|.png/.test(url);
  const isPdf = /.pdf/.test(url);
  const fileName = url.split('/').pop();
  // const extenstion = url.split('.').pop();
  // const hostProtocol = window.location.protocol

  // const [numPages, setNumPages] = useState(null)
  // const [pageNumber, setPageNumber] = useState(1)

  // const handleLoadSuccess = ({numPages}) => {
  //   setNumPages(numPages)
  // }
  return (
    <span>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={isPdf ? { width: '500px', height: '100vh' } : {}}>
          {(() => {
            if (isImage) {
              return <img src={url} alt={fileName} />;
            }
            if (isPdf) {
              return (
                <iframe
                  style={{ width: '100%', height: '100%' }}
                  src={url}
                  frameBorder="0"
                ></iframe>
              );
            }
            return <InsertDriveFileIcon />;
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};
FileModal.propTypes = {
  open: PropTypes.bool,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};
FileModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default FileModal;
