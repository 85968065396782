import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
		color: '#323a46',
		htmlFontSize: 12,
		fontSize: 10,
		fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
		].join(','),
	},
	palette: {
		type: 'light',
		action: {
			main: '#98A6AD',
		},
		error: {
			main: '#dc004e',
		}
  },
});


export { theme };
