import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams, NavLink, Outlet} from 'react-router-dom';
import {connect} from 'react-redux';
import {loadAll, loadUnassigned} from '../actions/deal';
import {makeStyles } from '@mui/styles';
import {Grid, Paper} from '@mui/material';
import Alert from '@mui/lab/Alert';
import {LocalOfferOutlined} from '@mui/icons-material';
import PageHeader from '../components/PageHeader.js';
import TableComponent from '../components/table/TableComponent';

const useStyles = makeStyles(theme => ({
	alert: {
		'& .MuiAlert-message': {
			flexDirection: 'unset',
		}
	}
}));

const headCells = [
	{ id: 'venue_name', label: 'Venue'},
	{ id: 'contact_name', label: 'Contact'},
	{ id: 'stage_name', label: 'Deal Stage', sortkey: 'stage_position' },
	{ id: 'title', label: 'Title'},
  { id: 'type', label: 'Event Type'},
	{ id: 'date', label: 'Event Date', align: 'center', type: 'date'},
	{ id: 'value', label: 'Price', align: 'right', type: 'number', width: 120},
	{ id: 'created_at', label: 'Date Created', align: 'center', type: 'date'},
  { id: '', label: 'Action', align: 'right', searchable: false, sortable:false},
];

const Deals = (props) => {

	const {deals, unassigned_deals, loadAll, loadUnassigned, history} = props;
	const classes = useStyles();

	useEffect(() => {
		async function loadData() {
			await loadAll();
			await loadUnassigned();
		}
		loadData();
	}, []);

	const handleView = (deal) => {
		props.navigate(`/deals/view/${deal.id}`);
	}

  return (
		<Grid container spacing={2} className="p-3 pt-4">
			<Grid item xs={12}>
				<PageHeader headerTitle="Deals" icon={<LocalOfferOutlined />} />
			</Grid>
			{(unassigned_deals.length > 0) && (
				<Grid item xs={12}>
					<Alert severity="info" className={classes.alert}>
						There
						{unassigned_deals.length > 1 ? ' are ' : ' is '}
						{unassigned_deals.length} unassigned deal/s. Click &nbsp;
						<NavLink to="/deals/unassigned"> here </NavLink>
						&nbsp; to view details.
					</Alert>
				</Grid>
			)}
			<Grid item xs={12}>
				<Paper>
					<TableComponent
						data={deals}
						headCells={headCells}
						handleView={(deal) => handleView(deal)}
					/>
				</Paper>
			</Grid>
			<Outlet />
		</Grid>
  );
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

const mapStateToProps = state => ({
	deals: state.Deal.deals,
	unassigned_deals: state.Deal.unassigned_deals,
});

const mapDispatchToProps = {
	loadAll: () => loadAll(),
	loadUnassigned: () => loadUnassigned(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Deals));
