import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import {loadAll, remove} from '../actions/venue';
import {makeStyles} from '@mui/styles';
import {Paper, Grid, Button} from '@mui/material';
import {AddCircle, LocationCityOutlined} from '@mui/icons-material';
import PageHeader from '../components/PageHeader.js';
import ConfirmModal from '../components/ConfirmModal';
import {SuccessSnackbar} from '../components/Snackbars.js';
import TableComponent from '../components/table/TableComponent';

const useStyles = makeStyles(theme => ({
	addBtn: {
		color: '#ffffff',
		border: '1px solid #82c34c',
		marginTop: '-4px',
		backgroundColor: 'rgb(109,180,78)',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: 'rgb(109,180,78, 0.7)',
			boxShadow: 'none',
		}
	},
}));

const headCells = [
  { id: 'name', label: 'Name'},
  { id: 'user_name', label: 'Contact Person'},
  { id: 'email', label: 'Email'},
	{ id: 'address', label: 'Address'},
	{ id: 'created_at', label: 'Date Created', align: 'center',  type: 'date'},
  { id: '', align: 'right', label: 'Action', sortable: false, searchable: false, width: 120},
];

const Venues = (props) => {

	const {venues, loadAll, remove} = props;
	const classes = useStyles();

  useEffect(() => {
    loadAll();
	}, []);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const toggleSnackbar = () => {
    setTimeout( function() {
      setSnackbarOpen(!snackbarOpen);
    }, 1000);
	}

	const [deleteId, setDeleteId] = React.useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
	const toggleConfirmModal = () => {
		setConfirmModalOpen(!confirmModalOpen);
	}

	const confirmDelete = (venue) => {
		setDeleteId(venue.id);
		toggleConfirmModal();
	}

	const handleDelete = async () => {
		let success = await remove({id: deleteId});
		if(success) {
			toggleConfirmModal();
			toggleSnackbar();
		}
  }

	const handleEdit = (venue) => {
		props.navigate(`/venues/edit/${venue.id}`)
	}

  return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item sm={6} xs={12}>
						<PageHeader headerTitle="Venues" icon={<LocationCityOutlined />} />
					</Grid>
					<Grid item sm={6} xs={12} className="text-right">
						<Button
							size="small"
							className={`${classes.addBtn}`}
							variant="contained"
							onClick={() => props.navigate('/venues/edit/new')}
						>
							<AddCircle style={{fontSize:15,marginRight:4}}/>
							Create New
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Paper>
					<SuccessSnackbar
						open={snackbarOpen}
						toggleSnackbar={ () => toggleSnackbar() }
						message="Venue deleted successfully."
					/>
					<ConfirmModal
						title=""
						color="action"
						open={confirmModalOpen}
						toggleModal={() => toggleConfirmModal()}
						handleYes={() => handleDelete()}
						DialogTitle="Are you sure?"
						ContentText="Are you sure you want to delete this venue? This process cannot be undone."
					/>
					<TableComponent
						data={venues}
						headCells={headCells}
						handleEdit={(venue) => handleEdit(venue)}
						handleDelete={(venue) => confirmDelete(venue)}
					/>
				</Paper>
			</Grid>
		</Grid>
  );
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

const mapStateToProps = state => ({
	venues: state.Venue.venues,
});

const mapDispatchToProps = {
  loadAll: () => loadAll(),
	remove: (payload) => remove(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Venues));
