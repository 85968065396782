import React, {useEffect,useState} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import {changeFilters, loadAllStats, loadDealsByMonth} from '../actions/report';
import {loadAll as loadStages} from '../actions/stage';
import {loadAll as loadVenues} from '../actions/venue';
import {makeStyles} from '@mui/styles';
import {Grid, Paper, Button, TextField, Fab, Tooltip, Stack} from '@mui/material';
import {EqualizerRounded, GetApp} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/lab/Autocomplete';
// import DateFnsUtils from '@date-io/date-fns';
import PageHeader from '../components/PageHeader.js';
import ReportDealsByType from './ReportDealsByType';
import ReportDealsByMonth from './ReportDealsByMonth';
import ReportDealsByStageChart from './ReportDealsByStageChart';
import ReportDealsRevenueByMonthChart from './ReportDealsRevenueByMonthChart';
import ReportNewDealsByTypeChart from './ReportNewDealsByTypeChart';
import ReportWonDealsByTypeChart from './ReportWonDealsByTypeChart';
import ReportKeyStats from './ReportKeyStats';
import ReportDownloadDialog from './ReportDownloadDialog';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: '15px',
	},
	filterContainer: {
		[theme.breakpoints.down('xs')]: {
			display: 'grid',
    },
	},
	filters: {
		textAlign: 'right',
	},
	dateField: {
		maxWidth: '180px',
		marginRight: '5px',
		marginBottom: '5px',
		marginTop: '2px !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			marginTop: '4px !important',
    },
	},
	autocomplete: {
		width: '230px',
		display: 'inline-block',
		marginRight: '35px',
		'& div': {
			verticalAlign: 'middle',
			marginBottom: 0,
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
    },
	},
	downloadFab: {
		position: 'absolute',
		right: 20,
		top: 80,
	},
	btnGreen: {
		color: '#ffffff',
		border: '1px solid #82c34c',
		backgroundColor: 'rgb(109,180,78)',
		boxShadow: 'none',
		marginTop: '3px',
		'&:hover': {
			backgroundColor: 'rgb(109,180,78, 0.7)',
			boxShadow: 'none',
		}
	},
}));

const Reports = (props) => {

	const classes = useStyles();

	const [venue, setVenue] = useState(null);
	const changeVenue = (event, venue) => {
		setVenue(venue);
	}

	useEffect(() => {
		getData();
	}, [venue]);

	const getData = async () => {

		let venue_id = venue ? venue.id : '';

		await props.loadStages({venue_id: venue_id});
		await props.loadAllStats(venue_id);
		await props.loadDealsByMonth(venue_id);

		if(props.user.role === 'god_admin') {
			await props.loadVenues();
		}
	}

	const [openDownload, setOpenDownload] = useState(false);
	const toggleDownloadModal = () => {
		setOpenDownload(!openDownload);
	}

  return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2} >
					<Grid item sm={6} xs={12}>
						<PageHeader headerTitle="Reports" icon={<EqualizerRounded />}/>
					</Grid>
					<Grid item sm={6} xs={12} className="text-right">
						{(props.user.role === 'god_admin') && (
							<Autocomplete
								size="small"
								className={classes.autocomplete}
								options={props.venues}
								getOptionLabel={option => option.name}
								onChange={changeVenue}
								renderInput={params => (
									<TextField {...params} label="Select Venue" placeholder="Select Venue" variant="outlined" fullWidth margin="dense" />
								)}
							/>
						)}
						<div className={classes.downloadFab}>
							<Tooltip title="Download PDF Report">
								<Fab size="medium" color="primary" onClick={() => toggleDownloadModal()}>
									<GetApp />
								</Fab>
							</Tooltip>
						</div>
						{openDownload && (
							<ReportDownloadDialog
								{...props}
								toggleModal={toggleDownloadModal}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container spacing={2}>
						<Grid item xs={12} className={classes.filters}>
							<Filters {...props} venue={venue} />
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<ReportKeyStats {...props} />
						</Grid>
						<Grid item xl={12} md={12} sm={12} xs={12}>
							<Grid container spacing={2}>
								<Grid item md={12} sm={12} xs={12}>
									<Grid container spacing={2}>
										{(props.new_deals_by_type.length > 0) && (
												<Grid item lg={6} md={6} xs={12}>
													<ReportNewDealsByTypeChart />
												</Grid>
										)}
										{(props.won_deals_by_type.length > 0) && (
												<Grid item lg={6} md={6} xs={12}>
													<ReportWonDealsByTypeChart />
												</Grid>
										)}
										{(props.deals_by_stage.length > 0) && (
												<Grid item lg={6} md={6} xs={12}>
													<ReportDealsByStageChart />
												</Grid>
										)}
										{(props.deals_revenue_by_month.length > 0) && (
												<Grid item lg={6} md={6} xs={12}>
													<ReportDealsRevenueByMonthChart />
												</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<ReportDealsByType />
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<ReportDealsByMonth venue={venue} />
			</Grid>
		</Grid>
  );
}

const Filters = (props) => {
	const classes = useStyles();

	const handleDateFrom = (timestamp) => {
		props.changeFilters({date_from: timestamp});
	}

	const handleDateTo = (timestamp) => {
		props.changeFilters({date_to: timestamp});
	}

	const handleSubmit = () => {
		let venue_id = props.venue ? props.venue.id : '';
		props.loadAllStats(venue_id);
	}

	return(
		<Grid container>
			<Grid item xs={12} className={classes.filterContainer}>
				<Stack justifyContent="flex-end"
							 direction={{ xs: 'column', sm: 'row' }}
							 spacing={1}>
					<LocalizationProvider dateAdapter={AdapterDateFns} className="date-picker-div">
						<DatePicker
							label="Date From"
							disableToolbar
							className={classes.dateField}
							autoOk
							format="MM/dd/yyyy"
							variant="inline"
							name="date_from"
							value={props.date_from}
							onChange={handleDateFrom}
							fullWidth
							animateYearScrolling={true}
							inputVariant="outlined"
							margin="dense"
							renderInput={params => (
								<TextField size="small" {...params} variant="outlined" margin="dense" />
							)}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterDateFns} className="date-picker-div">
						<DatePicker
							label="Date To"
							disableToolbar
							className={classes.dateField}
							autoOk
							format="MM/dd/yyyy"
							variant="inline"
							name="date_to"
							value={props.date_to}
							onChange={handleDateTo}
							fullWidth
							animateYearScrolling={true}
							inputVariant="outlined"
							margin="dense"
							renderInput={params => (
								<TextField size="small" {...params} variant="outlined" margin="dense" />
							)}
						/>
					</LocalizationProvider>
					<Button size="small" variant="contained" className={classes.btnGreen} onClick={handleSubmit}>
						Submit
					</Button>
				</Stack>
			</Grid>
		</Grid>
	)
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

const mapStateToProps = state => ({
	user: state.Auth.user,
	venues: state.Venue.venues,
	date_from: state.Report.date_from,
	date_to: state.Report.date_to,
	year: state.Report.year,
	key_stats: state.Report.key_stats,
	deals_by_type: state.Report.deals_by_type,
	deals_by_month: state.Report.deals_by_month,
	deals_by_stage: state.Report.deals_by_stage,
	deals_revenue_by_month: state.Report.deals_revenue_by_month,
	new_deals_by_type: state.Report.new_deals_by_type,
	won_deals_by_type: state.Report.won_deals_by_type,
	by_range_completed: state.Report.by_range_completed,
	by_year_completed: state.Report.by_year_completed,
});

const mapDispatchToProps = {
	loadStages: (payload) => loadStages(payload),
	changeFilters: (payload) => changeFilters(payload),
	loadAllStats: (payload) => loadAllStats(payload),
	loadDealsByMonth: (payload) => loadDealsByMonth(payload),
	loadVenues: () => loadVenues(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Reports));
