export const REPORT = {
	REPORT_CHANGE_FILTERS: 'REPORT_CHANGE_FILTERS',
	REPORT_CHANGE_YEAR: 'REPORT_CHANGE_YEAR',
	REPORT_LOAD_KEY_STATS: 'REPORT_LOAD_KEY_STATS',
	REPORT_LOAD_DEALS_BY_TYPE: 'REPORT_LOAD_DEALS_BY_TYPE',
	REPORT_LOAD_DEALS_BY_MONTH: 'REPORT_LOAD_DEALS_BY_MONTH',
	REPORT_LOAD_DEALS_BY_STAGE: 'REPORT_LOAD_DEALS_BY_STAGE',
	REPORT_LOAD_DEALS_REVENUE_BY_MONTH: 'REPORT_LOAD_DEALS_REVENUE_BY_MONTH',
	REPORT_LOAD_NEW_DEALS_BY_TYPE: 'REPORT_LOAD_NEW_DEALS_BY_TYPE',
	REPORT_LOAD_WON_DEALS_BY_TYPE: 'REPORT_LOAD_WON_DEALS_BY_TYPE',
	REPORT_LOAD_REQUEST: 'REPORT_LOAD_REQUEST',
}
