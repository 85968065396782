import React from 'react';
import { connect } from 'react-redux';
import {changeYear, loadDealsByMonth} from '../actions/report';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableRow, TablePagination, Toolbar, Typography, Paper, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import TableHeader from '../components/table/TableHeader';
import { stableSort, getSorting } from '../components/table/TableFunctions';
import {formatNumber} from '../helpers/GeneralFunctions.js';

const headCells = [
	{ id: 'month_year', align: "left", label: 'Month', },
	{ id: 'number_of_new_deals', align: "center", label: 'Number of new Deals', },
  { id: 'revenue_of_new_deals', align: "right", label: 'Revenue of New Deals' },
  { id: 'number_of_deals_won', align: "center", label: 'Number of Deals Won' },
  { id: 'revenue_of_deals_won', align: "right", label: 'Revenue of Deals Won' },
	{ id: 'conversion_rate', align: "left", label: 'Conversion Rate' },
	{ id: 'average_spend', align: "right", label: 'Average Spend' },
];

TableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
	},
	autocomplete: {
		float: 'right',
		minWidth: '150px',
	},
	formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const EnhancedTableToolbar = (props) => {

	const classes = useToolbarStyles();
	const [yearOptions, setYearOptions] = React.useState([]);

	if(props.year && yearOptions.length === 0) {
		let temp_arr = [];
		for(let y = 2019; y <= props.year + 1; y++) {
			temp_arr.push({year: y});
		}
		setYearOptions(temp_arr);
	}

	const handleYearChange = (event) => {
		props.changeYear({year: event.target.value});
		props.loadDealsByMonth();
	}

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle">
				Deals By Month
			</Typography>
			 <FormControl variant="outlined" className={classes.formControl} margin="dense">
					<InputLabel>Year</InputLabel>
					<Select
						value={props.year}
						onChange={handleYearChange}
						labelWidth={30}
					>
						{yearOptions.map((item, key) => {
								return(
									<MenuItem key={key} value={item.year}>{item.year}</MenuItem>
								)
						})}
					</Select>
				</FormControl>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
		width: '100%',
		border: '1px solid #e2e0e0',
  },
  table: {
    width: '100%',
  },
}));

const ReportDealsByMonth = (props) => {

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created_at');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.deals_by_month.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
	};

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar {...props}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            // size="small"
            aria-label="enhanced table"
          >
						<TableHeader
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>

            <TableBody>
              {stableSort(props.deals_by_month, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                    >
											<TableCell>
												{item.monthname}
											</TableCell>
                      <TableCell align="center">
												{item.number_of_new_deals}
											</TableCell>
                      <TableCell align="right">
													{formatNumber(item.revenue_of_new_deals)}
											</TableCell>
                      <TableCell align="center">
												{item.number_of_deals_won}
											</TableCell>
                      <TableCell align="right">
												{formatNumber(item.revenue_of_deals_won)}
											</TableCell>
											<TableCell style={{width:200}}>
												{item.conversion_rate}
											</TableCell>
											<TableCell align="right">
												{formatNumber(item.average_spend)}
											</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={props.deals_by_month.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({
	year: state.Report.year,
  deals_by_month: state.Report.deals_by_month,
});

const mapDispatchToProps = {
	changeYear: (payload) => changeYear(payload),
	loadDealsByMonth: () => loadDealsByMonth(),
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReportDealsByMonth);
