import React, { useEffect, useState } from "react";
import {
	Grid,
	Button,
	Paper,
	TextField,
	MenuItem,
	Fab,
	IconButton,
	Tooltip,
	Divider,
	Switch,
	FormControlLabel,
	Alert,
	AlertTitle,
	Link,
	Box,
	LinearProgress,
} from "@mui/material";
import PageHeader from "../components/PageHeader.js";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NoteAdd, DeleteOutline, Add, Close } from "@mui/icons-material";
import { save, load } from "../actions/contact_builder";
import { SuccessSnackbar2 } from "../components/Snackbars.js";

const types = [
	{ val: "" },
	{ val: "Short Answer" },
	{ val: "Paragraph" },
	{ val: "Multiple Choice" },
	{ val: "Checkbox" },
	{ val: "Dropdown" },
	{ val: "Date" },
];

const FormBuilderContact = (props) => {
	const navigate = useNavigate();
	const [form, setForm] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState("");

	const openSnackbar = () => {
		setSnackbarOpen(true);
	};
	const closeSnackbar = () => {
		setSnackbarOpen(false);
	};

	useEffect(() => {
		props.load({ user_id: props.user.id });
	}, []);

	useEffect(() => {
		setForm(JSON.parse(props.contact_form.contacts_other_info_details));
	}, [props.contact_form]);

	const handleFormChange = (index) => (event) => {
		let field = event.target.name;
		let value = event.target.value;

		let required = event.target.required;

		if (required) {
		}

		if (field == "required") {
			value = value === "true";
		}
		let newArr = [...form];
		newArr[index][field] = value;
		setForm(newArr);
	};

	const handleOptionChange = (index, key) => (event) => {
		let value = event.target.value;
		let newArr = [...form];
		newArr[index]["options"][key] = value;
		setForm(newArr);
	};

	const removeOption = (index, main_key) => (event) => {
		let newArr = [...form];
		newArr[index]["options"] = newArr[index]["options"].filter(
			(item, key) => key != main_key
		);
		setForm(newArr);
	};

	const addOption = (index, length) => {
		let newArr = [...form];
		newArr[index]["options"] = [
			...newArr[index]["options"],
			"Option " + (length + 1),
		];
		setForm(newArr);
	};

	const removeBuilder = (index) => {
		let newArr = [...form];
		newArr = newArr.filter((item, key) => key != index);
		setForm(newArr);
	};

	const addBuilder = () => {
		setForm((form) => [
			...form,
			{
				id: 1,
				title: "",
				type: "",
				placeholder: "",
				options: ["Option 1"],
				required: true,
				disabled: false,
			},
		]);
	};
	const handleFormSubmit = (event) => {
		event.preventDefault();
		let formObject = {
			user_id: props.user.id,
			contacts_other_info_details: JSON.stringify(form),
		};
		if (Object.keys(props.contact_form).length > 0) {
			formObject["id"] = props.contact_form.id;
		}
		props.save(formObject);
		setSnackbarMessage("Dynamic contact form successfully saved.");
		openSnackbar();
	};

	const Capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	return (
		<>
			{props.success && snackbarOpen && (
				<SuccessSnackbar2
					open={snackbarOpen}
					openSnackbar={() => openSnackbar()}
					closeSnackbar={() => closeSnackbar()}
					message={snackbarMessage}
				/>
			)}
			<form autoComplete="off" onSubmit={handleFormSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<PageHeader
									icon={<NoteAdd />}
									headerTitle="Dynamic Forms"
									subTitle="Create Contact"
								/>
							</Grid>
							<Grid item xs={6} className="text-right"></Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{form.map((item, index) => (
							<Paper key={index + 1} className="w-8/12 mx-auto mb-4 p-4">
								<div className="grid grid-cols-4 gap-3">
									<div className="col-span-2">
										<TextField
											fullWidth
											required
											className="form-group"
											variant="outlined"
											margin="dense"
											label="Field Title"
											name="title"
											value={Capitalize(item.title)}
											size="small"
											disabled={item.disabled}
											onChange={handleFormChange(index)}
											// error={true}
										/>
									</div>
									<div>
										<TextField
											size="small"
											required
											className="form-group"
											fullWidth
											select
											label="Type"
											placeholder="Type"
											name="type"
											onChange={handleFormChange(index)}
											value={item.type}
											disabled={item.disabled}
										>
											{types.map((option, index) => (
												<MenuItem key={index} value={option.val}>
													{option.val}
												</MenuItem>
											))}
										</TextField>
									</div>
									<div>
										<div className="flex items-center">
											<FormControlLabel
												value="start"
												control={
													<Switch
														inputProps={{ name: "required" }}
														value={!item.required}
														checked={item.required}
														onChange={handleFormChange(index)}
														disabled={
															item.title === "Phone" || item.title === "Email"
																? false
																: item.disabled
														}
													/>
												}
												label="Required"
												labelPlacement="start"
											/>
											<Divider
												orientation="vertical"
												flexItem
												variant="middle"
												style={{ margin: 10 }}
											/>
											<FormControlLabel
												control={
													<IconButton
														aria-label="delete"
														color="error"
														disabled={item.disabled}
														onClick={() => removeBuilder(index)}
													>
														<DeleteOutline />
													</IconButton>
												}
											/>
										</div>
									</div>
									<div className="col-span-2">
										{(item.type == "Short Answer" ||
											item.type == "Paragraph" ||
											item.type == "Date") && (
											<TextField
												fullWidth
												variant="outlined"
												margin="dense"
												label={item.type}
												name={item.type}
												value={item.type}
												size="small"
												disabled
												onChange={handleFormChange(index)}
											/>
										)}
										{(item.type == "Multiple Choice" ||
											item.type == "Checkbox" ||
											item.type == "Dropdown") && (
											<>
												{item.options.map((option, key) => (
													<div className="grid grid-cols-4 gap-2" key={key}>
														<TextField
															fullWidth
															variant="standard"
															margin="dense"
															label={"Option" + " " + (key + 1)}
															name="Option"
															value={option}
															size="small"
															key={key}
															className="col-span-3"
															onChange={handleOptionChange(index, key)}
														/>
														<div className="my-auto">
															{item.options.length > 1 && (
																<IconButton
																	aria-label="delete"
																	onClick={removeOption(index, key)}
																>
																	<Close />
																</IconButton>
															)}
														</div>
													</div>
												))}
												<div>
													<Button
														variant="text"
														disabled={item.disabled}
														onClick={() =>
															addOption(index, item.options.length)
														}
													>
														Add Option
													</Button>
												</div>
											</>
										)}
									</div>
								</div>
								<div className="flex float-right mt-1">
									{form.length - 1 == index && (
										<Tooltip
											title="Add New Field"
											color="primary"
											size="small"
											onClick={addBuilder}
										>
											<Fab aria-label="add" size="small">
												<Add />
											</Fab>
										</Tooltip>
									)}
								</div>
							</Paper>
						))}
						<div className="w-8/12 mx-auto mb-4 pt-4 pb-4 flex justify-end space-x-4">
							<div className="">
								<Button onClick={() => navigate(-1)}>Cancel</Button>
							</div>
							<div>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									// onClick={handleFormSubmit}
								>
									{props.isProcessing ? "Saving ..." : "Save"}
								</Button>
							</div>
						</div>
					</Grid>
				</Grid>
			</form>
		</>
	);
};

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
	isProcessing: state.ContactBuilder.isProcessing,
	formLoading: state.ContactBuilder.formLoading,
	user: state.Auth.user,
	contact_form: state.ContactBuilder.contact_form,
	success: state.ContactBuilder.success,
});

const mapDispatchToProps = {
	save: (payload) => save(payload),
	load: (payload) => load(payload),
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(FormBuilderContact));
