import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import TableComponent from '../components/table/TableComponent';

const headCells = [
	{ id: 'contact_name', label: 'Contact Name' },
	{ id: 'stage_name', label: 'Deal Stage', sortKey: 'stage_position' },
	{ id: 'title', label: 'Title' },
	{ id: 'type', label: 'Event Type' },
	{ id: 'date', label: 'Event Date', align: "center", type: 'date' },
	{ id: 'value', label: 'Price', align: "right", type: 'number' },
	{ id: 'created_at', label: 'Date Created', align: "center", type: 'date' },
	{ id: '', label: 'Action', align: "right", sortable: true, searchable: false, width: 110 },
];

const DealList = (props) => {

	const { deals, history, openEditorModal, confirmDelete } = props;

	const handleView = (deal) => {
		props.navigate(`/deals/view/${deal.id}`);
	}

	const handleEdit = (deal) => {
		openEditorModal(deal.id);
	}

	const handleDelete = (deal) => {
		confirmDelete(deal.id);
	}

	return (
		<Paper>
			<TableComponent
				data={deals}
				headCells={headCells}
				handleView={(deal) => handleView(deal)}
				handleEdit={(deal) => handleEdit(deal)}
				handleDelete={(deal) => handleDelete(deal)}
			/>
		</Paper>
	);
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

const mapStateToProps = state => ({
	deals: state.Deal.deals,
});

export default connect(
	mapStateToProps,
)(withRouter(DealList));
