import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

const AdminRoutes = (props) => {
	return (
		<div>
			{props.user.role === "god_admin" ? <Outlet /> : <Navigate to = '/login' />}
		</div>
	)
}

const mapStateToProps = state => ({
    user: state.Auth.user
});

export default connect(
	mapStateToProps
)(AdminRoutes);