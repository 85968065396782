import React from 'react';
import { connect } from 'react-redux';
import {Bar} from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Typography } from '@mui/material';
import 'chartjs-plugin-datalabels';

const useStyles = makeStyles({
  paper: {
		padding: '20px',
		border: '1px solid #e2e0e0',
	},
});

const legendOpts = {
  display: false,
  position: 'bottom',
  fullWidth: true,
  reverse: false,
  labels: {
    fontColor: 'rgb(77, 77, 77)'
	}
};

const options = {
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero: true
			}
		}],
	},
	plugins: {
		datalabels: {
			display: true,
			color: '#216367',
			anchor: 'end',
			font: {
				size: 9,
				weight: 700,
			},
			formatter: function(value, context) {
				return value > 0 ? `$${value.toFixed(2)}\n` : '';
			}
		}
	}
};

const ReportDealsRevenueByMonthChart = (props) => {

	const classes = useStyles();

	if(props.deals_revenue_by_month.length === 0) {
		return null;
	}

	const data = {
		labels: getData(props.deals_revenue_by_month, 'label'),
		datasets: [
			{
			label: 'Amount',
			maxBarThickness: 60,
			backgroundColor: 'rgb(9, 82, 86, 0.9)',
			borderColor: 'rgb(9, 82, 86)',
			borderWidth: 1,
			hoverBackgroundColor: 'rgba(9, 82, 86,0.7)',
			hoverBorderColor: 'rgba(9, 82, 86)',
			data: getData(props.deals_revenue_by_month, 'data'),
			}
		]
	};

	return (
			<Paper className={classes.paper}>
			<Grid container spacing={2} id="bar_chart_by_month">
				<Grid item xs={12}>
					<Typography variant="h6">
						Revenue By Month
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Bar data={data} legend={legendOpts} options={options} redraw/>
				</Grid>
			</Grid>
		</Paper>
	)
}

const getData = (stat, type) => {

	let data = [];
	let labels = [];
	let max_cnt = 0;

	for(let i in stat) {
		data.push(stat[i].value);
		labels.push(stat[i].month);
		max_cnt = max_cnt > stat[i].value ? max_cnt : stat[i].value;
	}

	options.scales.yAxes[0].ticks.max = max_cnt  + parseInt((max_cnt*0.1));

	if(type === 'label') {
		return labels;
	}

	if(type === 'data') {
		return data;
	}

	return data;

}

const mapStateToProps = state => ({
	deals_revenue_by_month: state.Report.deals_revenue_by_month,
});

export default connect(
  mapStateToProps
)(ReportDealsRevenueByMonthChart);
