import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { typing, save, load, clearForm } from '../actions/venue';
import { makeStyles } from '@mui/styles';
import { LocationCityOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, Button, Paper, TextField, InputAdornment, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PageHeader from '../components/PageHeader.js';
import AlertFormSuccess from '../components/AlertFormSuccess';
import AlertFormError from '../components/AlertFormError';
import { isObjectEmpty } from '../helpers/GeneralFunctions';
import { useParams, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px',
  },
  label: {
    color: '#636669fa',
    fontWeight: 600,
  },
}));

const VenueEdit = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  let id = useParams();

  useEffect(() => {
    props.clearForm();
    props.load({ id: id });
  }, []);

  const handleFormChange = (event) => {
    // props.typing({ [event.target.name]: event.target.value });
    if (event.target.name === 'password' || event.target.name === 'password_confirmation') {
      props.typing({ [event.target.name]: event.target.value.replace(/\s/g, '') });
    } else {
      props.typing({ [event.target.name]: event.target.value });
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!props.isProcessing) {
      let success = await props.save();
      setSuccess(success);
    }
  };

  if (document.getElementById('success') && props.success) {
    document.getElementById('success').focus();
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageHeader
          headerTitle="Venue"
          subTitle={props.venue.id ? 'Edit Details' : 'Create New'}
          icon={<LocationCityOutlined />}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!isObjectEmpty(props.errors) && <AlertFormError errors={props.errors} />}
                {success && <AlertFormSuccess message="Venue details successfully saved." />}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <label className={classes.label}>Venue Information</label>
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/*<InputLabel shrink>Venue Name</InputLabel>*/}
                        <Tooltip title={props.errors.name || ''} placement="bottom-end">
                          <TextField
                            size="small"
                            label="Venue Name"
                            placeholder="Venue Name"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="name"
                            value={props.venue.name}
                            onChange={(event) => handleFormChange(event)}
                            error={Object.prototype.hasOwnProperty.call(props.errors, 'name')}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        {/*<InputLabel shrink>Address</InputLabel>*/}
                        <Tooltip title={props.errors.address || ''} placement="bottom-end">
                          <TextField
                            size="small"
                            label="Address"
                            placeholder="Address"
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="dense"
                            name="address"
                            value={props.venue.address}
                            onChange={(event) => handleFormChange(event)}
                            error={Object.prototype.hasOwnProperty.call(props.errors, 'address')}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={{ width: '100%', margin: '15px 0 0 15px' }} />
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <label className={classes.label}>Contact Person</label>
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/*<InputLabel shrink>First Name</InputLabel>*/}
                        <Tooltip title={props.errors.firstname || ''} placement="bottom-end">
                          <TextField
                            size="small"
                            label="First Name"
                            placeholder="First Name"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="firstname"
                            value={props.venue.firstname}
                            onChange={(event) => handleFormChange(event)}
                            error={Object.prototype.hasOwnProperty.call(props.errors, 'firstname')}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        {/*<InputLabel shrink>Last Name</InputLabel>*/}
                        <Tooltip title={props.errors.lastname || ''} placement="bottom-end">
                          <TextField
                            size="small"
                            label="Last Name"
                            placeholder="Last Name"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="lastname"
                            value={props.venue.lastname}
                            onChange={(event) => handleFormChange(event)}
                            error={Object.prototype.hasOwnProperty.call(props.errors, 'lastname')}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={{ width: '100%', margin: '15px 0 0 15px' }} />
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <label className={classes.label}>Login Details</label>
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/*<InputLabel shrink>E-mail</InputLabel>*/}
                        <Tooltip title={props.errors.email || ''} placement="bottom-end">
                          <TextField
                            size="small"
                            label="E-mail"
                            placeholder="E-mail"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="email"
                            value={props.venue.email}
                            onChange={(event) => handleFormChange(event)}
                            error={Object.prototype.hasOwnProperty.call(props.errors, 'email')}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        {/*<InputLabel shrink>Password</InputLabel>*/}
                        <Tooltip title={props.errors.password || ''} placement="bottom-end">
                          <TextField
                            size="small"
                            label="Password"
                            placeholder="Password"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={props.venue.password}
                            onChange={(event) => handleFormChange(event)}
                            error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        {/*<InputLabel shrink>Password Confirm</InputLabel>*/}
                        <TextField
                          size="small"
                          label="Password Confirm"
                          placeholder="Password Confirm"
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          type={showPasswordConfirm ? 'text' : 'password'}
                          name="password_confirmation"
                          value={props.venue.password_confirmation}
                          onChange={(event) => handleFormChange(event)}
                          error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                >
                                  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={{ width: '100%', margin: '15px 0 0 15px' }} />
              <Grid item xs={12}>
                <div className="flex space-x-4 float-right">
                  <div>
                    <Button onClick={() => navigate(-1)}>Cancel</Button>
                  </div>
                  <div>
                    <Button type="submit" variant="contained" color="primary">
                      {props.isProcessing ? 'Saving ...' : 'Save'}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isProcessing: state.Venue.isProcessing,
  errors: state.Venue.errors,
  venue: state.Venue.venue,
});

const mapDispatchToProps = {
  clearForm: () => clearForm(),
  load: (payload) => load(payload),
  save: () => save(),
  typing: (payload) => typing(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueEdit);
