import {CONTACT_COMMENT} from '../constants/contact_comment';

export function clearForm() {
  return {type: CONTACT_COMMENT.CONTACT_COMMENT_CLEAR_FORM}
}

export function clearComments() {
  return {type: CONTACT_COMMENT.CONTACT_COMMENT_CLEAR}
}

export function loadAll(payload) {
  return function(dispatch) {

    global.axios({
      method: "get",
      url: `/contact_comment?contact_id=${payload.id}`,
		})
		.then(({ data }) => {
      dispatch({ type: CONTACT_COMMENT.CONTACT_COMMENT_LOAD_ALL, payload:data });
    })
    .catch( function(error) {
      console.log(error.response);
		});

  }
}

export function load(payload) {
  return function(dispatch) {
    global.axios({
      method: "get",
      url: `/contact_comment/${payload.id}`,
		})
		.then(({ data }) => {
      dispatch({ type: CONTACT_COMMENT.CONTACT_COMMENT_LOAD, payload:data });
    })
    .catch( function(error) {
      console.log(error.response);
    });
  }
}


export function save(payload) {
	return function(dispatch, getState) {

		let id = "";
		if(Object.keys(getState().ContactComment.comment).length > 0) {
			id = getState().ContactComment.comment.id;
		}

		let postData = new FormData();
		postData.append('id', id);
		postData.append('comment', payload.comment);
		postData.append('user_id', getState().Auth.user.id);
		postData.append('contact_id', getState().Contact.contact.id);

		for(let i in payload.files) {
			postData.append('files[]', payload.files[i]);
		}

		let url = "/contact_comment";

		if(id) {
			postData.append('_method', "put");
			url += "/" + id;

			for(let i in payload.deleteFiles) {
				postData.append('delete_files[]', payload.deleteFiles[i]);
			}

		}

		return global.axios({
			method: "post",
			url: url,
			data: postData,
			headers: {
			'content-type': `multipart/form-data; boundary=${postData._boundary}`,
			},
		})
    .then( ({data}) => {
			dispatch({ type: CONTACT_COMMENT.CONTACT_COMMENT_SAVE_SUCCESS });
			dispatch({ type: CONTACT_COMMENT.CONTACT_COMMENT_LOAD_ALL, payload: data });
    })
    .catch( function(error) {
			console.log("error", error);
		})
	}
}

export function remove(payload) {
  return function(dispatch, getState) {
		global.axios({
      method: "delete",
      url: `/contact_comment/${payload.id}?contact_id=${getState().Contact.contact.id}`,
    })
    .then(({ data }) => {
      dispatch({ type: CONTACT_COMMENT.CONTACT_COMMENT_LOAD_ALL, payload:data });
    })
    .catch(function(error) {
      console.log(error.response)
    });
  }
}

export function removeFile(payload) {
  return {type: CONTACT_COMMENT.CONTACT_COMMENT_DELETE_FILE, payload:payload}
}
