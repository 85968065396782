import { CONTACT } from "../constants/contact";

export function clearForm() {
	return { type: CONTACT.CONTACT_CLEAR_FORM };
}

export function remove(payload) {
	return async function (dispatch, getState) {
		let venue_id = getState().Auth.user.venue_id;
		try {
			let response = await global.axios({
				method: "delete",
				url: `/contact/${payload.id}?venue_id=${venue_id}`,
			});
			dispatch({ type: CONTACT.CONTACT_DELETE, payload: response.data });
			return true;
		} catch (error) {
			console.log(error.response);
			return false;
		}
	};
}

export function load(payload) {
	return async function (dispatch) {
		try {
			let response = await global.axios({
				method: "get",
				url: `/contact/${payload.id}`,
			});
			dispatch({ type: CONTACT.CONTACT_LOAD, payload: response.data });
			return true;
		} catch (error) {
			console.log(error.response);
			return false;
		}
	};
}

export function loadAll() {
	return async function (dispatch, getState) {
		dispatch({ type: CONTACT.CONTACT_LOAD_ALL_REQUEST });

		let url = "/contact";

		let user = getState().Auth.user;
		if (user.role === "venue") {
			url += `?venue_id=${user.venue_id}`;
		}

		try {
			let response = await global.axios({
				method: "get",
				url: url,
			});
			dispatch({ type: CONTACT.CONTACT_LOAD_ALL, payload: response.data });
		} catch (error) {
			console.log(error.response);
		}
	};
}

export function save() {
	return async function (dispatch, getState) {
		dispatch({ type: CONTACT.CONTACT_SAVE_REQUEST });

		let method = "post";
		let url = "/contact";
		let postData = getState().Contact.contact;

		if (postData.id) {
			method = "put";
			url += `/${postData.id}`;
		}

		try {
			let response = await global.axios({
				method: method,
				url: url,
				data: postData,
			});
			let data = response.data;
			if ("errors" in data) {
				dispatch({ type: CONTACT.CONTACT_SAVE_ERROR, payload: data.errors });
				return false;
			} else {
				dispatch({ type: CONTACT.CONTACT_SAVE_SUCCESS, payload: data });
				return data;
			}
		} catch (error) {
			console.log("error", error.response);
			dispatch({
				type: CONTACT.CONTACT_SAVE_ERROR,
				payload: error.response.data.errors,
			});
			return false;
		}
	};
}

export function typing(payload) {
	return { type: CONTACT.CONTACT_TYPING, payload: payload };
}
