export const STAGE = {
	STAGE_CLEAR_FORM: 'STAGE_CLEAR_FORM',
	STAGE_DELETE: 'STAGE_DELETE',
	STAGE_LOAD: 'STAGE_LOAD',
	STAGE_LOAD_ALL: 'STAGE_LOAD_ALL',
	STAGE_SAVE_REQUEST: 'STAGE_SAVE_REQUEST',
	STAGE_SAVE_SUCCESS: 'STAGE_SAVE_SUCCESS',
	STAGE_SAVE_ERROR: 'STAGE_SAVE_ERROR',
	STAGE_TYPING: 'STAGE_TYPING',
}
