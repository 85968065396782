import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from './actions/auth';
import CONFIG from './config.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import Divider from '@mui/material/Divider';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

import {
  CssBaseline,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Avatar,
  Backdrop,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import Sidebar from './components/Sidebar.js';
import logo from './pp-logo-white.png';
import logoIcon from './icon.png';
import Routes from './components/routes/Routes';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingLeft: 10,
    paddingRight: 24,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: '#F0EEFA',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(3),
    maxWidth: '100%',
    backgroundColor: '#f4f5f7',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(9),
    },
  },
  containerNoPadding: {
    padding: '0 !important',
    maxWidth: '99%',
    backgroundColor: '#f4f5f7',
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '50px !important',
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  avatar: {
    fontWeight: 959,
    paddingBottom: '2px',
    backgroundColor: '#82c34c !important',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const App = (props) => {
  // const theme = useTheme();
  useEffect(() => {
    handleScreenResize();
    window.addEventListener('resize', handleScreenResize);
  }, []);

  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [backdrop, setBackDrop] = useState(false);
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (screenWidth <= 959) {
      setBackDrop(open);
    }
  }, [open]);

  useEffect(() => {
    setOpen(screenWidth >= 960);
  }, [screenWidth]);

  const handleScreenResize = () => {
    setScreenWidth(window.screen.width);
  };

  if (
    !props.isLoggedIn ||
    ['/reports/pdf', '/test-inquiry-to-stg', '/test-inquiry-to-dev', '/404'].indexOf(
      props.location.pathname
    ) !== -1
  ) {
    return <Routes />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <AppBarContent
          {...props}
          open={open}
          toggleDrawer={toggleDrawer}
          screenWidth={screenWidth}
        />
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
        PaperProps={{
          sx: {
            backgroundColor: '#F0EEFA',
            display: 'flex',
          },
        }}
      >
        <Sidebar open={open} />
        <Typography align="center">Build v1.0.4</Typography>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          className={
            props.location.pathname === '/deals' ? classes.containerNoPadding : classes.container
          }
        >
          {backdrop && <Backdrop className={classes.backdrop} open={true}></Backdrop>}
          <Routes />
        </Container>
      </main>
    </div>
  );
};

const AppBarContent = (props) => {
  const classes = useStyles();
  const { user, open, screenWidth, toggleDrawer, logout } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <div>
        <img
          alt=""
          src={logo}
          width="190"
          className={open && screenWidth > 599 ? 'd-block mr-4' : 'd-none'}
        />
        <img
          alt=""
          src={logoIcon}
          width="40"
          className={!open || screenWidth < 599 ? 'd-block mr-4' : 'd-none'}
        />
      </div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        className={'nav-toggle text-white ' + classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      ></Typography>
      {user.avatar ? (
        <Avatar alt="avatar" src={`${CONFIG.API_STORAGE_URL}${user.avatar}`} />
      ) : (
        <Avatar className={classes.avatar}>{user.firstname.charAt(0)}</Avatar>
      )}
      <div className="btn-group">
        <button
          type="button"
          className="btn header-user-menu dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="">{user.firstname}</span>
        </button>
        <div className="dropdown-menu dropdown-menu-right nav-user-dropdown border-0">
          <button
            className="dropdown-item"
            type="button"
            onClick={() => props.navigate('/settings')}
          >
            <i className="far fa-user mr-2"></i>
            My Account
          </button>
          <button className="dropdown-item" type="button" onClick={() => logout(props.navigate)}>
            <i className="fas fa-sign-out-alt mr-2"></i>
            Logout
          </button>
        </div>
      </div>
    </Toolbar>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  logout: (navigate) => logout(navigate),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
