import {REPORT} from '../constants/report';

const initialState = {
	by_range_completed: false,
	by_year_completed: false,
	date_from: new Date(new Date().setDate(new Date().getDate() - 30)),
	date_to: new Date(),
	year: new Date().getFullYear(),
  key_stats: {
		number_of_new_deals: 0,
		revenue_of_new_deals: 0,
		number_of_deals_won: 0,
		revenue_of_deals_won: 0,
		average_spend: 0,
	},
	deals_by_type: [],
	deals_by_month: [],
	deals_by_stage: [],
	deals_revenue_by_month: [],
	new_deals_by_type: [],
	won_deals_by_type: [],
};

const Report = (state = initialState, action) => {

	let newState = JSON.parse(JSON.stringify(state));

	switch(action.type) {

		case REPORT.REPORT_CHANGE_FILTERS : {
			let key = Object.keys(action.payload)[0];
			newState[key] = action.payload[key];
			return newState;
		}

		case REPORT.REPORT_CHANGE_YEAR : {
			newState.year = action.payload.year;
			return newState;
		}

		case REPORT.REPORT_LOAD_REQUEST : {
			newState.by_range_completed = false;
			newState.by_year_completed = false;
			return newState;
		}

		case REPORT.REPORT_LOAD_KEY_STATS : {
			newState.key_stats = action.payload;
			return newState;
		}

		case REPORT.REPORT_LOAD_DEALS_BY_TYPE : {
			newState.deals_by_type = action.payload;
			return newState;
		}

		case REPORT.REPORT_LOAD_DEALS_BY_MONTH : {
			newState.deals_by_month = action.payload;
			newState.by_year_completed = true;
			return newState;
		}

		case REPORT.REPORT_LOAD_DEALS_BY_STAGE : {
			newState.deals_by_stage = action.payload;
			return newState;
		}

		case REPORT.REPORT_LOAD_DEALS_REVENUE_BY_MONTH : {
			newState.deals_revenue_by_month = action.payload;
			return newState;
		}

		case REPORT.REPORT_LOAD_NEW_DEALS_BY_TYPE : {
			newState.new_deals_by_type = action.payload;
			return newState;
		}

		case REPORT.REPORT_LOAD_WON_DEALS_BY_TYPE : {
			newState.won_deals_by_type = action.payload;
			newState.by_range_completed = true;
			return newState;
		}

    default: return state;
  }

}

export default Report;
