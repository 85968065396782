import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { typing, clearForm } from '../actions/deal';
// import { loadAll as loadContacts, clearForm as clearContactForm } from '../actions/contact';
// import { load } from '../actions/deal_builder';
import {
  Grid,
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Slide,
  TextField,
  Fab,
  // Checkbox,
  // MenuItem,
  // Radio,
  // RadioGroup,
  // FormControlLabel,
  // FormControl,
  // FormLabel,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Add as AddIcon } from '@mui/icons-material';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { InputTextDollar } from '../components/FormElements';
import DealContactEditDialog from './DealContactEditDialog.js';
// import AlertFormError from '../components/AlertFormError';
// import { isObjectEmpty } from '../helpers/GeneralFunctions';
// import InputAdornment from '@mui/material/InputAdornment';
import { SuccessSnackbar2 } from '../components/Snackbars';

const ContactSelect = ({
  loadAll,
  label,
  errors,
  deal,
  contacts,
  clearContactForm,
  handleSelectChange,
  loadContacts,
  setIsHidden,
}) => {
  const [contact, setContact] = React.useState({
    firstname: '',
    lastname: '',
  });

  useEffect(() => {
    if (deal.contact_id !== '') {
      let temp_contact = contacts.filter((item) => item.id === deal.contact_id)[0];
      setContact(temp_contact);
    }
  }, [deal.contact_id]);

  const handleOnChange = (event, contact) => {
    handleSelectChange(contact.id);
    setContact(contacts.filter((item) => item.id === contact.id)[0]);
  };

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpenNew, setSnackbarOpenNew] = useState(false);
  const [editorOpen, setEditorOpen] = React.useState(false);

  const openSnackbar = () => {
    setSnackbarOpenNew(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpenNew(false);
  };

  const openEditorModal = () => {
    clearContactForm();
    setEditorOpen(true);
    setIsHidden('hidden');
  };
  const closeEditorModal = async (event, reason) => {
    if (reason === 'backdropClick') return;

    setEditorOpen(false);
    clearContactForm();
    setIsHidden('visible');
  };

  const successCloseModal = () => {
    setEditorOpen(false);
    setIsHidden('visible');
    clearContactForm();
    setSnackbarMessage('Contact successfully saved.');
    openSnackbar();
  };

  const handleSaveSuccess = async (new_contact) => {
    await loadContacts();
    setContact(new_contact);
    handleSelectChange(new_contact.id);
    successCloseModal();
  };

  const contactsDescending = [...contacts].sort((a, b) => b.id - a.id);

  return (
    <Grid container>
      <Grid item xs={11}>
        {snackbarOpenNew && (
          <SuccessSnackbar2
            open={snackbarOpenNew}
            openSnackbar={() => openSnackbar()}
            closeSnackbar={() => closeSnackbar()}
            message={snackbarMessage}
          />
        )}
        {editorOpen && (
          <DealContactEditDialog
            open={editorOpen}
            openEditorModal={() => openEditorModal()}
            closeEditorModal={closeEditorModal}
            handleSaveSuccess={(contact) => handleSaveSuccess(contact)}
          />
        )}
        <Autocomplete
          size="small"
          disableClearable
          loading={loadAll}
          options={contactsDescending}
          getOptionLabel={(option) =>
            `${option.firstname} ${option.lastname}` + (option.email ? ` (${option.email})` : '')
          }
          value={contact}
          onChange={handleOnChange}
          renderInput={(params) => (
            <TextField
              label={label}
              placeholder={label}
              fullWidth
              variant="outlined"
              margin="dense"
              value={
                `${contact.firstname} ${contact.lastname}` +
                (contact.email ? ` (${contact.email})` : '')
              }
              error={Object.prototype.hasOwnProperty.call(errors, 'contact_id') ? true : false}
              {...params}
            />
          )}
        />
      </Grid>
      <Grid item xs={1} className="text-right">
        <Fab size="small" color="primary" onClick={() => openEditorModal()}>
          <AddIcon />
        </Fab>
      </Grid>
    </Grid>
  );
};
ContactSelect.propTypes = {
  loadAll: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.object,
  deal: PropTypes.object,
  contacts: PropTypes.array,
  clearContactForm: PropTypes.func,
  handleSelectChange: PropTypes.func,
  loadContacts: PropTypes.func,
  setIsHidden: PropTypes.func,
};
ContactSelect.defaultProps = {
  deal: {},
  contacts: [],
  clearContactForm: () => {},
  handleSelectChange: () => {},
  loadContacts: () => {},
  setIsHidden: () => {},
};

export default ContactSelect;
