import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { load, clearFlag, updateStage, save } from '../actions/deal';
import { loadAll as loadContacts, load as loadContact } from '../actions/contact';
import { loadAll as loadComments, clearComments } from '../actions/deal_comment';
import { loadAll as loadStages } from '../actions/stage';
import { DateToString } from '../helpers/DateFunctions.js';

import { makeStyles } from '@mui/styles';
import {
  Grid,
  Paper,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, Edit as EditIcon } from '@mui/icons-material';

import { SuccessSnackbar2 } from '../components/Snackbars.js';
import DealEditDialog from './DealEditDialog';
import DealComments from './DealComments';
import ConfirmModal from '../components/ConfirmModal';
import { load as loadDealBuilder } from '../actions/deal_builder';
import { load as loadContactBuilder } from '../actions/contact_builder';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  Popper: {
    zIndex: 9999,
  },
  GridLabel: {
    fontWeight: 600,
    color: '#595959',
    textTransform: 'capitalize',
  },
  ButtonLabel: {
    minWidth: '140px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DealOverview = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [editFormFields, setEditFormFields] = React.useState(null);
  const classes = useStyles();
  const [loadComplete, setLoadComplete] = React.useState(false);

  useEffect(() => {
    props.clearComments();
    async function fetchData() {
      let loaded = await props.load({ id: props.params.id });
      setLoadComplete(loaded);
      if (loaded) {
        await props.loadComments({ id: props.params.id });
        await props.loadContacts();
      } else {
        props.navigate('/404');
      }
    }
    fetchData();
  }, []);

  // useEffect(() => {
  // 	console.log("CONTAC PROPS", props.deal.contact);
  // }, [props.deal.contact]);

  if (props.stages.length === 0 && props.deal.venue_id !== '') {
    props.loadStages({ venue_id: props.deal.venue_id });
  }

  const [EditorOpen, setEditorOpen] = React.useState(false);
  const openEditorModal = async (deal_id) => {
    setIsLoading(true);
    if (deal_id) {
      await props.load({ id: deal_id });
    }
    setEditFormFields(JSON.parse(props.deal_form.deals_other_info_details));
    setEditorOpen(true);
    setIsLoading(false);
  };
  const closeEditorModal = () => {
    setEditorOpen(false);
  };

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const handleStageChange = (stageId) => {
    setStageId(stageId);
    toggleConfirmModal();
  };

  const updateStage = () => {
    props.updateStage({ stage_id: stageId });
    setStageNameById(stageId);
    setStageId(null);
    toggleConfirmModal();
  };

  const setStageNameById = (stageId) => {
    let stage = props.stages.filter((item) => item.stage_id === stageId);
    if (stage.length > 0) {
      setStageName(stage[0].name ? stage[0].name : stage[0].stage.name);
    }
  };

  const [stageId, setStageId] = React.useState(null);
  const [stageName, setStageName] = React.useState(null);
  if (props.stages.length !== 0 && !stageName) {
    setStageNameById(props.deal.stage.stage_id);
  }

  const handleSave = async (formFields) => {
    // console.log('handleSave', formFields)
    let success = await props.save(formFields);
    if (success) {
      setSnackbarMessage('Deal successfully saved.');
      openSnackbar();
      props.clearFlag();
      closeEditorModal();
    }
  };

  if (!loadComplete) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <div>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" className="mb-2">
              <Box p={1} flexGrow={1}>
                <h5 className="mt-1 font-weight-bold">Deal Overview</h5>
              </Box>
              <Box p={1}>
                {props.user.role === 'god_admin' ? null : (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => openEditorModal(props.deal.id)}
                  >
                    <EditIcon className="btn-icon mr-1" />
                    Edit
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {confirmModalOpen && (
          <ConfirmModal
            title="update"
            color="action"
            open={confirmModalOpen}
            toggleModal={() => toggleConfirmModal()}
            handleYes={() => updateStage()}
            DialogTitle="Confirm Update"
            ContentText="Are you sure you want to update deal stage?"
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {snackbarOpen && (
              <SuccessSnackbar2
                open={snackbarOpen}
                openSnackbar={() => openSnackbar()}
                closeSnackbar={() => closeSnackbar()}
                message={snackbarMessage}
              />
            )}
            <DealEditDialog
              fields={editFormFields}
              open={EditorOpen}
              openEditorModal={() => openEditorModal()}
              closeEditorModal={() => closeEditorModal()}
              handleSave={handleSave}
              {...props}
            />
            <Paper className="p-3">
              <Grid container spacing={0}>
                <Grid item sm={9} xs={12}>
                  <h5
                    className="font-weight-bold"
                    style={{
                      color: '#008799',
                    }}
                  >
                    {props.deal.title}
                  </h5>
                </Grid>
                <Grid item sm={3} xs={12} className="text-right">
                  {stageName && (props.user.role === 'god_admin' || props.deal.archived) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className="pl-4 pr-4"
                      disableElevation
                    >
                      {stageName}
                    </Button>
                  ) : (
                    <StageSelect
                      stageName={stageName}
                      handleStageChange={(stage) => handleStageChange(stage)}
                      {...props}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item sm={1} xs={12}>
                      <span className="font-weight-bold" style={{ color: '#4d4d4d' }}>
                        <span className="mr-1" style={{ fontSize: '12px' }}>
                          $
                        </span>
                        <span style={{ fontSize: '16px' }}>
                          {props.deal.value
                            ? Number(props.deal.value).toLocaleString('en', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : '0.00'}
                        </span>
                      </span>
                    </Grid>
                    <Grid item sm={11} xs={12}>
                      <span className="font-weight-bold" style={{ color: '#4d4d4d' }}>
                        <i className="far fa-address-book mr-1" style={{ fontSize: '12px' }}></i>
                        <span style={{ fontSize: '16px' }}>
                          {props.deal.contact
                            ? `${props.deal.contact.firstname} ${props.deal.contact.lastname}`
                            : null}
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="mt-2">
          <Grid item lg={5} md={6} sm={12}>
            <Grid container spacing={2}>
              <DealDetails {...props} />
              <ContactDetails {...props} />
            </Grid>
          </Grid>
          <Grid item lg={7} md={6} sm={12}>
            <Paper className="p-3">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h6 className="font-weight-bold">Deal Notes</h6>
                </Grid>
                <Grid item xs={12}>
                  <DealComments />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
};

const DealDetails = (props) => {
  const classes = useStyles();

  useEffect(() => {
    props.loadDealBuilder({ user_id: props.user.id });
  }, []);

  return (
    <Grid item xs={12}>
      <Paper className="p-3">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span" className="font-weight-bold">
              Deal Details
            </Typography>
          </Grid>
          {props.user.role === 'god_admin' && (
            <>
              <Grid item sm={4} xs={12} className={classes.GridLabel}>
                Venue
              </Grid>
              <Grid item sm={8} xs={12}>
                {' '}
                {'venue' in props.deal ? props.deal.venue.name : ''}{' '}
              </Grid>
            </>
          )}
          <Grid item sm={4} xs={12} className={classes.GridLabel}>
            Date
          </Grid>
          <Grid item sm={8} xs={12}>
            {props.deal.date ? DateToString(props.deal.date) : null}
          </Grid>

          <Grid item sm={4} xs={12} className={classes.GridLabel}>
            Type
          </Grid>
          <Grid item sm={8} xs={12}>
            {props.deal.type === 'Other' && props.deal.type_other
              ? props.deal.type_other
              : props.deal.type}
          </Grid>
          <Grid item sm={4} xs={12} className={classes.GridLabel}>
            Description
          </Grid>
          <Grid item sm={8} xs={12}>
            {props.deal.notes}
          </Grid>
          {props.deal_form &&
            JSON.parse(props.deal_form.deals_other_info_details).map((item, index) => (
              <Grid container key={index}>
                {item.disabled == false &&
                  JSON.parse(props.deal.additional_info)[
                    item.title.replace(/ /g, '_').toLowerCase()
                  ] !== undefined && (
                    <>
                      <Grid item sm={4} xs={12} className={classes.GridLabel + ' pt-2 pl-2'}>
                        {item.title}
                      </Grid>
                      <Grid item sm={8} xs={12} className={'pt-2 pl-2'}>
                        {' '}
                        {props.deal.additional_info
                          ? JSON.parse(props.deal.additional_info)[
                              item.title.replace(/ /g, '_').toLowerCase()
                            ] + ' '
                          : null}
                      </Grid>
                    </>
                  )}
              </Grid>
            ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

const ContactDetails = (props) => {
  const classes = useStyles();
  const form = props.contact_form.contacts_other_info_details
    ? JSON.parse(props.contact_form.contacts_other_info_details)
    : [];
  const data = props.deal.contact.additional_info
    ? JSON.parse(props.deal.contact.additional_info)
    : props.deal.contact;

  useEffect(() => {
    props.loadContactBuilder({ user_id: props.user.id });
  }, []);

  return (
    <Grid item xs={12}>
      <Paper className="p-3">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span" className="font-weight-bold">
              Contact Details
            </Typography>
          </Grid>

          {!props.deal.contact ? null : (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12} className={classes.GridLabel}>
                  Name
                </Grid>
                <Grid item sm={8} xs={12}>
                  {`${data.firstname} ${data.lastname}`}
                </Grid>
                {props.contact_form &&
                  form.map((item, index) => (
                    <>
                      {(item.title !== 'First Name' || item.title !== 'Last Name') &&
                        data[item.title.replace(/\s/g, '_').toLowerCase()] !== undefined &&
                        data[item.title.replace(/\s/g, '_').toLowerCase()] !== null && (
                          <>
                            <Grid item sm={4} xs={12} className={classes.GridLabel + ' pt-2 pl-2'}>
                              {item.title}
                            </Grid>
                            <Grid item sm={8} xs={12} className={'pt-2 pl-2'}>
                              {item.type == 'Date'
                                ? moment(data[item.title.replace(/\s/g, '_').toLowerCase()]).format(
                                    'MMMM DD, YYYY'
                                  )
                                : data[item.title.replace(/\s/g, '_').toLowerCase()] + ' '}
                            </Grid>
                          </>
                        )}
                    </>
                  ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const StageSelect = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, stageId) => {
    setOpen(false);

    if (props.deal.stage.stage_id !== stageId) {
      props.handleStageChange(stageId);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="flex-end">
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <Button className={classes.ButtonLabel}> {props.stageName} </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} transition className={classes.Popper}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {props.stages.map((option, index) => (
                      <MenuItem
                        key={index}
                        selected={option.id === props.deal.stage.stage_id}
                        onClick={(event) => handleMenuItemClick(event, option.stage_id)}
                      >
                        {option.name ? option.name : option.stage.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
  success: state.Deal.success,
  deal: state.Deal.deal,
  contacts: state.Contact.contacts,
  user: state.Auth.user,
  stages: state.Stage.stages,
  deal_form: state.DealBuilder.deal_form,
  contact_form: state.ContactBuilder.contact_form,
});

const mapDispatchToProps = {
  load: (payload) => load(payload),
  loadDealBuilder: (payload) => loadDealBuilder(payload),
  loadComments: (payload) => loadComments(payload),
  loadContacts: () => loadContacts(),
  loadStages: (payload) => loadStages(payload),
  clearFlag: () => clearFlag(),
  updateStage: (payload) => updateStage(payload),
  clearComments: () => clearComments(),
  save: (payload) => save(payload),
  loadContactBuilder: (payload) => loadContactBuilder(payload),
  loadContact: (payload) => loadContact(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DealOverview));
