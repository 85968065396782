import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    borderRadius: 3,
    padding: 20,
  },
  container2: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}));

const Divider = () => {
  return(
    <hr style={{ width: '100%' }} />
  )
}

const InputText = (props) => {
  return (
    <div className={`form-group ${props.className}`}>
      {/* <InputLabel shrink> {props.label} </InputLabel> */}
      <TextField
				size="small"
        fullWidth
        variant="outlined"
        margin="dense"
        className="mt-0"
        name={props.name}
				placeholder={`${props.label ?? props.placeholder }`}
        value={props.value}
        label={props.label}
        onChange={(event) => props.handleFormChange(event)}
      />
    </div>
  );
}

const InputTextRequired = (props) => {
  return (
    <div className={`form-group ${props.className}`}>
      {/* <InputLabel shrink> {props.label} </InputLabel> */}
      <TextField
        fullWidth
				size="small"
        variant="outlined"
        margin="dense"
        className="mt-0"
        name={props.name}
				placeholder={`${props.label ?? props.placeholder }`}
        value={props.value}
        label={props.label}
        onChange={(event) => props.handleFormChange(event)}
        required={props.className === 'd-none' ? false : true}
      />
    </div>
  );
}

const InputPassword = (props) => {

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className="form-group">
      <InputLabel shrink> {props.label} </InputLabel>
      <TextField
        fullWidth
				size="small"
        margin="dense"
        className="mt-0"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        name={props.name}
				placeholder={`${props.label ?? props.placeholder }`}
        value={props.value}
        onChange={props.handlePasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

const InputTextDollar = (props) => {
  return (
    <div className="form-group" style={{marginTop: '10px'}}>
      {/*<InputLabel shrink> {props.label} </InputLabel>*/}
      <TextField
				size="small"
				fullWidth
				type="number"
        margin="dense"
        className="mt-0"
        variant="outlined"
        name={props.name}
        label={props.label}
				placeholder={`${props.label ?? props.placeholder }`}
        value={props.value}
        onChange={props.handleFormChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              $
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

const InputSelect = (props) => {
  return(
    <div className="form-row">
      <div className="col col-sm-12">
        <div className="form-group">
					{
						props.label
						? <InputLabel shrink> {props.label} </InputLabel>
						: null
					}
          <select
						size="small"
            className="form-control"
            name={props.name}
						placeholder={`${props.label ?? props.placeholder }`}
            value={props.value}
            onChange={(event) => props.handleSelectChange(event)}
          >
            {
              props.data.map( val => {
                return(
                  <option
                    key={val[props.optionVal]}
                    value={val[props.optionVal]}
                  >
                    {val[props.optionText]}
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>
    </div>
  )
}

const Required = () => {
  return(
    <sup className="text-danger">*</sup>
  )
}

const SubmitButton = (props) => {
  return (
    <div className="form-row">
      <div className="col col-sm-12">
        <input type="submit" className="btn btn-primary btn-block" value={props.value} />
      </div>
    </div>
  )
}

const SuccessMessage = (props) => {
  return (
    <div
      role="alert"
			className={"alert alert-success " + ( props.display ? "" : "d-none") }
			id='success'
    >
      {props.message}
    </div>
  )
}

const ErrorMessage = (props) => {

	if(!Array.isArray(props.error)) {
		return null;
	}

	let errors = [];
	for(let i in props.error) {
		errors.push(<li key={i}>{props.error[i]}</li>);
	}

  return (
    <div className="row">
      <div className="col-md-12">
        <div
          role="alert"
          className={ "alert alert-danger " + props.className + " " + (props.error ? "" : "d-none") }
        >
          <strong>Error!</strong>
          <ul>
            {errors}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {
  useStyles,
  InputText,
	InputTextRequired,
	InputTextDollar,
	InputPassword,
  InputSelect,
  SubmitButton,
  SuccessMessage,
  ErrorMessage,
  Required,
  Divider
};
