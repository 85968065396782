import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, TextField } from '@mui/material';
// import { LocalizationProvider, DatePicker } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import DateFnsUtils from '@date-io/date-fns';
import { TableStyles } from './TableStyles';

const TableSearchRow = (props) => {
  const classes = TableStyles();
  const { user, headCells, searchKeys, handleTextSearch, handleDateSearch } = props;

  return (
    <TableRow className={classes.searchRow}>
      {headCells.map((cell) => {
        if (Object.prototype.hasOwnProperty.call(cell, 'restrict') && cell.restrict !== user.role) {
          return null;
        }

        if (
          !Object.prototype.hasOwnProperty.call(cell, 'searchable') ||
          (Object.prototype.hasOwnProperty.call(cell, 'searchable') && cell.searchable)
        ) {
          let type = Object.prototype.hasOwnProperty.call(cell, 'type') ? cell.type : 'text';
          let field = '';

          switch (type) {
            case 'date':
              field = (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    fullWidth
                    variant="inline"
                    format="MM/dd/yyyy"
                    name={cell.id}
                    value={
                      Object.prototype.hasOwnProperty.call(searchKeys, cell.id) &&
                      searchKeys[cell.id]
                        ? new Date(searchKeys[cell.id])
                        : null
                    }
                    onChange={(dateStr, date) => handleDateSearch(dateStr, `${date}|${cell.id}`)}
                    animateYearScrolling={true}
                    inputVariant="outlined"
                    className={classes.searchField}
                    style={{ width: '150px' }}
                    placeholder={cell.label}
                    label={cell.label}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" margin="dense" size="small" />
                    )}
                  />
                </LocalizationProvider>
              );
              break;

            case 'number':
              field = (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  type="number"
                  margin="dense"
                  className={classes.searchField}
                  name={cell.id}
                  onChange={(event) => handleTextSearch(event)}
                  placeholder={cell.label}
                  label={cell.label}
                />
              );
              break;

            default:
              field = (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  margin="dense"
                  className={classes.searchField}
                  name={cell.id}
                  onChange={(event) => handleTextSearch(event)}
                  placeholder={cell.label}
                  label={cell.label}
                />
              );
              break;
          }

          return (
            <TableCell key={cell.id} align={cell.align}>
              {field}
            </TableCell>
          );
        } else {
          return <TableCell key={cell.id}></TableCell>;
        }
      })}
    </TableRow>
  );
};

TableSearchRow.propTypes = {
  user: PropTypes.object,
  headCells: PropTypes.array.isRequired,
  searchKeys: PropTypes.object,
  handleTextSearch: PropTypes.func,
  handleDateSearch: PropTypes.func,
};
TableSearchRow.defaultProps = {
  user: {},
  searchKeys: {},
};

export default TableSearchRow;
