import { STAGE } from '../constants/stage';

export function clearForm() {
  return {type: STAGE.STAGE_CLEAR_FORM}
}

export function remove(payload) {
  return async function(dispatch, getState) {
		try {
			let response = await global.axios({
				method: "delete",
				url: `/stage/${payload.id}?venue_id=${getState().Auth.user.venue_id}`,
			});
			dispatch({ type: STAGE.STAGE_LOAD_ALL, payload: response.data });
			return true;
		}
		catch(error) {
			console.log(error.response);
		}
  }
}

export function load(payload) {
  return async function(dispatch, getState) {
		try {
			let response = await global.axios({
				method: "get",
				url: `/stage/${payload.id}?venue_id=${getState().Auth.user.venue_id}`,
			});
			dispatch({ type: STAGE.STAGE_LOAD, payload: response.data });
		}
		catch(error) {
			console.log(error.response);
		}
  }
}

export function loadAll(payload=null) {
  return async function(dispatch, getState) {

		let venue_id = '';
		if(getState().Auth.user.role === 'venue') {
			venue_id = getState().Auth.user.venue_id;
		} else {
			venue_id = (payload && payload.venue_id) ? payload.venue_id : 'all';
		}

		try {
			let response = await global.axios({
				method: "get",
				url: `/stage?venue_id=${venue_id}`
			});
			dispatch({type: STAGE.STAGE_LOAD_ALL, payload: response.data});
		}
		catch(error) {
			console.log(error.response);
		}
  }
}

export function save() {
	return async function(dispatch, getState) {

		dispatch({type: STAGE.STAGE_SAVE_REQUEST});

		let postData = getState().Stage.stage;
		postData['position'] = parseInt(postData['after_position']) + 1;
		postData['venue_id'] = getState().Auth.user.venue_id;

    let method = "post";
    let url = "/stage";

    if(Number.isInteger(parseInt(postData.id))) {
      postData['_method'] = "put";
      url += `/${postData.id}`;
		}

		try {
			let response = await global.axios({
				method: method,
				url: url,
				data: postData
			});
			dispatch({ type: STAGE.STAGE_SAVE_SUCCESS });
			dispatch({ type: STAGE.STAGE_LOAD_ALL, payload: response.data });
			return true;
		}
		catch(error) {
			console.log(error.response);
			// dispatch({type: STAGE.STAGE_SAVE_ERROR, payload: error.response.data.errors});
			return false;
		}
  };
}

export function typing(payload) {
  return {type: STAGE.STAGE_TYPING, payload: payload}
}
