export const DEAL = {
	DEAL_CLEAR_FORM: 'DEAL_CLEAR_FORM',
	DEAL_DELETE: 'DEAL_DELETE',
	DEAL_LOAD: 'DEAL_LOAD',
	DEAL_LOAD_ALL: 'DEAL_LOAD_ALL',
	DEAL_SAVE_REQUEST: 'DEAL_SAVE_REQUEST',
	DEAL_SAVE_SUCCESS: 'DEAL_SAVE_SUCCESS',
	DEAL_SAVE_ERROR: 'DEAL_SAVE_ERROR',
	DEAL_SEARCH_TYPING: 'DEAL_SEARCH_TYPING',
	DEAL_TYPING: 'DEAL_TYPING',
	DEAL_CLEAR_FLAG: 'DEAL_CLEAR_FLAG',
	DEAL_UPDATE_STAGE: 'DEAL_UPDATE_STAGE',
	DEAL_BOARD_UPDATE_STAGE: 'DEAL_BOARD_UPDATE_STAGE',
	DEAL_BOARD_SWAP_COLUMN: 'DEAL_BOARD_SWAP_COLUMN',
	DEAL_LOAD_STATISTICS: 'DEAL_LOAD_STATISTICS',
	DEAL_LOAD_UNASSIGNED: 'DEAL_LOAD_UNASSIGNED',
	DEAL_CHANGE_VIEW: 'DEAL_CHANGE_VIEW',
}
