import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, ListItemButton, Tooltip } from '@mui/material';
import {
  DashboardOutlined,
  PeopleOutlineRounded,
  LocalOfferOutlined,
  SettingsOutlined,
  LocationCityOutlined,
  EqualizerRounded,
  NoteAdd,
} from '@mui/icons-material';

const menuItems = [
  { itemLink: '', itemLabel: 'Dashboard', restrict: 'god_admin', itemIcon: <DashboardOutlined /> },
  {
    itemLink: 'venues',
    itemLabel: 'Venues',
    restrict: 'god_admin',
    itemIcon: <LocationCityOutlined />,
  },
  { itemLink: 'contacts', itemLabel: 'Contacts', restrict: '', itemIcon: <PeopleOutlineRounded /> },
  { itemLink: 'deals', itemLabel: 'Deals', restrict: '', itemIcon: <LocalOfferOutlined /> },
  {
    itemLink: 'reports',
    itemLabel: 'Reports',
    restrict: 'god_admin',
    itemIcon: <EqualizerRounded />,
  },
  {
    itemLink: 'dynamic-forms',
    itemLabel: 'Dynamic Forms',
    restrict: 'god_admin',
    itemIcon: <NoteAdd />,
  },
  { itemLink: 'settings', itemLabel: 'Settings', restrict: '', itemIcon: <SettingsOutlined /> },
];

const Sidebar = (props) => {
  // console.log(props.location.pathname.split('/'));
  const handleMenuSelect = (link) => {
    props.navigate('/' + link);
  };

  return (
    <List className="sidebar" style={{ marginTop: '75px', flexGrow: 1 }}>
      <div
        className={props.open ? 'row mb-3 ml-1 d-block' : 'd-none'}
        style={{ fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}
      >
        <div className="col col-md-12">NAVIGATION</div>
      </div>
      {menuItems.map((row, index) => {
        if (row.restrict && row.restrict !== props.user.role) {
          return null;
        }

        let menuItem = (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              className={
                row.itemLink === props.location.pathname.split('/')[1] ? 'menu-item-selected' : ''
              }
              onClick={() => handleMenuSelect(row.itemLink)}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {row.itemIcon}
              </ListItemIcon>
              <ListItemText primary={row.itemLabel} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        );

        if (props.open) {
          return menuItem;
        } else {
          return (
            <Tooltip key={index} title={row.itemLabel} placement="right">
              {menuItem}
            </Tooltip>
          );
        }
      })}
    </List>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
  user: state.Auth.user,
});

export default connect(mapStateToProps)(withRouter(Sidebar));
