import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmModal = (props) => {
  return (
    <span>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.toggleModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.DialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.ContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggleModal} color="primary">
            Cancel
          </Button>
          {(() => {
            if (props.loading) {
              return (
                <Button variant="contained" color="secondary">
                  <CircularProgress size={20} color="inherit" />
                </Button>
              );
            }

            return (
              <Button onClick={props.handleYes} variant="contained" color="secondary">
                Yes
              </Button>
            );
          })()}
        </DialogActions>
      </Dialog>
    </span>
  );
};
ConfirmModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  toggleModal: PropTypes.func,
  DialogTitle: PropTypes.string,
  ContentText: PropTypes.string,
  handleYes: PropTypes.func,
};
ConfirmModal.defaultProps = {
  loading: false,
};

export default ConfirmModal;
