import { createMachine, assign } from 'xstate';

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'CommentCard',
    type: 'parallel',
    initial: 'idle',
    context: {
      fileUrl: '',
    },
    states: {
      fileModal: {
        initial: 'close',
        states: {
          close: {
            on: {
              FILEMODAL_TOGGLE: {
                target: 'open',
                actions: ['setFileUrl'],
              },
            },
          },
          open: {
            on: {
              FILEMODAL_TOGGLE: 'close',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      setFileUrl: assign({
        fileUrl(ctx, event) {
          return event.data;
        },
      }),
    },
  }
);
