import { DEAL_BUILDER } from "../constants/deal_builder";

const initialState = {
	isProcessing: false,
	errors: {},
	success: false,
	deal_form: {
		deals_other_info_details: JSON.stringify([
			{
				title: "Contact",
				type: "Dropdown",
				placeholder: "",
				options: [],
				required: true,
				disabled: true,
			},
			{
				title: "Title",
				type: "Short Answer",
				placeholder: "",
				options: [],
				required: true,
				disabled: true,
			},
			{
				title: "Value",
				type: "Short Answer",
				placeholder: "",
				options: [],
				required: true,
				disabled: true,
			},
			{
				title: "Date",
				type: "Date",
				placeholder: "",
				options: [],
				required: true,
				disabled: true,
			},
			{
				title: "Type",
				type: "Dropdown",
				placeholder: "",
				options: ["Birthday", "Corporate", "Engagement", "Wedding", "Other"],
				required: true,
				disabled: true,
			},
			{
				title: "Description",
				type: "Paragraph",
				placeholder: "",
				options: [],
				required: true,
				disabled: true,
			},
		]),
	},
};

const DealBuilder = (state = initialState, action) => {
	let newState = JSON.parse(JSON.stringify(state));

	switch (action.type) {
		case DEAL_BUILDER.DEAL_BUILDER_CLEAR_FORM: {
			newState.errors = {};
			newState.isProcessing = false;

			newState.deal_form = {};

			return newState;
		}

		case DEAL_BUILDER.DEAL_BUILDER_LOAD: {
			newState.deal_form = action.payload;
			return newState;
		}

		case DEAL_BUILDER.DEAL_BUILDER_SAVE_REQUEST: {
			newState.isProcessing = true;
			newState.errors = {};
			newState.success = false;

			return newState;
		}

		case DEAL_BUILDER.DEAL_BUILDER_SAVE_SUCCESS: {
			newState.isProcessing = false;
			newState.errors = {};
			newState.success = true;

			newState.deal_form = action.payload;

			return newState;
		}

		case DEAL_BUILDER.DEAL_BUILDER_SAVE_ERROR: {
			newState.isProcessing = false;
			newState.errors = action.payload;
			newState.success = false;

			return newState;
		}

		default:
			return state;
	}
};

export default DealBuilder;
