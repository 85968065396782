import React, {useState, useEffect, forwardRef} from 'react';
import {connect} from 'react-redux';
import {typing, save, clearForm} from '../actions/venue';
import {makeStyles} from '@mui/styles';
import {Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField, InputLabel, InputAdornment, IconButton} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {isObjectEmpty} from '../helpers/GeneralFunctions';
import AlertFormError from '../components/AlertFormError';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: '20px',
	},
	label: {
		color: '#636669fa',
		fontWeight: 600,
	},
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AssignDealDialog = (props) => {

	const {toggleModal, venue, venueEmail, errors, save, typing, clearForm, handleSaveSuccess} = props;
	const classes = useStyles();

	useEffect(() => {
		clearForm();
		typing({email: venueEmail});
	}, []);

	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

	const handleFormChange = event => {
    typing({ [event.target.name]: event.target.value })
  }

  const handleFormSubmit = async () => {
		let venue =  await save();
		if(venue) {
			venue.email = venue.user.email;
			handleSaveSuccess(venue);
		}
	}

	return (
		<Dialog
			open={true}
			TransitionComponent={Transition}
			keepMounted
			onClose={toggleModal}
			maxWidth="sm"
			fullWidth
			// disableBackdropClick={true}
		>
			<DialogTitle className="modal-header">
				Create New Venue
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{!isObjectEmpty(errors) && (<AlertFormError errors={errors} />)}
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item sm={3} xs={12}>
								<label className={classes.label}>Venue Information</label>
							</Grid>
							<Grid item sm={9} xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										{/*<InputLabel shrink>Venue Name</InputLabel>*/}
										<TextField
											fullWidth
											variant="outlined"
											margin="dense"
											label="Venue Name"
											name="name"
											value={venue.name}
											placeholder={venue.name}
											size="small"
											onChange={(event) => handleFormChange(event)}
											error={errors.hasOwnProperty('name')}
										/>
									</Grid>
									<Grid item xs={12}>
										{/*<InputLabel shrink>Address</InputLabel>*/}
										<TextField
											fullWidth
											multiline
											variant="outlined"
											margin="dense"
											label="Address"
											name="address"
											value={venue.address}
											placeholder={venue.address}
											size="small"
											onChange={(event) => handleFormChange(event)}
											error={errors.hasOwnProperty('address')}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<hr style={{ width: '100%' }} />
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item sm={3} xs={12}>
								<label className={classes.label}>Contact Person</label>
							</Grid>
							<Grid item sm={9} xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<InputLabel shrink>First Name</InputLabel>
											<TextField
												fullWidth
												variant="outlined"
												margin="dense"
												name="firstname"
												size="small"
												value={venue.firstname}
												onChange={(event) => handleFormChange(event)}
												error={errors.hasOwnProperty('firstname')}
											/>
										</Grid>
										<Grid item xs={12}>
											<InputLabel shrink>Last Name</InputLabel>
											<TextField
												fullWidth
												variant="outlined"
												margin="dense"
												name="lastname"
												size="small"
												value={venue.lastname}
												onChange={(event) => handleFormChange(event)}
												error={errors.hasOwnProperty('lastname')}
											/>
										</Grid>
									</Grid>
								</Grid>
						</Grid>
					</Grid>
					<hr style={{ width: '100%' }} />
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item sm={3} xs={12}>
								<label className={classes.label}>Login Details</label>
							</Grid>
							<Grid item sm={9} xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<InputLabel shrink>E-mail</InputLabel>
										<TextField
											size="small"
											fullWidth
											variant="outlined"
											margin="dense"
											name="email"
											value={venue.email}
											onChange={(event) => handleFormChange(event)}
											disabled
										/>
									</Grid>
									<Grid item xs={12}>
										<InputLabel shrink>Password</InputLabel>
										<TextField
											size="small"
											fullWidth
											margin="dense"
											variant="outlined"
											type={showPassword ? 'text' : 'password'}
											name="password"
											value={venue.password}
											onChange={(event) => handleFormChange(event)}
											error={errors.hasOwnProperty('password')}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															edge="end"
															aria-label="toggle password visibility"
															onClick={() => setShowPassword(!showPassword)}
														>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputLabel shrink>Password Confirm</InputLabel>
											<TextField
												size="small"
												fullWidth
												margin="dense"
												variant="outlined"
												type={showPasswordConfirm ? 'text' : 'password'}
												name="password_confirmation"
												value={venue.password_confirmation}
												onChange={(event) => handleFormChange(event)}
												error={errors.hasOwnProperty('password')}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																edge="end"
																aria-label="toggle password visibility"
																onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
															>
																{showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													),
												}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<hr style={{ width: '100%' }} />
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" size="medium" onClick={() => toggleModal()}>
					Close
				</Button>
				<Button
					color="primary"
					variant="contained"
					size="medium"
					onClick={() => handleFormSubmit()}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const mapStateToProps = state => ({
	venue: state.Venue.venue,
	errors: state.Venue.errors,
});
const mapDispatchToProps = {
	save: () => save(),
	clearForm: () => clearForm(),
	typing: (payload) => typing(payload),
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AssignDealDialog);
