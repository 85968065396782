export const VENUE = {
    VENUE_CLEAR_FORM: 'VENUE_CLEAR_FORM',
    VENUE_DELETE: 'VENUE_DELETE',
    VENUE_TYPING: 'VENUE_TYPING',
    VENUE_LOAD: 'VENUE_LOAD',
    VENUE_LOAD_ALL: 'VENUE_LOAD_ALL',
    VENUE_SAVE_REQUEST: 'VENUE_SAVE_REQUEST',
    VENUE_SAVE_SUCCESS: 'VENUE_SAVE_SUCCESS',
		VENUE_SAVE_ERROR: 'VENUE_SAVE_ERROR',
  }
