import React, { useEffect } from 'react';
import { Paper, Grid } from '@mui/material';
import PageHeader from '../components/PageHeader.js';
import { connect } from 'react-redux';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NoteAdd } from '@mui/icons-material';
import TableComponent from '../components/table/TableComponent';

const headCells = [
	{ id: 'name', label: 'Form' },
	{ id: '', label: 'Action', align: "right", sortable: false, searchable: false },
];

const tableBody = [
	{ id:1 , name:'Contacts', url:'contacts' },
	{ id:1 , name:'Create Deal', url:'deals' }
];

const FormBuilder = (props) => {

	useEffect(() => {

	}, []);

	const handleView = (tableBody) => {
		console.log(tableBody);
		props.navigate(`/dynamic-forms/${tableBody.url}`);
	}

  return (
		<>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<PageHeader icon={<NoteAdd />} headerTitle="Dynamic Forms" />
					</Grid>
					<Grid item xs={6} className="text-right">
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Paper>
				<TableComponent
				data={tableBody}
				headCells={headCells}
				handleView={(tableBody) => handleView(tableBody)}
					/>
				</Paper>
			</Grid>
		</Grid>
		</>
  )
}


function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

const mapStateToProps = state => ({
	user: state.Auth.user,
});

export default connect(
	mapStateToProps,
)(withRouter(FormBuilder));
