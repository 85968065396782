import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import html2canvas from 'html2canvas';
import { isObjectEmpty } from '../helpers/GeneralFunctions';

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportDownloadDialog = (props) => {
  const { toggleModal } = props;
  const classes = useStyles();
  const [disableDownload, setDisableDownload] = useState(false);
  const [options, setOptions] = useState({
    key_stats: false,
    deals_by_type: false,
    deals_by_month: false,
    bar_charts: false,
  });
  const [disabled, setDisabled] = useState({
    key_stats: false,
    deals_by_type: false,
    deals_by_month: false,
    bar_charts: false,
  });

  useEffect(() => {
    setDisableDownload(
      !options.key_stats && !options.deals_by_type && !options.deals_by_month && !options.bar_charts
    );
  }, [options]);

  useEffect(() => {
    let hasKeyStats =
      !isObjectEmpty(props.key_stats) &&
      (props.key_stats.number_of_new_deals ||
        props.key_stats.revenue_of_new_deals ||
        props.key_stats.number_of_deals_won ||
        props.key_stats.revenue_of_deals_won ||
        props.key_stats.average_spend)
        ? true
        : false;
    let hasDealsByType = props.deals_by_type.length > 0;
    let hasBarCharts =
      props.deals_by_stage.length > 0 ||
      props.deals_revenue_by_month.length > 0 ||
      props.new_deals_by_type.length > 0 ||
      props.won_deals_by_type > 0
        ? true
        : false;
    let hasDealsByMonth = props.deals_by_month.length > 0;

    setOptions({
      key_stats: hasKeyStats,
      deals_by_type: hasDealsByType,
      deals_by_month: hasBarCharts,
      bar_charts: hasDealsByMonth,
    });

    setDisabled({
      key_stats: !hasKeyStats,
      deals_by_type: !hasDealsByType,
      deals_by_month: !hasBarCharts,
      bar_charts: !hasDealsByMonth,
    });
  }, [props]);

  const handleOnCheck = (event) => {
    let field = event.target.name;
    let value = event.target.checked;
    setOptions({
      ...options,
      [field]: value,
    });
  };

  const getChartImage = async (element_id) => {
    let image = null;
    let element = document.getElementById(element_id);

    if (element) {
      image = await html2canvas(element).then((canvas) => {
        return canvas.toDataURL('image/png');
      });
    }

    return image;
  };

  const clearData = () => {
    localStorage.removeItem('data');
    localStorage.removeItem('charts');
  };

  const download = async () => {
    clearData();

    let data = {
      year: props.year,
      date_from: props.date_from,
      date_to: props.date_to,
      key_stats: props.key_stats,
      deals_by_type: props.deals_by_type,
      deals_by_month: props.deals_by_month,
      options: options,
    };
    localStorage.setItem('props', JSON.stringify(data));

    async function getChartImages() {
      let new_deals_by_type = await getChartImage('bar_chart_new_deal');
      let won_deals_by_type = await getChartImage('bar_chart_won_deal');
      let deals_by_stage = await getChartImage('bar_chart_by_stage');
      let revenue_by_month = await getChartImage('bar_chart_by_month');

      localStorage.setItem(
        'charts',
        JSON.stringify({
          new_deals_by_type: new_deals_by_type,
          won_deals_by_type: won_deals_by_type,
          deals_by_stage: deals_by_stage,
          revenue_by_month: revenue_by_month,
        })
      );

      window.open('/#/reports/pdf');
    }
    getChartImages();
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleModal}
      maxWidth="sm"
      fullWidth
      // disableBackdropClick={true}
    >
      <DialogTitle className="modal-header">Download Reports</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} className="pt-3">
            <FormControl component="fieldset">
              <FormLabel component="legend">Select report/s to download:</FormLabel>
              <FormGroup>
                <FormControlLabel
                  className={classes.label}
                  label="Deals By Month"
                  control={
                    <Checkbox
                      color="primary"
                      name="deals_by_month"
                      value="deals_by_month"
                      onChange={(event) => handleOnCheck(event)}
                      checked={options.deals_by_month}
                      disabled={disabled.deals_by_month}
                    />
                  }
                />
                <FormControlLabel
                  label="Deals By Event Type"
                  className={classes.label}
                  control={
                    <Checkbox
                      color="primary"
                      name="deals_by_type"
                      value="deals_by_type"
                      onChange={(event) => handleOnCheck(event)}
                      checked={options.deals_by_type}
                      disabled={disabled.deals_by_type}
                    />
                  }
                />
                <FormControlLabel
                  label="Key Statistics"
                  className={classes.label}
                  control={
                    <Checkbox
                      color="primary"
                      name="key_stats"
                      value="key_stats"
                      onChange={(event) => handleOnCheck(event)}
                      checked={options.key_stats}
                      disabled={disabled.key_stats}
                    />
                  }
                />
                <FormControlLabel
                  label="Bar Charts"
                  className={classes.label}
                  control={
                    <Checkbox
                      color="primary"
                      name="bar_charts"
                      value="bar_charts"
                      onChange={(event) => handleOnCheck(event)}
                      checked={options.bar_charts}
                      disabled={disabled.bar_charts}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" size="medium" onClick={() => toggleModal()}>
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={() => download()}
          disabled={disableDownload}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReportDownloadDialog;
