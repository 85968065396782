import React from 'react';
import { connect } from 'react-redux';
import {Line} from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Typography } from '@mui/material';
import 'chartjs-plugin-datalabels';

const useStyles = makeStyles({
  paper: {
		padding: '20px',
	},
});

const options = {
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero: true
			},
		}]
	},
	legend: {
		display: false,
	},
	plugins: {
		datalabels: {
			display: true,
			color: '#26547C',
			anchor: 'end',
			font: {
				size: 9,
				weight: 700,
			},
			formatter: function(value, context) {
				return value > 0 ? `${value}\n` : '';
			}
		}
	}
};

const DealsByStageChart = (props) => {

	const classes = useStyles();

	if(props.deal_cnt_by_date.length === 0) {
		return null;
	}

	const data = {
		labels: getChartData(props.deal_cnt_by_date, 'labels'),
		datasets: [
			{
				label: 'Count',
				fill: false,
				lineTension: 0.1,
				backgroundColor: 'rgba(38,84,124,0.4)',
				borderColor: 'rgba(38,84,124,1)',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(38,84,124,1)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(38,84,124,1)',
				pointHoverBorderColor: 'rgba(220,220,220,1)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: getChartData(props.deal_cnt_by_date, 'data'),
			}
		]
	};


	return (
			<Paper className={classes.paper}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6">
						Deals By Date
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Line data={data} options={options} />
				</Grid>
			</Grid>
		</Paper>
	)
}

const getChartData = (stat, type) => {

	let monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

	let labels = [];
	let data = [];

	let date = new Date();

	for(let i = date.getMonth() ; i < date.getMonth() + 6; i++) {
		labels.push(monthNames[i]);

		let temp = stat.filter(item => item.month === (i+1));
		let cnt = temp.length === 0 ? 0 : temp[0].cnt;
		data.push(cnt);
	}

	let chartData = {
			'labels': labels,
			'data': data,
		};

	if(type === "labels") {
		return labels;
	}

	if(type === "data") {
		return data;
	}

	return chartData;
}


const mapStateToProps = state => ({
	deal_cnt_by_date: state.Deal.stats.deal_cnt_by_date,
});

export default connect(
  mapStateToProps
)(DealsByStageChart);
