import { STAGE } from '../constants/stage';

const initialState = {
  error: '',
  success: false,
	isProcessing: false,
	stages: [],
  stage: {
    'id': '',
    'venue_id': '',
		'name': '',
		'after_position': '',
	},
};

const Stage = (state = initialState, action) => {

	let newState = JSON.parse(JSON.stringify(state));

  switch(action.type) {

		case STAGE.STAGE_CLEAR_FORM : {

      newState.error = false;
      newState.success = false;
			newState.isProcessing = false;

			newState.stage = {
				'id': '',
				'venue_id': '',
				'name': '',
				'after_position': newState.stages.length === 0 ? 0 : newState.stages[newState.stages.length - 1].position,
			}
			return newState;
    }

    case STAGE.STAGE_DELETE : {
      newState.stages = action.payload;
			return newState;
		}

    case STAGE.STAGE_LOAD : {
			newState.stage = action.payload;
			return newState;
		}

		case STAGE.STAGE_LOAD_ALL : {
			newState.stages = action.payload;
      return newState;
		}

    case STAGE.STAGE_SAVE_REQUEST : {

			newState.isProcessing = true;
			newState.error = '';
			newState.success = false;

			return newState;
    }

    case STAGE.STAGE_SAVE_SUCCESS : {

			newState.error = '';
			newState.isProcessing = false;
			newState.success = true;

			return newState;
    }

    case STAGE.STAGE_SAVE_ERROR : {

			newState.isProcessing = false;
			newState.success = false;

      let err = [];

      if(typeof action.payload === 'object') {
        for(let i in action.payload) {
          err.push(action.payload[i][0]);
        }
      }
      else if(Array.isArray(action.payload)) {
        err = action.payload;
      }
      else {
        err[0] = action.payload;
			}

      newState.error = err;

			return newState;
		}

    case STAGE.STAGE_TYPING : {

			newState.error = '';
			let key = Object.keys(action.payload)[0];
			newState.stage[key] = action.payload[key];

			return newState;
		}

    default: return state;
  }

}

export default Stage;
