import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Typography } from '@mui/material';
import {Doughnut} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const useStyles = makeStyles({
  paper: {
		padding: '20px',
	},
});

const legendOpts = {
  display: true,
  position: 'right',
  fullWidth: true,
  reverse: false,
  labels: {
    fontColor: 'rgb(77, 77, 77)'
  }
};

const options = {
	plugins: {
		datalabels: {
			display: true,
			color: '#fff',
			font: {
				size: 9,
			},
			formatter: function(value, context) {
				let percentage = "";
				if(value > 0) {
					let datasets = context.chart.data.datasets[0].data;
					let total = 0;
					for (let i in datasets) {
					total += datasets[i];
					}
					percentage = ((value/total) * 100).toFixed(2) + '%';
					let label = `${percentage}\n(${value} of ${total})`
				}
				return percentage;
			}
		}
	}
}

const DealsByTypeChart = (props) => {

	const classes = useStyles();

	if(props.deal_cnt_by_type.length === 0) {
		return null;
	}

	const data = {
		labels: [
			'Birthday',
			'Corporate',
			'Engagement',
			'Wedding',
			'Other',
		],
		datasets: [{
			data: [
				getCount(props.deal_cnt_by_type, 'Birthday'),
				getCount(props.deal_cnt_by_type, 'Corporate'),
				getCount(props.deal_cnt_by_type, 'Engagement'),
				getCount(props.deal_cnt_by_type, 'Wedding'),
				getCount(props.deal_cnt_by_type, 'Other'),
			],
			backgroundColor: [
			'#26547C',
			'#095256',
			'#93B7BE',
			'#2B59C3',
			'#00ACC1',
			],
			hoverBackgroundColor: [
			'#26547C',
			'#095256',
			'#93B7BE',
			'#2B59C3',
			'#00ACC1',
			]
		}]
	};

	return (
		<Paper className={classes.paper}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6">
						Deals By Type
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Doughnut data={data} legend={legendOpts} options={options} redraw />
				</Grid>
			</Grid>
		</Paper>
	)
}

const getCount = (data, type) => {

	let stat = data.filter(item => item.type === type);

	if(stat.length !== 0) {
		return stat[0].cnt;
	}

	return 0;
}

const mapStateToProps = state => ({
	deal_cnt_by_type: state.Deal.stats.deal_cnt_by_type,
});

export default connect(
  mapStateToProps
)(DealsByTypeChart);
