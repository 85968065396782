import {CONTACT_COMMENT} from '../constants/contact_comment';

const initialState = {
  error: '',
  success: false,
	isProcessing: false,
	comments: [],
	comment: {},
};

const ContactComment = (state = initialState, action) => {

	let newState = JSON.parse(JSON.stringify(state));

  switch(action.type) {

		case CONTACT_COMMENT.CONTACT_COMMENT_CLEAR_FORM : {

      newState.error = false;
      newState.success = false;
			newState.isProcessing = false;
			newState.comment = {};

			return newState;
		}

		case CONTACT_COMMENT.CONTACT_COMMENT_CLEAR : {

      newState.error = false;
      newState.success = false;
			newState.isProcessing = false;
			newState.comment = {};
			newState.comments = [];

			return newState;
    }

    case CONTACT_COMMENT.CONTACT_COMMENT_DELETE : {
      newState.comments = action.payload;
			return newState;
		}

		case CONTACT_COMMENT.CONTACT_COMMENT_DELETE_FILE : {
			let files = newState.comment.files;
			newState.comment.files = files.filter(item => item.id !== action.payload.id);
			return newState;
		}

    case CONTACT_COMMENT.CONTACT_COMMENT_LOAD : {
			newState.comment = action.payload;
			return newState;
		}

		case CONTACT_COMMENT.CONTACT_COMMENT_LOAD_ALL : {
			newState.comments = action.payload;
      return newState;
		}

		case CONTACT_COMMENT.CONTACT_COMMENT_SAVE_SUCCESS : {

			newState.error = '';
			newState.isProcessing = false;
			newState.success = true;

			return newState;
    }

    default: return state;
  }

}

export default ContactComment;
