import React from 'react';
import { connect } from 'react-redux';
import { typing, save } from '../actions/stage';

import { makeStyles } from '@mui/styles';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, TextField, FormControl, Select, MenuItem } from '@mui/material';
import FormErrorMessage from '../components/FormErrorMessage';

const useStyles = makeStyles(theme => ({
	DialogTitle: {
		color: '#fff',
		backgroundColor: '#323a46',
		borderRadius: '1px !important',
		padding: '12px 24px !important',
	},
	TextArea: {
		width: '100%',
		padding: '5px',
		borderRadius: '3px',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const BoardColumnEditDialog = (props) => {

	const classes = useStyles();

	const handleFormChange = event => {
		props.typing({ [event.target.name]: event.target.value })
	}

	const handleFormSubmit = event => {
		event.preventDefault();
		props.saveBoardColumn();
	}

	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => props.closeEditorModal()}
				maxWidth="sm"
				fullWidth
			>
				<form autoComplete="off" onSubmit={handleFormSubmit}>
					<DialogTitle className={classes.DialogTitle}	>
						Create New Board Column
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<FormErrorMessage error={props.error} />
							</Grid>
							<Grid item xs={12}>
								{/*<Typography variant="body2" component="div" color="textSecondary" className="mb-1">*/}
								{/*	Column Name*/}
								{/*</Typography>*/}
								<TextField
									fullWidth
									size="small"
									margin="dense"
									variant="outlined"
									name="name"
									label="Column Name"
									placeholder="Column Name"
									value={props.stage.name}
									onChange={handleFormChange}
									required
								/>
							</Grid>

							{
								props.action === "update"
									? null
									: <Grid item xs={12}>
										{/*<Typography variant="body2" component="div" color="textSecondary" className="mb-1">*/}
										{/*	Insert after column*/}
										{/*</Typography>*/}
										<FormControl variant="outlined" margin="dense" fullWidth>
											<TextField
												size="small"
												className="form-group"
												style={{width:'100%'}}
												select
												label="Insert after column"
												placeholder="Insert after column"
												name="after_position"
												value={props.stage.after_position}
												onChange={handleFormChange}
											>
												{props.stages.map((item, index) => {
													return (
														<MenuItem key={index} value={item.position}>
															{item.name ? item.name : item.stage.name}
														</MenuItem>
													)
												})}
											</TextField>
											{/*<Select*/}
											{/*	value={props.stage.after_position}*/}
											{/*	name="after_position"*/}
											{/*	onChange={handleFormChange}*/}
											{/*>*/}
											{/*	{*/}
											{/*		props.stages.map((item, index) => {*/}
											{/*			return(*/}
											{/*				<MenuItem key={index} value={item.position}>*/}
											{/*					{ item.name ? item.name : item.stage.name }*/}
											{/*				</MenuItem>*/}
											{/*			)*/}
											{/*		})*/}
											{/*	}*/}
											{/*</Select>*/}
										</FormControl>
									</Grid>
							}

						</Grid>
					</DialogContent>
					<DialogActions>
						<Button color="primary" className="mr-1 mb-2" onClick={props.closeEditorModal}>
							Cancel
						</Button>
						<Button type="submit" variant="contained" color="secondary" className="mr-3 mb-2">
							Save
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
}

const mapStateToProps = state => ({
	isProcessing: state.Stage.isProcessing,
	success: state.Stage.success,
	error: state.Stage.error,
	stage: state.Stage.stage,
	stages: state.Stage.stages,
});

const mapDispatchToProps = {
	typing: (payload) => typing(payload),
	save: () => save()
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BoardColumnEditDialog);
