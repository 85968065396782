import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { typing, save, load, clearForm } from '../actions/contact';
import {
  Grid,
  // InputLabel,
  TextField,
  Button,
  TextareaAutosize,
  // LinearProgress,
  // Box,
  FormControl,
  // Select,
  MenuItem,
  // Alert,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  DialogActions,
} from '@mui/material';
// import {
// 	// Check,
// 	// InsertEmoticon,
// 	// PeopleOutlineRounded,
// } from "@mui/icons-material";
// import { hasSpecialCharacter } from "../helpers/GeneralFunctions.js";
// import PageHeader from "../components/PageHeader.js";
// import { useStyles } from "../components/FormElements";
// import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { isObjectEmpty } from '../helpers/GeneralFunctions';
import AlertFormError from '../components/AlertFormError';
// import FormErrorMessage from "../components/FormErrorMessage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ContactEdit = (props) => {
  // const classes = useStyles();
  // const navigate = useNavigate();
  // let id = useParams();

  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    props.clearForm();
    // props.load({ id: id });
  }, []);

  const handleFormChange = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let type = event.target.type;
    let checked = event.target.checked;

    // let pattern = ["phone", "email"].indexOf(field) !== -1 ? field : "standard";

    setIsDisable(false);

    // if (hasSpecialCharacter(value, pattern) && type !== "date") {
    // 	return;
    // }

    if (field == 'First Name' || field == 'Last Name') {
      field = field.toLowerCase().replace(/\s/g, '');
    } else {
      field = field.toLowerCase().replace(/\s/g, '_');
    }

    if (type === 'checkbox') {
      let newOptions = props.contact[field] ?? [];
      if (checked) {
        newOptions.push(value);
      } else {
        newOptions = newOptions.filter((option) => option != value);
      }
      value = newOptions;
    }

    props.typing({ [field]: value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let response = await props.save(props.contact);
    if (response) {
      await props.saveEditorModal();
      await props.handleSaveSuccess();
    }
  };

  const contact_form = props.contact_form.contacts_other_info_details
    ? JSON.parse(props.contact_form.contacts_other_info_details)
    : [];

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChangePhone = (phone) => {
    setIsDisable(false);
    props.typing({ phone: phone });
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => props.closeEditorModal()}
      maxWidth="sm"
      fullWidth
    >
      <form
        autoComplete="off"
        onSubmit={handleFormSubmit}
        style={{ display: 'flex', flexDirection: 'column', maxHeight: '90vh' }}
      >
        <DialogTitle className="modal-header">Add New Contact</DialogTitle>
        <div style={{ maxHeight: '85vh', overflowY: 'auto' }}>
          <DialogContent>
            <Grid container spacing={2} className="pt-3">
              {!isObjectEmpty(props.error) && (
                <Grid item xs={12}>
                  <AlertFormError errors={props.error} />
                </Grid>
              )}
              {contact_form.map((item, index) => (
                <Grid item xs={12} key={index}>
                  {item.title == 'Email' ? (
                    <TextField
                      fullWidth
                      type="email"
                      size="small"
                      required={item.required}
                      label={Capitalize(item.title)}
                      name={item.title}
                      value={props.contact[item.title.replace(/\s/g, '_').toLowerCase()] || ''}
                      onChange={(event) => handleFormChange(event)}
                    />
                  ) : item.title == 'Phone' ? (
                    <PhoneInput
                      country={'au'}
                      countryCodeEditable={false}
                      value={props.contact[item.title.replace(/\s/g, '_').toLowerCase()] || ''}
                      onChange={handleChangePhone}
                      inputStyle={{ width: '100%' }}
                      inputProps={{
                        name: item.title,
                        required: item.required,
                        autoFocus: true,
                      }}
                      isValid={(value, country) => {
                        if (value.match(/12345/)) {
                          return 'Invalid value: ' + value + ', ' + country.name;
                        } else if (value.match(/1234/)) {
                          return false;
                        } else {
                          return true;
                        }
                      }}
                    />
                  ) : item.type == 'Short Answer' ? (
                    <TextField
                      fullWidth
                      size="small"
                      required={item.required}
                      label={Capitalize(item.title)}
                      name={item.title}
                      value={
                        item.title == 'First Name' || item.title == 'Last Name'
                          ? props.contact[item.title.replace(/\s/g, '').toLowerCase()] || ''
                          : props.contact[item.title.replace(/\s/g, '_').toLowerCase()] || ''
                      }
                      onChange={(event) => handleFormChange(event)}
                    />
                  ) : item.type == 'Paragraph' ? (
                    <TextareaAutosize
                      className="px-2 pt-2"
                      style={{
                        border: '1px solid #bdbdbd',
                        width: '100%',
                        borderRadius: '5px',
                      }}
                      placeholder={item.title + '...'}
                      required={item.required}
                      label={Capitalize(item.title)}
                      name={item.title}
                      value={props.contact[item.title.replace(/\s/g, '_').toLowerCase()] || ''}
                      onChange={(event) => handleFormChange(event)}
                      minRows={2}
                    />
                  ) : item.type == 'Multiple Choice' || item.type == 'Checkbox' ? (
                    <FormControl
                      fullWidth
                      required={item.required}
                      error={item.required}
                      size="small"
                    >
                      <FormLabel>{Capitalize(item.title)}</FormLabel>
                      <>
                        {item.type == 'Multiple Choice' && (
                          <RadioGroup>
                            {item.options.map((option, i) => (
                              <FormControlLabel
                                key={i}
                                control={
                                  <Radio
                                    name={item.title}
                                    value={option}
                                    onChange={(event) => handleFormChange(event)}
                                    size="small"
                                  />
                                }
                                label={option}
                              />
                            ))}
                          </RadioGroup>
                        )}
                        {item.type == 'Checkbox' && (
                          <FormGroup>
                            {item.options.map((option, i) => (
                              <FormControlLabel
                                key={i}
                                control={
                                  <Checkbox
                                    name={item.title}
                                    value={option}
                                    onChange={(event) => handleFormChange(event)}
                                    size="small"
                                  />
                                }
                                label={option}
                              />
                            ))}
                          </FormGroup>
                        )}
                      </>
                    </FormControl>
                  ) : item.type == 'Dropdown' ? (
                    <TextField
                      fullWidth
                      size="small"
                      name={item.title}
                      required={item.required}
                      label={Capitalize(item.title)}
                      value={props.contact[item.title.replace(/\s/g, '_').toLowerCase()] || ''}
                      onChange={(event) => handleFormChange(event)}
                      select
                    >
                      {item.options.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      required={item.required}
                      label={Capitalize(item.title)}
                      type="date"
                      name={item.title}
                      value={
                        props.contact[item.title.replace(/\s/g, '_').toLowerCase()]
                          ? moment(
                              props.contact[item.title.replace(/\s/g, '_').toLowerCase()]
                            ).format('YYYY-MM-DD')
                          : ''
                      }
                      onChange={(event) => handleFormChange(event)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </div>
        <DialogActions>
          <Button onClick={props.closeEditorModal} color="primary" className="mr-1 mb-2">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="secondary" disabled={isDisable}>
            {props.isProcessing ? 'Saving ...' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  isProcessing: state.Contact.isProcessing,
  success: state.Contact.success,
  error: state.Contact.error,
  contact: state.Contact.contact,
  contact_form: state.ContactBuilder.contact_form,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  clearForm: () => clearForm(),
  load: (payload) => load(payload),
  save: (payload) => save(payload),
  typing: (payload) => typing(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactEdit);
