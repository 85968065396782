import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoutes = (props) => {
	return (
		<div>
			{props.isLoggedIn ? <Outlet /> : <Navigate to='/login' />}
		</div>
	)
}

const mapStateToProps = state => ({
	isLoggedIn: state.Auth.isLoggedIn
});

export default connect(
	mapStateToProps
)(PrivateRoutes);