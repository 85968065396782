import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {Grid, Typography, Button} from '@mui/material';

const PageNotFound = ({history}) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{height:'100vh'}}>
			<div className="text-center">
				<Typography variant="h1" component="div" style={{fontSize:'144px',fontWeight:'600',color:'rgb(93,94,95)'}}>
					404
				</Typography>
				<Typography variant="h2" component="div" style={{fontWeight:600,color:'rgb(93,94,95)'}}>
					Oops! Page Not Found
				</Typography>
				<Typography variant="h6" component="div" style={{color:'rgb(93,94,95)', marginTop:'20px',marginBottom:'20px'}}>
					We're sorry, the page you requested is not found. Please go back to dashboard page.
				</Typography>
				<Button
					variant="contained"
					size="large"
					color="primary"
					onClick={() => history.navigate('/')}
				>
          Dashboard
        </Button>
			</div>
		</Grid>
  );
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

export default withRouter(PageNotFound);
