/* global global */
import React from 'react';
// import ReactDOM from 'react-dom';
// import { HashRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';

import axios from 'axios';
import CONFIG from './config.js';
import { createRoot } from 'react-dom/client';
import 'chart.js/auto';
import App from './App';
import Wrapper from './Wrapper';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './App.css';

global.axios = axios;
axios.defaults.baseURL = CONFIG.API_URL;

axios.interceptors.request.use(
  (config) => {
    let token = store.getState().Auth.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    console.error(error);
  }
);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Wrapper>
    <App />
  </Wrapper>
);

// ReactDOM.render(

//     , document.getElementById('root')
// );
